import React from 'react';
import { FormattedMessage } from 'react-intl';
import formatRichMessage from '@utils/formatRichMessage';
import { useSelector } from 'react-redux';
import { ReactComponent as BannerInfoIcon } from '../../../../../assets/icons/BannerInfoIcon.svg';
import { ReactComponent as BannerInfoIconDark } from '../../../../../assets/icons/BannerInfoIconDark.svg';

export const ReportBuilderHeader = (props) => {
    const isDarkMode = useSelector((state) => state.user.preferences.generalSettings.isDarkMode);
    let message = props.publicRecordsOn
        ? formatRichMessage({ id: 'ReportBuilderPage_Notifications.expiryDatePRsOn' }, props.intl)
        : formatRichMessage({ id: 'ReportBuilderPage_Notifications.expiryDate' }, props.intl);

    return (
        <div className="reports-list-header" id="reports-header">
            <div className="reports-list-title">
                <h1>
                    <FormattedMessage id={'UserPreferences.leftSideNavigation.reportBuilder'} />
                </h1>
            </div>
            <div className="horizontal-divider"></div>
            <div className="message-notification-wrapper">
                <div
                    className={`message-notification slide-down ${isDarkMode ? 'dark-mode' : ''}`}
                    id="expiry-notification"
                >
                    <div className="message-notification__icon">
                        {isDarkMode ? <BannerInfoIconDark /> : <BannerInfoIcon />}
                    </div>
                    <div className="message-notification__message">
                        <div className="message-notification__message-container">
                            <span dangerouslySetInnerHTML={{ __html: message }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
