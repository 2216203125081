import { merge, isEmpty } from 'lodash';
import {
    SEARCH_PARAM_BOOLEAN_TERMS,
    SEARCH_PARAM_RESET,
    SEARCH_PARAM_SET_BOOLEAN_TERMS,
    SEARCH_PARAM_UPDATE_QUERY,
    SEARCH_PARAM_UPDATE_TYPE,
    SEARCH_UPDATE_IS_CUSTOM_FUZZY,
    SEARCH_PARAM_SET_CATEGORY,
    SEARCH_PARAM_SET_SHARD_PREFERENCE,
} from './SearchParams.actions';

function searchParams(state = {}, action) {
    switch (action.type) {
        case SEARCH_PARAM_UPDATE_QUERY:
            if (state.query === action.payload || !action.payload || isEmpty(action.payload)) {
                return state;
            }
            return merge({}, state, { query: action.payload });

        case SEARCH_PARAM_UPDATE_TYPE:
            if (state.searchType === action.payload || !action.payload || isEmpty(action.payload)) {
                return state;
            }
            return merge({}, state, { searchType: action.payload });

        case SEARCH_PARAM_RESET: {
            return Object.assign({}, state, { query: '' }, { prefilterQuery: '' });
        }

        case SEARCH_PARAM_SET_BOOLEAN_TERMS: {
            return merge({}, state, { prefilterQuery: action.payload });
        }

        case SEARCH_PARAM_BOOLEAN_TERMS:
            if (state.prefilterQuery === action.payload || !action.payload || isEmpty(action.payload)) {
                return state;
            }

            return Object.assign({}, state, { prefilterQuery: state.prefilterQuery + action.payload });

        case SEARCH_UPDATE_IS_CUSTOM_FUZZY:
            return Object.assign({}, state, { isCustomFuzzy: action.payload });

        case SEARCH_PARAM_SET_CATEGORY: {
            return merge({}, state, { category: action.payload });
        }

        case SEARCH_PARAM_SET_SHARD_PREFERENCE: {
            return merge({}, state, { shardPreference: action.payload });
        }

        default:
            return state;
    }
}
export default searchParams;
