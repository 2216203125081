import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'redux';
import ReactTooltip from 'react-tooltip';

import {
    BATCH_SCREENING_PAGE_TITLE,
    BREADCRUMBS,
    MODAL_TYPE,
    MULTIPLE_ENTITIES_PREFIX_FILENAME,
    ROUTES,
} from '@constants';
import PopupModel from '@reusable/PopupModal/PopupBuilder';
import popupModelActions from '@reusable/PopupModal/redux/PopupModel.actions';
import BreadCrumbsModel from '@utils/breadCrumbBuilder';
import breadcrumbsActions from '@reusable/Breadcrumbs/redux/Breadcrumbs.actions';
import { withRouteRedirecter } from '@utils/contexts';
import EntityViewTable from '@pages/EntityView/components/EntityViewGridTable/EntityViewTable';
import reportBuilderActions from '@pages/ReportBuilder/redux/ReportBuilder.action';
import mainActions from '../Main/Main.actions';
import OverlaySpinner from '@reusable/OverlaySpinner/OverlaySpinner';
import utils from '@utils/utilities';
import formatRichMessage from '@utils/formatRichMessage';
import EntitiesUsed from './components/EntitiesUsed/EntitiesUsed';
import { isEntityUploadLimitReached } from './utils/validations';
import {
    getEntitiesLoadingStatus,
    isViewNotFoundException,
    getViewName,
} from '@pages/EntityView/components/EntityViewGridTable/helpers/batchHelpers';
import { returnAllEntitiesCount, getUserNotifications, patchUserNotifications, showNotifications } from './EntityViewHelper';
import { POPUP_SIZE_SMALL } from '@constants';
import NotificationsContainer from '@scripts/reusable/NotificationsContainer/NotificationsContainer';


const EntityView = ({ intl }) => {
    const [exportEntitiesPayload, setExportEntitiesPayload] = useState({});
    const [tableHasData, setTableHasData] = useState(false);
    const [isEntitiesCountLoading, setIsEntitiesCountLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [entitiesLoaded, setEntitiesLoaded] = useState(false);
    const [views, setViews] = useState([]);
    const [totalNumberOfEntities, setTotalNumberOfEntities] = useState(0);
    const [notificationsToDisplay, setNotificationsToDisplay] = useState([]);

    const createNotificationHidden = useSelector((state) => state.notificationStatus.createAlertsStatus.hidden);
    const copyNotificationHidden = useSelector((state) => state.notificationStatus.copyEntitiesStatus.hidden);
    const deleteNotificationHidden = useSelector((state) => state.notificationStatus.deleteEntitiesStatus.hidden);
    const refreshNotificationHidden = useSelector((state) => state.notificationStatus.refreshEntitiesStatus.hidden);
    const uploadNotificationHidden = useSelector((state) => state.notificationStatus.uploadEntitiesStatus.hidden);
    const notificationStatus = useSelector((state) => state.notificationStatus);

    const lastReportStatus = useSelector((state) => state.reportBuilder.lastBatchReportStatus);
    const isMultipleEntitiesPageSpinnerVisible = useSelector(
        (state) => state.searchStatus.isMultipleEntitiesPageSpinnerVisible
    );
    const isMultipleEntitiesTrial = useSelector((state) => state.user.appSettings.isMultipleEntitiesTrial);
    const entityUploadLimit = useSelector((state) => state.user.preferences.generalSettings.entityUploadLimit);
    const pollingStatus = useSelector((state) => state.reportBuilder.pollingStatus);
    const dispatch = useDispatch();

    const isEntityLimitReached = isEntityUploadLimitReached(
        totalNumberOfEntities,
        entityUploadLimit,
        isMultipleEntitiesTrial
    );

    const shouldUploadButtonBeDisabled = isMultipleEntitiesTrial && (isEntityLimitReached || isEntitiesCountLoading);

    //purpose of this useEffect is to determine if all of the entities have finished loading
    useEffect(() => {
        const isAnyEntityLoading = getEntitiesLoadingStatus(pollingStatus, tableHasData, tableData);
        setEntitiesLoaded(isAnyEntityLoading);
    }, [pollingStatus, tableHasData]);

    useEffect(() => {
        document.title = BATCH_SCREENING_PAGE_TITLE;
        handleTotalEntitiesCountChange();
        setBreadcrumbs();
        dispatch(mainActions.resetLaunchFrom());

        const { notificationsWereSanitized, sanitizedNotificationStatus } = utils.sanitizeNotifications(notificationStatus)
        notificationsWereSanitized && dispatch(mainActions.sanitizeNotifications(sanitizedNotificationStatus));
    }, []);

    useEffect(() => {
        if (lastReportStatus?.uploadedFinishedStatus)
            dispatch(reportBuilderActions.updateLastBatchReportProperty('uploadedFinishedStatus', false));
    }, [lastReportStatus?.uploadedFinishedStatus]);

    useEffect(() => {
        getUserNotifications()
            .then((notifications) => {
                // notifications come sorted as oldest > newest, so we reverse the array
                showNotifications([...notifications.reverse()]);
                const notificationIds = notifications.map((notification) => notification.id);
                !!notificationIds.length && patchUserNotifications(notificationIds);
            });
    }, [])

    useEffect(() => {
        const mapStoreKeysToHiddenValues = {
            createAlertsStatus: createNotificationHidden,
            copyEntitiesStatus: copyNotificationHidden,
            deleteEntitiesStatus: deleteNotificationHidden,
            refreshEntitiesStatus: refreshNotificationHidden,
            uploadEntitiesStatus: uploadNotificationHidden,
        }

        //make a list of notifications to be displayed based on the data from notificationStatus
        const notificationsToDisplayList = Object.keys(mapStoreKeysToHiddenValues).filter((key) => !mapStoreKeysToHiddenValues[key]);
        setNotificationsToDisplay(notificationsToDisplayList)

    }, [
        createNotificationHidden,
        copyNotificationHidden,
        deleteNotificationHidden,
        refreshNotificationHidden,
        uploadNotificationHidden
    ]);

    const setBreadcrumbs = () => {
        const breadcrumbsObj = new BreadCrumbsModel.Builder(BREADCRUMBS.BATCH_SCREENING).build().breadcrumbs;
        dispatch(breadcrumbsActions.updateBreadcrumbs(breadcrumbsObj));
    };

    const openExportEntitiesModal = () => {
        const currentTime = utils.getCurrentTime();
        const fileName = `${MULTIPLE_ENTITIES_PREFIX_FILENAME}_${utils.formatReportDateWithtTimezoneOffset(
            currentTime
        )}`;
        const popupModel = new PopupModel.Builder(MODAL_TYPE.DOWNLOAD_ENTITIES)
            .setPopupProperty('isVisible', true)
            .setPopupProperty('fileName', fileName)
            .setPopupProperty('exportEntitiesPayload', exportEntitiesPayload)
            .setPopupProperty('onDeliveryApiError', handleEntityViewApiError)
            .build();

        dispatch(popupModelActions.setPopupModel(popupModel));
    };

    const openUploadEntitiesModal = () => {
        const popupModel = new PopupModel.Builder(MODAL_TYPE.UPLOAD_ENTITIES)
            .setPopupProperty('isVisible', true)
            .setPopupProperty('popupSize', POPUP_SIZE_SMALL)
            .setPopupProperty('views', views)
            .setPopupProperty('handleTotalEntitiesCountChange', handleTotalEntitiesCountChange)
            .build();

        dispatch(popupModelActions.setPopupModel(popupModel));
    };

    const handleTotalEntitiesCountChange = async () => {
        setIsEntitiesCountLoading(true);
        await returnAllEntitiesCount().then((count) => setTotalNumberOfEntities(count));
        setIsEntitiesCountLoading(false);
    };

    const handleEntityViewApiError = (error, urlParams = {}) => {
        const { viewId } = urlParams;

        if (isViewNotFoundException(error, viewId)) {
            const viewName = getViewName(views, viewId);
            utils.showNotificationsMessage({
                messageText: 'BatchScreening.page.createViews.deletedView',
                messageType: 'system-error',
                terms: { viewName },
            });

            dispatch(mainActions.setShouldGetAllViews(true));
        } else {
            utils.showNotificationsMessage({
                messageText: 'BatchScreening.generalError.message',
                messageType: 'system-error',
            });
        }
    };

    return (
        <div className="batch-screening-container">
            {isMultipleEntitiesPageSpinnerVisible && <OverlaySpinner className="category-overlay" size="massive" />}
            <div className="batch-screening-header">
                <div className="batch-screening-header-container">
                    <h2 data-track="batch-screening-title">
                        <FormattedMessage id="BatchScreening.page.title" />
                    </h2>
                </div>

                {isMultipleEntitiesTrial && (
                    <div className="batch-screening-limit-container">
                        <EntitiesUsed
                            entityViewCount={totalNumberOfEntities}
                            entityUploadLimit={entityUploadLimit}
                        />
                    </div>
                )}

                <div className="batch-screening-buttons-container">
                    <button
                        className={`batch-screening-export-button button-secondary-lg`}
                        disabled={!tableHasData || !entitiesLoaded}
                        data-testid="batch-screening-export-entities-button-id"
                        data-track="batch-screening-export-entities-button"
                        onClick={openExportEntitiesModal}
                    >
                        <FormattedMessage id="BatchScreening.page.download.entities" />
                    </button>
                    <span
                        data-tip={`${formatRichMessage({ id: 'BatchScreening.page.button.uploadLimitReached' }, intl, {
                            limit: entityUploadLimit,
                        })}`}
                        data-for="upload-entites-tooltip"
                    >
                        <button
                            className={`button-primary-lg batch-screening-header-upload-entities ${shouldUploadButtonBeDisabled
                                ? 'disabled'
                                : ''
                                }`}
                            data-track="batch-screening-page-upload-entities-button"
                            id="upload-entites-button"
                            disabled={shouldUploadButtonBeDisabled}
                            onClick={openUploadEntitiesModal}
                        >
                            <FormattedMessage id="BatchScreening.page.button.uploadEntities" />
                        </button>
                    </span>
                    {isEntityLimitReached && (
                        <ReactTooltip
                            id="upload-entites-tooltip"
                            type="light"
                            border={true}
                            effect="solid"
                            className="tooltips"
                            place="bottom"
                        />
                    )}
                </div>
            </div>
            <EntityViewTable
                updateExportEntitiesPayload={setExportEntitiesPayload}
                updateTableHasData={setTableHasData}
                updateTableData={setTableData}
                entitiesLoaded={entitiesLoaded}
                handleEntityViewApiError={handleEntityViewApiError}
                views={views}
                setViews={setViews}
                intl={intl}
                totalNumberOfEntities={totalNumberOfEntities}
                handleTotalEntitiesCountChange={handleTotalEntitiesCountChange}
                loadedEntitiesCount={tableData.length}
            />
            {notificationsToDisplay.length? (
                    <NotificationsContainer page={ROUTES.SCREENING} notificationsNames={notificationsToDisplay}/>
                ) : <></>
            }
        </div>
    );
};

export { EntityView as TestEntityView };
export default compose(
    withRouter,
    withRouteRedirecter(() => {
        const appSettings = useSelector((state) => state.user.appSettings);

        return {
            shouldRedirect: !appSettings?.isBatchReportsEnabled,
            redirectTo: '/start',
        };
    }),
    injectIntl
)(EntityView);
