import React from 'react';
import { DefaultCheckbox } from '@reusable/Table/components/defaults';
import SelectedItemsDropdown from '@reusable/Table/components/SelectedItemsDropdown';
import { isSelectAllChecked as validateIsSelectAllChecked } from '@pages/EntityView/components/EntityViewGridTable/helpers/batchHelpers';

const SelectionHeader = ({
    onSelectAll,
    selectedItemsList,
    onRemoveItem,
    onClearAll,
    isSelectAllChecked,
    actions,
    dropdownMessages,
    countNoForAll,
    entitiesLoaded,
    shouldDropdownCheckboxBeVisible,
    multipleEntitiesSelectAllSetting,
}) => {
    const countNoForSelected = (isSelectAllChecked || multipleEntitiesSelectAllSetting?.includeDB) && countNoForAll;
    const isChecked_DefaultCheckbox = multipleEntitiesSelectAllSetting
        ? validateIsSelectAllChecked(multipleEntitiesSelectAllSetting)
        : isSelectAllChecked;

    const onSelectAllDropdownCheckbox = (shouldClear) => {
        if (multipleEntitiesSelectAllSetting) {
            onSelectAll({ shouldIncludeDB: true, shouldClear });
        } else {
            onSelectAll();
        }
    };

    return (
        <div className={'selection-header'}>
            <DefaultCheckbox
                onToggleSelect={() => {
                    if (multipleEntitiesSelectAllSetting) {
                        onSelectAll({ shouldIncludeDB: false, shouldClear: isChecked_DefaultCheckbox });
                    } else {
                        onSelectAll();
                    }
                }}
                checked={isChecked_DefaultCheckbox}
                id={'selectAll'}
                value={'selectAll'}
                disabled={!entitiesLoaded} // entitiesLoaded must be still related to all entities because other not-displayed entities can be loaded and they would be automatically selected
            />
            <SelectedItemsDropdown
                selectedItems={selectedItemsList}
                onRemoveItem={onRemoveItem}
                onClearAll={onClearAll}
                onSelectAll={onSelectAllDropdownCheckbox}
                messages={dropdownMessages}
                noPopup={true}
                countNoForAllSelected={countNoForSelected}
                shouldDropdownCheckboxBeVisible={shouldDropdownCheckboxBeVisible}
                isDropdownCheckboxDisabled={!entitiesLoaded}
                isDropdownCheckboxChecked={!!multipleEntitiesSelectAllSetting?.includeDB}
            />
            <div className={'table-actions'} data-for="tooltip-table-actions" data-tip="">
                {actions}
            </div>
        </div>
    );
};

export default SelectionHeader;
