import React, { Fragment } from 'react';
import EsgRatingsCategory from './components/EsgRatingsCategory';
import EsgRatingsOverall from './components/EsgRatingsOverall';
import EsgRatingsUtils from './EsgRatingsUtils';
import ReactTooltip from 'react-tooltip';
import { isEmpty } from 'lodash';
import { DOC_TYPE_SEARCH_EVENT_REQUEST, TOOLTIP_PLACEMENT } from '@constants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ArticleNavigationActions from '@pages/MainSearch/redux/ArticleNavigation.actions';

class EsgRatingsDocumentView extends React.Component {
    constructor(props) {
        super(props);
        this.companySourceInformation = React.createRef();

        this.state = {
            showLessLink: false,
        };
    }

    toggleViewLink = () => {
        this.setState({ showLessLink: !this.state.showLessLink });
        this.searchDocAccessEvent(!this.state.showLessLink);
    };

    componentDidUpdate(prevProps) {
        if (this.props.content !== prevProps.content) {
            const companySourceInformationContent = EsgRatingsUtils.buildCompanySourceInformationContent(
                this.props.content
            );

            if (!isEmpty(companySourceInformationContent)) {
                [...companySourceInformationContent].forEach((element) => {
                    this.companySourceInformation.current.appendChild(element);
                });
            }
        }

        // when navigating to next/previous page, originalArticleInfo changes so the call to Doc Access Event should be updated
        if (prevProps.originalArticleInfo.id !== this.props.originalArticleInfo.id) {
            this.setState({ showLessLink: false });
            this.searchDocAccessEvent(false);
        }
    }

    searchDocAccessEvent(isShowLessActive) {
        const data = {
            ...this.props.originalArticleInfo,
            docType: isShowLessActive ? DOC_TYPE_SEARCH_EVENT_REQUEST.EXPANDED : DOC_TYPE_SEARCH_EVENT_REQUEST.SIMPLE,
        };

        this.props.searchDocAccessEvent(data);
        this.props.setArticleShowMoreActive(isShowLessActive);
    }

    componentDidMount() {
        this.searchDocAccessEvent(this.state.showLessLink);
    }

    render() {
        if (!this.props.content) {
            return null;
        }

        const { categories, ...overallProperties } = EsgRatingsUtils.buildPropertiesForEsgDocument(this.props.content);
        const hasCategories = Object.values(categories)?.length > 0;
        const commonTooltipProperties = {
            type: 'light',
            border: true,
            effect: 'solid',
            className: 'tooltips notranslate',
        };

        return (
            <div className="esg-ratings" data-track="esg_ratings-container">
                <ReactTooltip
                    id={`category-and-subcategory-${TOOLTIP_PLACEMENT.RIGHT}-tooltip`}
                    place={TOOLTIP_PLACEMENT.RIGHT}
                    {...commonTooltipProperties}
                />
                <ReactTooltip
                    id={`category-and-subcategory-${TOOLTIP_PLACEMENT.LEFT}-tooltip`}
                    place={TOOLTIP_PLACEMENT.LEFT}
                    {...commonTooltipProperties}
                />
                <ReactTooltip
                    id={`category-and-subcategory-${TOOLTIP_PLACEMENT.BOTTOM}-tooltip`}
                    place={TOOLTIP_PLACEMENT.BOTTOM}
                    {...commonTooltipProperties}
                />
                {<div className="esg-ratings-company-source-information" ref={this.companySourceInformation} />}
                <EsgRatingsOverall {...overallProperties} />
                {hasCategories && (
                    <Fragment>
                        <div className="esg-ratings-categories" data-track="esg_ratings-categories-container">
                            {Object.values(categories).map((category, index) => (
                                <EsgRatingsCategory
                                    key={index}
                                    columnIndex={index}
                                    hideSubcategories={!this.state.showLessLink}
                                    {...category}
                                />
                            ))}
                        </div>
                        <div
                            className="view-more-less-link"
                            data-track="esg_ratings-view-more-less-link"
                            onClick={this.toggleViewLink}
                        >
                            <span className="view-more-less-text">
                                {!this.state.showLessLink ? 'Show more' : 'Show less'}
                            </span>
                            <span className={`icon la-Triangle${!this.state.showLessLink ? 'Down' : 'Up'}`} />
                        </div>
                    </Fragment>
                )}
                <div className="copyright" data-track="esg_ratings-copyright">
                    {overallProperties.copyright}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            setArticleShowMoreActive: ArticleNavigationActions.setArticleShowMoreActive,
        },
        dispatch
    );
};

export default connect(null, mapDispatchToProps)(EsgRatingsDocumentView);
