// @flow
import * as React from 'react';
import { COMPANY, COMPANY_SEARCH, EDIT_SEARCH_URL, PERSON, ROUTES } from '@constants';
import { FormattedMessage } from 'react-intl';
import AlertBtn from './CreateAlertBtn';
import { Link } from 'react-router';
import { withEditAlertContext } from '@contexts/EditAlert';
import type { SearchResultsType } from './typeGuards/ResultsList.typeGuards';
import SaveSearchButton from './SaveSearchButton';
import AddToEntityViewButton from './AddToEntityViewButton';

type Props = {
    searchType: string,
    searchedQueryString: string,
    totalArticleCount: {
        count: number,
        formatted: string,
    },
    articleType: string,
    includeTerms?: Array<string>,
    excludeTerms?: Array<string>,
    removeFilter?: (e: SyntheticEvent<HTMLElement>) => void,
    resetFilter?: (e: SyntheticEvent<HTMLElement>) => void,
    similarNames?: ?Array<string>,
    defaultSimilarNames?: ?boolean,
    dateRange?: ?string,
    defaultDateRange?: ?boolean,
    searchResults?: SearchResultsType,
    isArticleListLoaded?: boolean,
    company?: ?Array<Object>,
    defaultCompany?: ?boolean,
    companyMentions?: ?number,
    personMentions?: ?number,
    proximity?: ?string,
    defaultProximity?: ?boolean,
    source?: ?Array<Object>,
    sourceName?: ?Array<Object>,
    sourceType?: ?Array<Object>,
    defaultSource?: ?boolean,
    defaultSourceName?: ?boolean,
    language: string,
    defaultLanguage?: ?boolean,
    geography?: ?Array<Object>,
    defaultGeography?: ?boolean,
    contentLanguage?: ?string,
    docketStatus?: ?Array<Object>,
    dunsFilter?: ?string,
    subject?: ?Array<Object>,
    defaultSubject?: ?boolean,
    industry?: ?Array<Object>,
    defaultIndustry?: boolean | null,
    fuzzySearch?: boolean | null,
    fuzzyThreshold?: string,
    fuzzyThresholdPersonLock?: boolean,
    newFuzzyEnabled?: boolean,
    fuzzyOn?: boolean,
    suggestedNamesEnabled?: boolean,
    negativityLevels?: Array<string>,
    defaultNegativityLevels?: Array<string>,
    esgFactors?: ?Array<Object>,
    defaultEsgFactors?: ?boolean,
    alertsLimitReached: boolean,
    activeAlertsLimit: number,
    openSaveSearchPopup: () => void,
    shouldDisplaySaveSearchButton: boolean,
    isSavingSearch: boolean,
    isSaveSearchButtonDisabled: boolean,
    addEntityToEntityView: () => void,
    shouldAddToEntityViewButtonDisplay: boolean,
    isAddToEntityViewButtonDisabled: boolean,
    isSearchComplete: boolean,
    location: Object,
    updatePrevPath: (path: string | Object) => void,
    prevPath: string,
};

const ResultsListHeader = (props: Props): React$Element<'div'> => {
    const {
        alertsLimitReached,
        activeAlertsLimit,
        searchType,
        articleType,
        searchedQueryString,
        totalArticleCount,
        shouldDisplaySaveSearchButton,
        openSaveSearchPopup,
        isSavingSearch,
        isSaveSearchButtonDisabled,
        updatePrevPath,
        prevPath,
        shouldAddToEntityViewButtonDisplay,
        addEntityToEntityView,
        isAddToEntityViewButtonDisabled,
        location,
        isSearchComplete,
    } = props;

    const searchTypeValue: string = searchType === COMPANY_SEARCH ? COMPANY : PERSON;
    const computedPrevPath: string | Object = prevPath === ROUTES.SCREENING ? prevPath : location;

    return (
        <div className="results-list-header-container">
            <div className="results-list-header notranslate">
                <div className="results-list-row">
                    <h1 className="results-text">
                        {searchTypeValue === COMPANY ? (
                            <b>
                                <FormattedMessage id="ResultListHeader.resultsForCompanyCheck" />
                            </b>
                        ) : (
                            <b>
                                <FormattedMessage id="ResultListHeader.resultsForPersonCheck" />
                            </b>
                        )}
                        {searchedQueryString} ({totalArticleCount ? totalArticleCount.formatted : '0'})
                    </h1>
                    <div className="results-edit-wrapper">
                        <EditSearchButton onClick={() => updatePrevPath(computedPrevPath)} />
                    </div>
                </div>
                {shouldAddToEntityViewButtonDisplay && (
                    <AddToEntityViewButton
                        onClickHandler={addEntityToEntityView}
                        isButtonDisabled={isAddToEntityViewButtonDisabled}
                    />
                )}
                {shouldDisplaySaveSearchButton && (
                    <SaveSearchButton
                        onClickHandler={openSaveSearchPopup}
                        isButtonDisabled={isSaveSearchButtonDisabled}
                        isSavingSearch={isSavingSearch}
                    />
                )}
                <AlertBtn
                    articleType={articleType}
                    alertsLimitReached={alertsLimitReached}
                    activeAlertsLimit={activeAlertsLimit}
                    updatePrevPath={updatePrevPath}
                    prevPath={prevPath}
                    isSearchComplete={isSearchComplete}
                />
            </div>
        </div>
    );
};

const EditSearchButton = withEditAlertContext(
    ({ editingAlert, onClick }) =>
        !editingAlert && (
            <Link to={EDIT_SEARCH_URL} onlyActiveOnIndex={false} className={'edit-search-link'} onClick={onClick}>
                <FormattedMessage id={'ManageAlerts.editAlert.editSearch'} />
            </Link>
        )
);

export default (ResultsListHeader: React$StatelessFunctionalComponent<Props>);
