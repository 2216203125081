import React, {Fragment} from 'react';
import hasError from '@reusable/Table/hoc/hasError';
import hasLoader from '@reusable/Table/hoc/hasLoader';
import withPagination from '@reusable/Table/hoc/withPagination';
import withSearch from '@reusable/Table/hoc/withSearch';
import withSelection from '@reusable/Table/hoc/withSelection';
import withSorting from '@reusable/Table/hoc/withSorting';
import withAlertsSortPersist from '@reusable/Table/hoc/withAlertsSortPersist';
import isEmpty from '@reusable/Table/hoc/isEmpty';
import withAutoLoad from '@reusable/Table/hoc/withAutoLoad';
import composeTable from '@reusable/Table/composeTable';
import {compose} from 'redux';
import {ACTION_TYPES, COMPANY_SEARCH, ERRORS, NOTIFICATION_STORE_KEYS, ROUTES, SORT_DIRECTION} from '@constants';
import {
    AlertsSortableHeader,
    DefaultActivePill,
    DefaultLoader,
    DefaultSelectableRows,
    DeleteBtn,
    PauseBtn,
    ResumeBtn,
} from '@reusable/Table/components/defaults';
import AddToEntityViewButton from '@MainSearch/components/AddToEntityViewButton';
import {FormattedMessage} from 'react-intl';
import utils from '@utils/utilities';
import AlertUtils from '@utils/alertsUtils';
import {AlertName, AlertSources, AnonymizedMyAlertActions, MyAlertsEmpty} from '@ManageAlerts/components/misc';
import TruncatedTerm from '@ManageAlerts/components/TruncatedTerm';
import TruncatedEmails from '@ManageAlerts/components/TruncatedEmails';
import withConfirmationModal from '@reusable/Modal/hoc/withConfirmationModal';
import withAlertsCounter from '@reusable/Table/hoc/withAlertsCounter';
import searchUtils from '@pages/MainSearch/SearchUtils';
import costCodeUtils from '@utils/costCodeUtils';
import {useDispatch, useSelector} from 'react-redux';
import notificationService from '@utils/notificationService';
import snackbarUtils from "@reusable/SnackbarWithAutohide/snackbarUtils";
import mainActions from "@Main/Main.actions";

/**
 * This is the Table Consumer component for MyAlerts
 * @type {any | *}
 */
const columnLoader = () => <DefaultLoader inline={'inline'} />;
const LoadableDefaultActivePill = hasLoader(columnLoader)(DefaultActivePill); // adds a loader to the Active/Inactive status cell
const StatusAndFrequency = ({ alert }) => {
    return (
        <div className="status-frequency">
            <LoadableDefaultActivePill data={{ isLoading: alert.active === null }} isActive={alert.active} />
            <span>
                <FormattedMessage
                    id={'Alerts.createAlert.frequency.label.' + alert.scheduleMeta.frequency.toLowerCase()}
                />
            </span>
        </div>
    );
};

const TermAndType = ({ alert }) => {
    return (
        <Fragment>
            <TruncatedTerm searchTerm={alert.searchQuery} className="alerts-search-query" />
            {alert.searchQueryType ? (
                <div className="alerts-search-query-type">
                    {alert.searchQueryType === COMPANY_SEARCH ? (
                        <FormattedMessage id="General.label.company" />
                    ) : (
                        <FormattedMessage id="General.label.person" />
                    )}
                </div>
            ) : null}
        </Fragment>
    );
};

const formattedAlertDate = (date, isLegacy) => {
    let dateComposer = {
        format: () => {
            date = isLegacy ? new Date(date) : date;
            return {
                date: utils.getUserLocaleDate(date).format('ll'), //get only date
                time: utils.getUserLocaleDate(date).format('LT'), //get only time
            };
        },
    };

    return (
        <div className="formatted-date">
            <span>{dateComposer.format().date}</span>
            <span>{dateComposer.format().time}</span>
        </div>
    );
};

const Table = compose(
    // Composing the functionalities
    hasError(/* Default error component */),
    withAutoLoad,
    withSelection,
    withSearch,
    withAlertsCounter,
    withSorting,
    withAlertsSortPersist,
    withPagination
)(
    // Now composing the table
    composeTable(
        AlertsSortableHeader,
        compose(
            // Adding a loader when is loading and
            hasLoader(/* Default loader component */),
            isEmpty(MyAlertsEmpty)
        )(DefaultSelectableRows)
    )
);

/**
 * This is the main Alerts Consumer table
 * @param props
 * @returns {XML}
 * @constructor
 */
const MyAlertsTableConsumer = (props) => {
    const shouldAutoRefresh = useSelector((state) => state.alerts.pollingStatus);
    const notificationStatus = useSelector((state) => state.notificationStatus)
    const dispatch = useDispatch();

    const transformRow = (object) => ({
        key: object.id,
        columns: {
            name: <AlertName alert={object} />,
            searchTerm: <TermAndType alert={object} />,
            sources: <AlertSources sources={object.contentTypes} />,
            active: <StatusAndFrequency alert={object} />,
            recipients: <TruncatedEmails emails={object.recipientData.emails} />,
            subscribed: object.subscribed ? 'YES' : 'NO',
            createdDate: formattedAlertDate(
                object.migrated ? object.legacyCreationDate : object.createdDate,
                object.migrated
            ),
            lastUpdated: formattedAlertDate(object.lastUpdated),
            actions: (
                <AnonymizedMyAlertActions
                    object={object}
                    onResume={handleResumeAlert}
                    onPause={handlePauseAlert}
                    onDelete={handleDeleteAlert}
                />
            ),
        },
        object,
    });

    const getSelectedAlertObjects = () => props.selection.selectedItems.map((selectedItem) => selectedItem.object);

    const setAlertsStatus = (alerts, active) => {
        props.data.onUpdateItems(
            alerts.map((alert) => ({ ...alert, active })),
            'id'
        );
    };

    const handleResumeAlert = (alert) => {
        setAlertsStatus([alert], null);
        props.alerts
            .onAlertsResume([alert])
            .then((response) => {
                const activatedAlertIds = response.activatedAlertIds;
                setAlertsStatus([alert], activatedAlertIds.includes(alert.id));
            })
            .catch((e) => {
                setAlertsStatus([alert], false);
                console.error(e);
                utils.showNotificationsMessage({
                    messageText: 'ManageAlerts.ErrorMessage.activateAlert',
                    messageType: 'system-error',
                });
            });
    };

    const handlePauseAlert = (alert) => {
        setAlertsStatus([alert], null);
        props.alerts
            .onAlertsPause([alert])
            .then(() => {
                setAlertsStatus([alert], false);
            })
            .catch((e) => {
                setAlertsStatus([alert], true);
                console.error(e);
                utils.showNotificationsMessage({
                    messageText: 'ManageAlerts.ErrorMessage.deactivateAlert',
                    messageType: 'system-error',
                });
            });
    };

    const handleDeleteAlert = (alert) => {
        const modalProps = {
            primaryBtnText: 'General_CoreFunctionality_UIText_general.delete',
            title: <FormattedMessage id={'PopupBuilder.title.deleteAlert'} />,
            description: <FormattedMessage id={'ManageAlerts.alertDeletion'} values={{ alert: alert.name }} />,
        };
        props.onConfirmModal(modalProps, () => {
            // removes item from selection if exists
            props.selection.onChangeSelection(props.selection.selectedItems.filter((item) => item.key !== alert.id));
            props.data.onChangeLoadState(true);

            const onFinishedDeletePolling = () => {
                if (props.data.rows.length === 1 && props.pagination.pageNumber > 0) {
                    props.data.onChangeLoadState(false, () => {
                        props.onChangePagination({ pageNumber: props.pagination.pageNumber - 1 }, handleLoadData);
                    });
                } else {
                    props.data.onChangeLoadState(false, handleLoadData);
                }
            };

            props.alerts
                .onAlertsDelete([alert])
                .then((batchId) => {
                    //set the initial data for the delete snackbar
                    const deleteAlertsNotificationData = snackbarUtils.getProcessingStatusInitialData({
                        actionType: ACTION_TYPES.DELETE.concat('ALERT'),
                        totalCount: 1,
                    });
                    dispatch(mainActions.addNewBatchId({batchId, data: deleteAlertsNotificationData, keyInTheStore: NOTIFICATION_STORE_KEYS.DELETE_ALERT}));
                    notificationService.pollDeleteAlert({
                        batchId,
                        route: ROUTES.MANAGE_ALERTS,
                        onFinished: () => onFinishedDeletePolling(),
                    });
                })
                .catch(() => {
                    utils.showNotificationsMessage({
                        messageText: 'ManageAlerts.ErrorMessage.deleteAlert',
                        messageType: 'system-error',
                        terms: { term: alert.name },
                    });
                    props.data.onChangeLoadState(false);
                });
        });
    };

    const handleBulkPause = () => {
        const selectedAlerts = getSelectedAlertObjects();
        setAlertsStatus(selectedAlerts, null);
        props.alerts
            .onAlertsPause(selectedAlerts)
            .then(() => {
                setAlertsStatus(selectedAlerts, false);
            })
            .catch(() => {
                selectedAlerts.forEach((alert) => {
                    if (alert.active) {
                        setAlertsStatus([alert], true);
                    } else {
                        setAlertsStatus([alert], false);
                    }
                });
                utils.showNotificationsMessage({
                    messageText: 'ManageAlerts.ErrorMessage.deactivateAlerts',
                    messageType: 'system-error',
                });
            });
    };

    const handleBulkResume = () => {
        const selectedItems = getSelectedAlertObjects();
        const validAlerts = selectedItems.filter(
            // alerts that have emails and can be resumed
            (alert) => alert.recipientData.emails.length > 0 || alert.active === true
        );

        if (validAlerts.length < selectedItems.length) {
            utils.showNotificationsMessage({
                messageText: 'ManageAlerts.ErrorMessage.resumeInvalidAlerts',
                messageType: 'system-error',
            });
        }
        setAlertsStatus(validAlerts, null);
        props.alerts
            .onAlertsResume(validAlerts)
            .then((response) => {
                const activatedAlertIds = response.activatedAlertIds;
                validAlerts.forEach((alert) => {
                    setAlertsStatus([alert], false);

                    if (activatedAlertIds.includes(alert.id)) {
                        setAlertsStatus([alert], true);
                    }
                });
            })
            .catch(() => {
                validAlerts.forEach((alert) => {
                    if (alert.active) {
                        setAlertsStatus([alert], true);
                    } else {
                        setAlertsStatus([alert], false);
                    }
                });
                utils.showNotificationsMessage({
                    messageText: 'ManageAlerts.ErrorMessage.activateAlerts',
                    messageType: 'system-error',
                });
            });
    };

    const handleAddToEntityView = () => {
        const { billingId, timezone, startEachArticleOnNewPage } = props.additionalData;
        const { language } = props.data;
        const selectedItems = getSelectedAlertObjects();
        const costCode = costCodeUtils.getCostCode();
        const payloadData = {
            billingId: billingId ?? costCodeUtils.generateBillingId(),
            costCode,
            timezone,
            language,
            startEachArticleOnNewPage,
        };
        const mappedDataForMultipleEntities = AlertUtils.mapAlertDataForMultipleEntities(selectedItems, payloadData);
        searchUtils.createEntitiesFromAlerts(mappedDataForMultipleEntities, notificationStatus);
    };

    const handleBulkDelete = () => {
        const modalProps = {
            primaryBtnText: 'General_CoreFunctionality_UIText_general.delete',
            title: <FormattedMessage id={'PopupBuilder.title.deleteAlerts'} />,
            description: (
                <FormattedMessage
                    id={'ManageAlerts.multipleAlertDeletion'}
                    values={{ alertsCount: getSelectedAlertObjects().length }}
                />
            ),
        };
        props.onConfirmModal(modalProps, () => {
            props.data.onChangeLoadState(true);

            const onFinishedDeletePolling = () => {
                props.data.onChangeLoadState(false, props.data.onResetState);
            };

            props.alerts
                .onAlertsDelete(getSelectedAlertObjects())
                .then((batchId) => {
                    //set the initial data for the delete alerts snackbar
                    const deleteAlertsNotificationData = snackbarUtils.getProcessingStatusInitialData({
                        actionType: ACTION_TYPES.DELETE.concat('ALERT'),
                        totalCount: getSelectedAlertObjects().length,
                    });
                    dispatch(mainActions.addNewBatchId({batchId, data: deleteAlertsNotificationData, keyInTheStore: NOTIFICATION_STORE_KEYS.DELETE_ALERT}));
                    notificationService.pollDeleteAlert({
                        batchId,
                        route: ROUTES.MANAGE_ALERTS,
                        onFinished: () => onFinishedDeletePolling(),
                    });
                })
                .catch(() => {
                    utils.showNotificationsMessage({
                        messageText: 'ManageAlerts.ErrorMessage.deleteAlerts',
                        messageType: 'system-error',
                    });
                    props.data.onChangeLoadState(false);
                });
        });
    };

    const areAlertsSelected = () => props.selection.selectedItems.length > 0;
    const areAlertsActive = () =>
        props.selection.selectedItems.filter((selectedItem) => selectedItem.object.active).length;
    const areAlertsPaused = () =>
        props.selection.selectedItems.filter((selectedItem) => !selectedItem.object.active).length;
    const areAlertsUpdating = () =>
        props.selection.selectedItems.filter((selectedItem) => selectedItem.object.active === null).length ||
        props.data.isLoading;

    const actions = (
        <Fragment>
            <PauseBtn onClick={handleBulkPause} disabled={!areAlertsActive() || areAlertsUpdating()} />
            <ResumeBtn onClick={handleBulkResume} disabled={!areAlertsPaused() || areAlertsUpdating()} />
            <DeleteBtn onClick={handleBulkDelete} disabled={!areAlertsSelected() || areAlertsUpdating()} />
            {props.additionalData.isBatchReportsEnabled && (
                <AddToEntityViewButton
                    onClickHandler={handleAddToEntityView}
                    isButtonDisabled={!areAlertsSelected() || areAlertsUpdating()}
                />
            )}
        </Fragment>
    );

    const headers = [
        { columnKey: 'name', label: <FormattedMessage id="ManageAlertsTableHeader.alertName" />, sortable: true },
        {
            columnKey: 'searchTerm',
            label: <FormattedMessage id="ManageAlertsTableHeader.searchTermType" />,
            sortable: false,
        },
        { columnKey: 'sources', label: <FormattedMessage id="ManageAlertsTableHeader.sources" />, sortable: false },
        { columnKey: 'active', label: <FormattedMessage id="ManageAlertsTableHeader.status" />, sortable: true },
        {
            columnKey: 'recipients',
            label: <FormattedMessage id="ManageAlertsTableHeader.recipients" />,
            sortable: false,
        },
        { columnKey: 'createdDate', label: <FormattedMessage id="ManageAlertsTableHeader.created" />, sortable: true },
        {
            columnKey: 'lastUpdated',
            label: <FormattedMessage id="ManageAlertsTableHeader.lastUpdated" />,
            sortable: true,
        },
        { columnKey: 'actions', label: null, sortable: false },
    ];

    const transformHeaders = (headers) => {
        headers.map((header, index) => {
            if (header.columnKey === props.sorting.sortBy) {
                headers[index] = {
                    ...headers[index],
                    sortedBy: true,
                    direction: props.sorting.direction,
                    useActiveClass: 'alerts-active-sort-field',
                };
            } else {
                headers[index] = {
                    ...headers[index],
                    sortedBy: false,
                    direction: SORT_DIRECTION.ASC,
                    useActiveClass: null,
                };
            }
        });

        return headers;
    };

    // adds specific error message when we cant get the alerts
    const handleLoadData = () => {
        return props.data.onLoadData().catch((e) => {
            if (e === ERRORS.TABLE_LOAD_DATA_GET) {
                utils.showNotificationsMessage({
                    messageText: 'ManageAlerts.ErrorMessage.getAlerts',
                    messageType: 'system-error',
                });
            }
        });
    };

    return (
        <div className={'generic-table alerts-table myAlerts-table'}>
            <Table
                {...props}
                headers={transformHeaders(headers)}
                data={{
                    ...props.data,
                    rows: props.data.rows && props.data.rows.map(transformRow),
                    onLoadData: handleLoadData,
                    language: props.data.language,
                }}
                autoRefresh={shouldAutoRefresh}
                onTransformRow={transformRow}
                selection={{
                    ...props.selection,
                    actions,
                }}
            />
        </div>
    );
};

export default withConfirmationModal(MyAlertsTableConsumer);
