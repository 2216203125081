import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ReactGrid } from '@silevis/reactgrid';
import { CellCountTemplate } from './components/cell-types/CellCount';
import { CommentsCellTemplate } from './components/cell-types/CommentsCell';
import { DateTemplate } from './components/cell-types/Date';
import { BatchHeaderTemplate } from './components/cell-types/BatchHeader';
import { NegativeNewsRiskTemplate } from './components/cell-types/NegativeNewsRisk';
import { SanctionsRiskTemplate } from './components/cell-types/SanctionsRisk';
import { EsgRatingsCellTemplate } from './components/cell-types/EsgRatingsCell';
import { EntityNameTemplate } from './components/cell-types/EntityName';
import {
    byKey,
    defaultGetSelectedName,
    getNumberOfAllSelectedEntities,
    getRows,
    isRowSelected,
    reorderArray,
} from './helperFunctions';
import {
    extractCommentsList,
    shouldClearSelection,
    shouldGetTableData,
} from '@pages/EntityView/components/EntityViewGridTable/helpers/batchHelpers';
import { DefaultError } from '@reusable/Table/components/defaults';
import { TableLoaderTemplate } from './components/cell-types/TableLoader';
import { differenceBy } from 'lodash';
import { DEFAULT_MESSAGES } from '@reusable/Table/components/SelectedItemsDropdown';
import ReactTooltip from 'react-tooltip';
import SelectionHeader from './components/SelectionHeader';
import overridePosition from './utils/overridePosition';
import { BATCH_SCREENING_PAGE_SIZE, REPORT_TYPE, TOOLBAR_ACTIONS } from '@constants';
import mainActions from '@pages/Main/Main.actions';
import { isSelectAllChecked } from '@pages/EntityView/components/EntityViewGridTable/helpers/batchHelpers';
import { MODAL_TYPE } from 'scripts/constants';
import usePrevious from '@scripts/hooks/usePrevious';

const GridTable = ({
    onApiGet,
    onApiGetError,
    triggerPolling,
    autoRefresh,
    emptyTableComponent,
    isCustomView,
    shouldDisplay = false,
    isSnapshotVisible = false,
    withFilteringArea = true,
    searchingArea,
    filteringArea,
    actions = <></>,
    tableColumns,
    columnMap,
    getRowStructure,
    onGetSelectedName,
    errorMessage,
    withSelectionProps,
    customUseEffectFunction,
    customUseEffectDependencies,
    handleContextMenu,
    handleColumnsOrderChange,
    handleColumnsResizeChange,
    onSelectAllEntities,
    extraProps,
    dropdownMessages = DEFAULT_MESSAGES,
    sortInfo,
    customFocusObject = false,
    entityViewCount,
    updateTableHasData,
    updateTableData,
    searchEntity,
    lastSelectedView,
    entitiesLoaded,
    lastPerformedAction,
    setLastPerformedAction,
    uploadPollingActive,
    prevUploadPollingActive,
    popupModalType,
    showPopupModal,
}) => {
    // hooks
    const intl = useIntl();
    const language = useSelector((state) => state.user.preferences.language);
    const loader = useRef(null);
    const dispatch = useDispatch();
    // state
    const [tableData, setTableData] = useState([]);
    const [tableHasData, setTableHasData] = useState(false);
    const [columns, setColumns] = useState(tableColumns);
    const [rows, setRows] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selection, setSelection] = useState({
        selectedItems: [],
        actions: {},
    });
    const [page, setPage] = useState(0);
    const [isBackToTopButtonVisible, setIsBatchToTopButtonVisible] = useState(false);
    const [observer, setObserver] = useState(null);
    const isAddingToEntityView = useSelector((state) => state.searchStatus.isAddingToEntityView);
    const prevIsAddingToEntityView = usePrevious(isAddingToEntityView);

    // Local constants
    const reactGridElement = document.querySelector('#reactgrid-scrollarea');
    const EmptyTableTemplate = emptyTableComponent;

    const { selectAllSetting, setSelectAllSetting, setUnselectedItemIds, setSelectedItems } = withSelectionProps;

    // transform selected item to a plain list item (with label/value properties)
    const selectedItemsList = selection.selectedItems.map((item) => ({
        value: item.id,
        label: onGetSelectedName ? onGetSelectedName(item) : defaultGetSelectedName(item),
        row: item,
    }));

    const shouldDropdownCheckboxBeVisible =
        entityViewCount > BATCH_SCREENING_PAGE_SIZE && isSelectAllChecked(selectAllSetting);

    // observer function to increment page
    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
            setIsLoading(true);
            setPage((prev) => prev + 1);
        }
    }, []);

    const getTableData = useCallback(
        async (clearSelection = false, loadMoreData = false, pageNumber = 0) => {
            setIsLoading(true);
            const { request, payload } = onApiGet(pageNumber);

            request
                .then((res) => {
                    const [response, error] = res;
                    if (error) return;

                    //extract the comments list for all entities and set it in the state of the parent component via callback
                    const { setCommentsList } = extraProps.comments;
                    const comments = extractCommentsList(response);

                    if (comments) {
                        !loadMoreData && extraProps.comments.setCommentsList(comments);
                        loadMoreData &&
                            response.length &&
                            setCommentsList((prevCommentsList) => [...prevCommentsList, ...comments]);
                    }

                    let formattedData = [...response];
                    const tableDataWithSelection = response.map((row) => ({
                        ...row,
                        selected: clearSelection ? false : isRowSelected(selection, row),
                    }));
                    formattedData = [...tableDataWithSelection];

                    if (loadMoreData && tableData.length && formattedData.length) {
                        // appends more data to the table based on page increase
                        setTableData((prevTableData) => [...prevTableData, ...formattedData]);

                        // select loaded entities if selectAll is checked
                        if (isSelectAllChecked(selectAllSetting) && !clearSelection) {
                            handleChangeSelection([...selection.selectedItems, ...formattedData]);
                        }
                    }

                    // loads the default amount of data to the table
                    !loadMoreData && setTableData(formattedData);
                    setIsLoading(false);

                    const unreadyEntities = response.filter((entity) => !entity.isReady);

                    if (unreadyEntities?.length) loadUnreadyEntities(unreadyEntities);

                    if (
                        !loadMoreData &&
                        tableData.length &&
                        formattedData.length &&
                        isSelectAllChecked(selectAllSetting)
                    ) {
                        const unselectedItems = differenceBy(tableData, selection.selectedItems, byKey);
                        const newSelectedItems = differenceBy(formattedData, unselectedItems, byKey);
                        // automatically select new uploaded entities if Select All checkbox is checked
                        handleChangeSelection(newSelectedItems);
                    }
                })
                .catch((error) => {
                    console.log('catch', error);
                    setHasError(true);
                    setIsLoading(false);
                    payload && onApiGetError(error, payload);
                });
        },
        [
            onApiGet,
            page,
            selection,
            selectAllSetting,
        ]
    );

    //implements intersection observer for infinite scroll
    useEffect(() => {
        const option = {
            root: document.querySelector('#reactgrid-scrollarea'),
            rootMargin: '20px',
            threshold: 1,
        };
        !hasError && setObserver(new IntersectionObserver(handleObserver, option));
    }, [handleObserver, hasError]);

    useEffect(() => {
        const hasData = !!tableData.length;

        updateTableHasData(hasData);
        dispatch(mainActions.setEntityViewTableHasData(hasData));
        hasData && updateTableData(tableData);
        setTableHasData(hasData ? 1 : 0);
    }, [tableData]);

    useEffect(() => {
        if (!isLoading && !hasError) {
            if (loader.current && tableData.length > BATCH_SCREENING_PAGE_SIZE - 1 && !isLoading) {
                observer && observer.observe(loader.current);
            }

            if (
                !loader.current ||
                tableData.length < BATCH_SCREENING_PAGE_SIZE ||
                isLoading ||
                tableData.length === entityViewCount
            ) {
                observer && observer.unobserve(loader.current);
            }
        }
        return () => {
            !hasError && observer && observer.disconnect();
        };
    }, [observer, tableData, page, isLoading, entityViewCount, hasError]);

    // updates grid table data based on paging
    useEffect(() => {
        page && tableData.length <= entityViewCount && getTableData(false, true, page);
    }, [page, entityViewCount]);

    // updates grid table data based on sorting
    useEffect(() => {
        getTableData();
    }, [sortInfo, onApiGet]);

    // updates grid table data when the polling of the upload entities popup finished and the modal closed
    useEffect(() => {
        const isUploadEntitiesModal = popupModalType === MODAL_TYPE.UPLOAD_ENTITIES;
        const shouldFetchTableData =
            isUploadEntitiesModal &&
            !showPopupModal &&
            uploadPollingActive !== prevUploadPollingActive &&
            prevUploadPollingActive;
        (shouldFetchTableData || (prevIsAddingToEntityView && !isAddingToEntityView)) && getTableData();
    }, [popupModalType, showPopupModal, uploadPollingActive, prevUploadPollingActive, isAddingToEntityView]);

    // renders grid table rows based on tableData and isLoading
    useEffect(() => {
        setRows(
            getRows(
                tableData,
                tableColumns.map((c) => c.columnId),
                columnMap,
                getRowStructure,
                shouldDisplay,
                isSnapshotVisible,
                emptyTableComponent,
                isLoading,
                handleToggleSelect,
                intl,
                extraProps,
                tableColumns.map((c) => c.sortDirection)
            )
        );
        setColumns(tableColumns);
    }, [
        tableData,
        isLoading,
        tableColumns,
        language,
        extraProps?.comments.activeCommentsSectionRowId,
        extraProps?.comments.commentsList,
        shouldDisplay,
        extraProps?.updateEntityNameProps.isEditingEntityName,
        extraProps?.updateEntityNameProps.activeEntityNameId,
        extraProps?.updateEntityNameProps.currentEntityName,
        extraProps?.updateEntityNameProps.updatedEntityName,
        extraProps?.updateEntityNameProps.entityChangesSaved,
        extraProps?.tableHasData,
        extraProps?.blockedEntities,
    ]);

    // updates grid table data based on constant
    useEffect(() => {
        autoRefresh && getTableData();
    }, [autoRefresh]);

    // updates the selected property of each row when clicking a checkbox
    useEffect(() => {
        const tableDataWithSelection = tableData.map((row) => ({
            ...row,
            selected: isRowSelected(selection, row),
        }));
        setTableData(tableDataWithSelection);
    }, [selection]);

    // is triggered by a toolbar action which clears the selection state and updates the grid table data
    useEffect(() => {
        const clearSelection = shouldClearSelection(withSelectionProps, selection, lastPerformedAction);

        if (clearSelection) {
            handleClearAll();

            if (lastPerformedAction !== TOOLBAR_ACTIONS.refresh) {
                setTableData([]);
                setPage(0);

                if (shouldGetTableData(lastPerformedAction)) {
                    getTableData(true);
                } else {
                    setIsLoading(true);
                }
            }

            lastPerformedAction && setLastPerformedAction(null);
        }
    }, [withSelectionProps, selection, lastPerformedAction]);

    // table data refresh based on custom useEffect function and array of dependencies
    useEffect(() => {
        if (customUseEffectFunction && customUseEffectDependencies) {
            customUseEffectDependencies?.setIsPageResetAfterRefresh(true);
            !customUseEffectDependencies?.isPageResetAfterRefresh && setPage(0);
            customUseEffectFunction(tableData, setTableData);
        }
    }, [
        customUseEffectDependencies?.refreshEntities,
        customUseEffectDependencies?.entitiesNeedUpdate,
        customUseEffectDependencies?.isPageResetAfterRefresh,
        customUseEffectDependencies?.setIsPageResetAfterRefresh,
    ]);

    // set page to 0 when sorting, refreshing
    useEffect(() => {
        setPage(0);
    }, [sortInfo, autoRefresh, searchEntity, lastSelectedView]);

    useEffect(() => {
        reactGridElement && reactGridElement.addEventListener('scroll', backToTopHandler);

        return () => {
            reactGridElement && reactGridElement.removeEventListener('scroll', backToTopHandler);
        };
    }, [reactGridElement]);

    // check if all elements are selected
    useEffect(() => {
        dispatch(mainActions.setAllEntitiesSelected(isSelectAllChecked(selectAllSetting)));
    }, [dispatch, selectAllSetting]);

    // BEGIN - table actions' handlers
    const handleColumnsReorder = (targetColumnId, columnIds) => {
        const indexOfDesiredLocation = columns.findIndex((column) => column.columnId === targetColumnId);
        const columnIdxs = columnIds.map((columnId) => columns.findIndex((c) => c.columnId === columnId));
        let updatedColumns = columns;
        setColumns((prevColumns) => {
            updatedColumns = reorderArray(prevColumns, columnIdxs, indexOfDesiredLocation);
            return updatedColumns;
        });
        handleColumnsOrderChange(updatedColumns);
    };

    const handleColumnResize = (ci, width) => {
        setColumns((prevColumns) => {
            const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
            const resizedColumn = prevColumns[columnIndex];
            const updatedColumn = { ...resizedColumn, width };
            prevColumns[columnIndex] = updatedColumn;
            return [...prevColumns];
        });

        handleColumnsResizeChange(columns);
    };

    const handleCanReorderColumns = (targetColumnId, columnIds) => {
        return (
            targetColumnId !== 'entityName' &&
            !columnIds.includes('entityName') &&
            targetColumnId !== 'comments' &&
            !columnIds.includes('comments')
        );
    };
    // END - table actions' handlers

    // BEGIN - selection handlers
    const handleChangeSelection = (selectedItems) => {
        setSelectedItems(selectedItems);

        if (isSelectAllChecked(selectAllSetting)) {
            // get unselected entities
            const unselectedEntitiesIds = differenceBy(tableData, selectedItems, byKey).map(byKey);
            setUnselectedItemIds(unselectedEntitiesIds);
        }

        setSelection({ ...selection, selectedItems });
    };

    const handleToggleSelect = (row) => {
        let selectedItems = selection.selectedItems;
        if (isRowSelected(selection, row)) {
            selectedItems = selectedItems.filter((item) => item.id !== row.id);

            if (isSelectAllChecked(selectAllSetting) && selectedItems.length === 0)
                onSelectAllEntities({ shouldClear: true });
        } else {
            selectedItems.push(row);

            if (!isSelectAllChecked(selectAllSetting) && selectedItems.length === entityViewCount)
                onSelectAllEntities({ shouldIncludeDB: true, shouldClear: false });
        }
        handleChangeSelection(selectedItems);
    };

    const handleSelectAll = ({ shouldIncludeDB, shouldClear }) => {
        onSelectAllEntities({ shouldIncludeDB, shouldClear });

        let selectedItems = [];
        // this will prevent Batch reports from being selected when using the Select all option
        let customSelection = [];
        if (rows[0].deliveryType) {
            customSelection = [...rows.filter((report) => report.deliveryType !== REPORT_TYPE.BATCH)];
        } else {
            customSelection = [...tableData];
        }
        // adding or subtracting items from the selectedItems list depending on what is selected
        if (differenceBy(customSelection, selection.selectedItems, byKey).length > 0) {
            selectedItems = [
                ...selection.selectedItems,
                ...differenceBy(customSelection, selection.selectedItems, byKey),
            ];
        } else {
            selectedItems = selection.selectedItems;
        }

        if (shouldClear) {
            selectedItems = [];
        }

        handleChangeSelection(selectedItems);
    };

    const handleRemoveItem = (item) => {
        handleToggleSelect(item.row);
    };

    const handleClearAll = () => {
        handleChangeSelection([]);
        setSelectAllSetting({ includeDB: false, limit: 0 });
    };
    // END - selection handlers

    // BEGIN - batck-to-top button handlers
    const backToTopHandler = () => {
        if (reactGridElement.scrollTop > 500) {
            setIsBatchToTopButtonVisible(true);
        } else {
            setIsBatchToTopButtonVisible(false);
        }
    };
    const jumpToTop = () => {
        reactGridElement?.scrollTo(0, 0);
    };
    // END - back-to-top button handlers

    const loadUnreadyEntities = (entities) => {
        const unreadyEntityIds = entities.map(({ id }) => id);
        triggerPolling(unreadyEntityIds);
    };

    return hasError ? (
        <DefaultError defaultMessages={{ errorMessage }} />
    ) : (
        <div className="grid-table-container">
            <div className="grid-table-header-container">
                <SelectionHeader
                    onRemoveItem={handleRemoveItem}
                    onSelectAll={handleSelectAll}
                    onClearAll={handleClearAll}
                    selectedItemsList={selectedItemsList}
                    actions={actions(selection, tableData)}
                    dropdownMessages={dropdownMessages}
                    countNoForAll={getNumberOfAllSelectedEntities(tableData, selectedItemsList, entityViewCount)}
                    entitiesLoaded={entitiesLoaded}
                    shouldDropdownCheckboxBeVisible={shouldDropdownCheckboxBeVisible}
                    multipleEntitiesSelectAllSetting={selectAllSetting}
                />
                <div className="grid-table-header-right">
                        <div className="entity-view-search-box-container">{searchingArea({ setSelection })}</div>
                    {withFilteringArea && (
                        <div className="grid-table-custom-filtering-area">{filteringArea(selection.selectedItems)}</div>
                    )}
                </div>
            </div>
            <ReactTooltip
                type="light"
                offset={{ bottom: 0, left: -80 }}
                overridePosition={overridePosition}
                border={true}
                effect="solid"
                place="bottom"
                html={true}
                className="tooltips entity-name-tooltip"
                id="grid-table-tooltip"
            />
            <ReactTooltip
                type="light"
                offset={{ bottom: 0, left: -80 }}
                overridePosition={overridePosition}
                border={false}
                effect="solid"
                place="bottom"
                html={true}
                className="tooltips counts-tooltip"
                id="grid-table-counts-tooltip"
                backgroundColor="#F2F2F2"
                color="#D2D2D2"
            />
            <ReactTooltip
                type="light"
                offset={{ bottom: 0, left: -70 }}
                overridePosition={overridePosition}
                border={true}
                effect="solid"
                place="bottom"
                html={true}
                className="tooltips negative-news-negativity-changed-tooltip"
                id="grid-table-negative-news-negativity-changed-tooltip"
            />
            <ReactTooltip
                type="light"
                offset={{ bottom: 0, left: -120 }}
                overridePosition={overridePosition}
                border={true}
                multiline={true}
                place="bottom"
                effect="solid"
                html={true}
                className="tooltips"
                id="grid-table-entity-name-tooltip-error"
            />
            <div
                id="reactgrid-scrollarea"
                className={`grid-table ${
                    tableData.length > 9 ? 'grid-table__more-than-nine-entries' : 'grid-table__less-than-nine-entries'
                }`}
            >
                <ReactGrid
                    rows={rows}
                    columns={columns}
                    onColumnsReordered={handleColumnsReorder}
                    canReorderColumns={handleCanReorderColumns}
                    onColumnResized={handleColumnResize}
                    onContextMenu={handleContextMenu}
                    customCellTemplates={{
                        count: new CellCountTemplate(),
                        comments: new CommentsCellTemplate(),
                        date: new DateTemplate(),
                        batchHeader: new BatchHeaderTemplate(),
                        negativeNews: new NegativeNewsRiskTemplate(),
                        sanctions: new SanctionsRiskTemplate(),
                        entityName: new EntityNameTemplate(),
                        esgRatings: new EsgRatingsCellTemplate(),
                        emptyTable: new EmptyTableTemplate(isCustomView),
                        loader: new TableLoaderTemplate(),
                    }}
                    stickyTopRows={tableHasData}
                    stickyLeftColumns={tableHasData}
                    enableRowSelection={false}
                    enableColumnSelection={!!tableData.length}
                    enableRangeSelection={false}
                    verticalStickyBreakpoint={70}
                    focusLocation={customFocusObject}
                />
                <div ref={loader} />
                {isBackToTopButtonVisible && (
                    <button type="button" className="back-to-top la-JumpToTop noprint" onClick={jumpToTop} />
                )}
            </div>
        </div>
    );
};

export default GridTable;
