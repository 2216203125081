import { call, put, fork, select, all } from 'redux-saga/effects';
import articleNavigationActions from '@pages/MainSearch/redux/ArticleNavigation.actions';
import * as selectors from '../selectors/articleNavigationSelectors';
import * as listeners from '../listeners/articleNavigationListeners';
import * as workers from '../workers/articleNavigationWorkers';
import * as requests from '../requests/articleNavigationRequests';
import { requestWithRetry } from '../helpers/mainHelper';

// This action is usually triggered by user from result list by clicking on an article
export function* articleNavigation() {
    // first of all check if we are close to the loading "border" - non-blocking operation
    yield fork(workers.checkBorder);
    // start listening for article navigation controls (prev/next)
    yield all([
        listeners.nextArticle(next),
        listeners.previousArticle(previous),
        listeners.loadNextPage(loadNextPage),
        listeners.loadPreviousPage(loadPreviousPage),
    ]);
}

export function* getDetailsFromArticleView(article) {
    const searchParams = yield select(selectors.theSearchParams);
    const articleId = yield select(selectors.theArticleIdAt(article.id));
    const suggestedNames = searchParams && searchParams.suggestedNames ? searchParams.suggestedNames : [];
    return { articleId, suggestedNames };
}

// triggered when entering article view, checks if is in cache, if not, load article by params (this will have no navigation)
export function* singleArticleView(action) {
    const article = action.payload;
    let articleView = getDetailsFromArticleView(article);
    if (!articleView.articleId) {
        const {
            id,
            query,
            type,
            category,
            billingId,
            docId,
            prefilterQuery,
            proximity,
            includeTerms,
            costCode,
            singleSource,
            fuzzyThreshold,
            terms,
        } = article;

        yield fork(
            workers.loadArticle,
            id,
            query,
            type,
            category,
            billingId,
            docId,
            prefilterQuery,
            proximity,
            includeTerms,
            costCode,
            singleSource,
            fuzzyThreshold,
            articleView.suggestedNames,
            article.articleData,
            terms
        );
    }
}

export function* searchDocAccessEvent(action) {
    const article = action.payload;
    let articleView = getDetailsFromArticleView(article);
    if (!articleView.articleId) {
        const request = {
            lni: article.id,
            searchQuery: article.query || article.searchQuery,
            searchQueryType: article.type || article.searchQueryType,
            category: article.category,
            billingId: article.billingId,
            docId: article.docId,
            prefilterQuery: article.prefilterQuery,
            proximity: article.proximity,
            includeTerms: article.includeTerms || [],
            costCode: article.costCode,
            singleSource: article.singleSource,
            fuzzyThreshold: article.fuzzyThreshold,
            suggestedNames: articleView.suggestedNames,
            articleData: article.articleData,
            terms: article.terms || [],
        };

        yield call(requestWithRetry, requests.searchDocAccessEvent(request, article.docType));
    }
}
// triggered to show an article at a specified index in cache
export function* articleLoader(action) {
    yield fork(workers.loadArticleAtIndex, action.payload);
}

export function* next() {
    const currentIndex = yield select(selectors.theArticleIndex);
    const totalCount = yield select(selectors.theTotalCount);
    if (currentIndex < totalCount) {
        // trigger view article with new index
        yield put(articleNavigationActions.viewArticle(currentIndex + 1));
        // check for load border
        yield fork(workers.checkBorder);
    }
}

export function* previous() {
    const currentIndex = yield select(selectors.theArticleIndex);
    if (currentIndex > 0) {
        // trigger view article with new index
        yield put(articleNavigationActions.viewArticle(currentIndex - 1));
        // check for load border
        yield fork(workers.checkBorder);
    }
}

export function* loadNextPage() {
    const pageNumber = yield select(selectors.theCurrentPage);
    yield call(workers.loadPage, pageNumber + 1);
}

export function* loadPreviousPage() {
    const pageNumber = yield select(selectors.theCurrentPage);
    yield call(workers.loadPage, pageNumber - 1);
}
