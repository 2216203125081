import { reduxStore, action } from '@reduxStore';
import { hashHistory } from 'react-router';
import searchResultsActions from './redux/SearchResults.actions';
import MainSearchApi from './MainSearch.api';
import {
    ACTION_TYPES,
    API_SEARCH_VERSION_4,
    CATEGORIES_EXCLUDED_FROM_PERSON_SEARCH,
    CALENDAR_DATE_FORMAT_BE,
    CATEGORY_NAMES,
    COMPANY_SEARCH,
    DEFAULT_FILENAME,
    SORT_OPTIONS,
    FILTER_INFO,
    FUZZY_NAMES,
    INCLUDE,
    ITEM_VALUE_FIELD,
    LOADED,
    OPERATOR_AND,
    OPERATOR_OR,
    PERSON_SEARCH,
    POST_FILTER_COMPANY,
    POST_FILTER_COMPANY_MENTIONS,
    POST_FILTER_PERSON_MENTIONS,
    POST_FILTER_DATE_RANGE,
    POST_FILTER_EXCLUDE_TERMS,
    POST_FILTER_GEOGRAPHY,
    POST_FILTER_INCLUDE_TERMS,
    POST_FILTER_LANGUAGE,
    POST_FILTER_PROXIMITY,
    POST_FILTER_SEARCH_CONTENT_TYPE,
    POST_FILTER_SEARCH_PUBLICATION_NAME,
    POST_FILTER_SEARCH_SOURCE_NAME,
    POST_FILTER_SEARCH_QUERY,
    POST_FILTER_SEARCH_QUERY_TYPE,
    POST_FILTER_SOURCE,
    POST_FILTER_SOURCE_TYPE,
    POSTFILTER_COMPONENT_TYPE,
    POSTFILTER_TYPE,
    RANGE_TYPE_CUSTOM,
    SOURCES,
    POST_FILTER_TYPES,
    POST_FILTER_SEARCH_SPECIFICALLY,
    POST_FILTER_SEARCH_NAME_PARTY_ENTITY,
    POST_FILTER_SOURCE_NAME,
    UBO_MAIN_CATEGORY,
    POST_FILTER_DOCKET_STATUS,
    DOCKET_STATUS_OPTION,
    LEGAL_SOURCES_TITLES,
    POST_FILTERS_DEFAULT_SORT,
    POST_FILTER_SUBJECT,
    POST_FILTER_INDUSTRY,
    POST_FILTER_LABELS,
    POST_FILTERS_WITH_PATH,
    NESTED_POST_FILTER_PATH,
    POST_FILTER_FUZZY_SEARCH,
    POST_FILTER_FUZZY_THRESHOLD,
    POST_FILTER_EXCLUDE_NON_BUSINESS_NEWS,
    DEFAULT_EXCLUDE_NEWS_TOGGLE_VALUES,
    POST_FILTER_EXCLUDE_NEWS_WIRES,
    SUGGESTED_NAMES,
    NEGATIVITY_BUCKET_NAMES,
    API_DOCUMENTS_COUNT_SEARCH_VERSION,
    POST_FILTER_NEGATIVITY_LEVELS,
    NEGATIVITY_STRINGS_TO_LEVEL,
    NEGATIVITY_LEVELS_ORDER,
    POST_FILTER_TERMS,
    POST_FILTER_ESG_FACTORS,
    ROUTES,
    LAUNCHED_SEARCH_FROM,
    QUERY_SEARCH_TYPES,
    DISPLAY_RISK_SCORES,
    NEGATIVITY_LEVELS,
    NOTIFICATION_STORE_KEYS,

} from '@constants';
import ReportBuilderApi from '../ReportBuilder/ReportBuilderMain.api';
import currentReportActions from '../ReportBuilder/redux/CurrentReport.actions';
import DilQueue, { PROMISE_CANCELED, URL_FUZZY } from '@utils/promiseQueue';
import userPreferencesActions from '@UserPreferences/redux/UserPreferences.actions';
import utils from '@utils/utilities';
import HistoryApi from '../HistoryPage/History.api';
import postFilterConfigActions from './redux/PostFilterConfiguration.actions';
import { cloneDeep, findIndex, intersection, isEmpty, union, values, camelCase, isEqual } from 'lodash';
import adHocSearchActions from '@reusable/AdHocSearch/redux/AdHocSearch.actions';
import categoryUtils, { getCategoryOrder } from '@utils/categoryUtils';
import { withContentSourceFilter, withOptimizedSearchCount } from '@utils/utilities';
import searchParamsActions from './redux/SearchParams.actions';
import costCodeUtils from '@utils/costCodeUtils';
import { searchStatusActions, toggleStatus } from './redux/SearchStatus.action';
import ArticlesUtils from './ArticlesUtils';
import errorUtils from '@utils/errors/error-utils';
import {
    formatUboCategoryPostFilters,
    getUboPostFilterFields,
    isUboCategory,
    isUboCompany,
    uboCategoryNameFormat,
    mapOrganizationToCategory,
    replaceSelectedCompanies,
    getAllTerms,
    sanitizeTerm,
} from '@sagas/helpers/uboHelper';
import { mapParentCatPostFiltersForV4Payload } from '@sagas/helpers/searchHelpers';
import uboApi from '@reusable/UBO/UBO.api';
import uboActions from '../StartPage/redux/Ubo.actions';
import { isInvestigationFromCurrentSearch } from '@sagas/helpers/investigationHelpers';
import * as investigationEvents from '@sagas/constants/investigationConstants';
import investigationActions from './redux/Investigation.actions';
import type { PostFiltersType, QueryObjectType } from './typeGuards/SearchUtils.typeGuards';
import { ACTIONS } from '@sagas/constants/metricConstants';
import suggestedNamesActions from '@reusable/SuggestedNames/redux/SuggestedNames.actions';
import negativeNewsVisualisationActions from './components/negativeNewsVisualisations/redux/NegativeNewsVisualisation.actions';
import sanctionsRiskActions from './components/sanctionsRisk/redux/SanctionsRisk.actions';
import EntityViewApi from '@pages/EntityView/api/EntityViewApi';
import notificationService from '@utils/notificationService';
import articleNavigationActions from './redux/ArticleNavigation.actions';
import snackbarUtils from '@reusable/SnackbarWithAutohide/snackbarUtils';
import mainActions from '@pages/Main/Main.actions';

export function markCategorySearchEnd(categoryName, withError = {}) {
    if (categoryName) {
        reduxStore.dispatch({
            type: ACTIONS.metricSearchEnd,
            payload: {
                [categoryName]: {
                    timeStamp: new Date().getTime(),
                    ...withError,
                },
            },
        });
    }
}

export const searchHelpers = {
    getCountsForCategory(enabledCategories, parentCategory, payload, ts, isEsg = false) {
        let promise = MainSearchApi.getCountsForCategories(payload, ts, isEsg);

        return promise
            .then((response) => {
                // take the timestamp from the documents-count endpoint and compare with the old one to not make multiple calls with the same timestamp
                const { updatedbyadmin, lastupdatedpreferencesdate } = response.headers;
                const lastPreferencesUpdateInfo = { updatedbyadmin, lastupdatedpreferencesdate };
                const categoryCounts =
                    response.body && response.body.languageToCountsMap
                        ? response.body.languageToCountsMap
                        : response.body;
                const negativityRisk = response.body && response.body.negativity ? response.body.negativity : {};
                const topNegativeTerms =
                    response.body && response.body.topNegativeTerms ? response.body.topNegativeTerms : [];
                const isEsgCategory =
                    response.body && Object.getOwnPropertyNames(response.body)[0] === CATEGORY_NAMES.ESG_RATINGS;
                const hasEsgRating = response.body && response.body.esgRatings?.overallRating;
                const breadcrumbs = reduxStore.getState().breadcrumbs;
                const currentLocation = breadcrumbs.items[breadcrumbs.items.length - 1];

                updateCountForCategoryGroup(
                    categoryCounts,
                    enabledCategories,
                    parentCategory,
                    payload.negativityLevels
                );
                if (parentCategory.name === CATEGORY_NAMES.NEGATIVE_NEWS) {
                    reduxStore.dispatch(negativeNewsVisualisationActions.updateNegativityPodRisk(negativityRisk));
                    reduxStore.dispatch(negativeNewsVisualisationActions.updateTopNegativeTerms(topNegativeTerms));
                }

                if (isEsgCategory && hasEsgRating && currentLocation.label === 'BREADCRUMBS.snapshot') {
                    const costCode = costCodeUtils.getCostCode();
                    const billingId = reduxStore.getState().investigation.billingId;
                    const article = response.body.esgRatings.entity[0];
                    const payload = getEsgBillingEventPayload(article);

                    MainSearchApi.billingForSearch({ billingId, costCode });
                    reduxStore.dispatch(
                        articleNavigationActions.searchDocAccessEvent({ ...payload, docType: 'SIMPLE' })
                    );
                }

                // Mark search end for category with children - time (in order to gather loading metrics)
                markCategorySearchEnd(parentCategory.name);

                return { categoryCounts, enabledCategories, parentCategory, lastPreferencesUpdateInfo };
            })
            .catch((err) => {
                //TODO: set error for category
                let errorResponse = {};
                Object.keys(enabledCategories).forEach((key) => {
                    errorResponse[key] = { status: 500, totalSize: 0, errorMessage: err.message };
                });
                parentCategory.hasError = true;
                updateCountForCategoryGroup(errorResponse, enabledCategories, parentCategory);
                setFailedCategory(parentCategory, err);
                console.log('error getting count for parent category', parentCategory.name, err.message);

                // Mark search end for category with children - time + error (in order to gather loading metrics)
                markCategorySearchEnd(parentCategory.name, { error: err.message });
            });
    },

    populateEnabledCategory(payload) {
        let { category, pageSize, countOnly, historyCategoryName, contentSource, isCustomFuzzy, ts, ignoreAllChecked, redirectToResultList } =
            payload;
        category.pageSize = pageSize;
        category.isCustomFuzzy = isCustomFuzzy;

        // we have a different endpoint for ubo count
        if (countOnly && categoryUtils.isDnbCategory(category.name)) {
            return new Promise((resolve) => resolve({}));
        }

        //TODO: move this  check in history logic
        // if the user comes from history we need to pass the contentSource
        if (historyCategoryName && category.name === historyCategoryName) {
            category.contentSource = contentSource;
        }
        return populateElasticCategory(category, ts, countOnly, ignoreAllChecked, redirectToResultList).then(
            ({ category, payload, lastPreferencesUpdateInfo }) => {
                if (!categoryUtils.isDnbCategory(category.name)) {
                    reduxStore.dispatch(searchResultsActions.updateCategory(category));

                    // Mark search end for category - time + error (in order to gather loading metrics)
                    if (!ignoreAllChecked) {
                        let withError = {};

                        if (category.hasError) {
                            withError = { error: category.errorMessage, statusCode: category.statusCode };
                        }

                        markCategorySearchEnd(category.name, withError);
                    }
                }
                return { category, payload, lastPreferencesUpdateInfo };
            }
        );
    },

    checkRequestsOrder() { },
};

export const flattenNamesArray = (names) => {
    // case for when the names parameters is null or undefined
    if(!names) {
        return [];
    // case for when the user triggers search from the Entity View page
    } else if (Array.isArray(names)) {
        return names;
    // case for when the user triggers search from the Home page
    } else if(names?.list) {
        return names.list.filter((nameObj) => nameObj.selected).map((nameObj) => nameObj.name)
    // case for when the fuzzyNames object is 2 levels deep
    } else if(names.fuzzyNames?.list) {
        return names.fuzzyNames?.list.filter((nameObj) => nameObj.selected).map((nameObj) => nameObj.name)
    }
    // fail safe case
    return [];
}

export const computePayloadForSanctionsPod = (data) => {
    const { suggestedNames = {}, fuzzyNames = null, includeTerms = [], excludeTerms = [], proximity = 'all', sort = 'relevance', dateRange = 'all' } = data.postfilters;
    const { query, searchType, isCustomFuzzy, shardPreference, prefilterQuery } = data.searchParams;
    const { fuzzyOn = false, fuzzyThreshold = 'loose' } = data.fuzzySettings;
    const billingId = data.billingId;
    const selectedCostCode = costCodeUtils.getCostCode();

    const sanctionsDateRange = data.adHocSearch && Object.keys(data.adHocSearch).length === 0 && data.adHocSearch.constructor === Object ? dateRange : determineDateRange(data.adHocSearch);
    const fuzzyNamesArray = flattenNamesArray(fuzzyNames);
    const suggestedNamesArray = flattenNamesArray(suggestedNames);

    const payload = {
        prefilterQuery: utils.removeFirstBooleanOperator(prefilterQuery),
        sort: sort,
        includeTerms: includeTerms,
        excludeTerms: excludeTerms,
        proximity: proximity,
        dateRange: sanctionsDateRange,
        language: [],
        contentType: [],
        fuzzyNames: fuzzyNamesArray,    
        suggestedNames: suggestedNamesArray,
        searchQuery: query,
        searchQueryType: searchType,
        pageNumber: 0,
        pageSize: 200,
        category: 'sanctions',
        getSimilarDocs: false,
        isCustomFuzzy: isCustomFuzzy,
        version: 'v2',
        interfaceLanguage: data.language,
        searchEngineOptions: shardPreference,
        fuzzyOn,
        billingId,
        costCode: selectedCostCode
    }

    // only add fuzzyThreshold if fuzzy search is enabled (otherwise this will cause inconsistencies in the search results)
    if(fuzzyOn) {
        payload.fuzzyThreshold = fuzzyThreshold;
    }

    return payload;
};

export const getSanctionsRisk = async (payload) => {
    const ts = Date.now();
    const results = await MainSearchApi.getSanctionsRisk(ts, payload);
    if (results) reduxStore.dispatch(sanctionsRiskActions.updateSanctionsRisk(results));
};

export const getSanctionsTableDataPayload = (categoryPostFilters) => {
    const user = reduxStore.getState().user;
    const searchParams = reduxStore.getState().searchParams;
    const adHocSearch = reduxStore.getState().adHocSearch;
    const fuzzySettings = searchParams.searchType === QUERY_SEARCH_TYPES.SEARCH_FOR_COMPANY ? user.preferences.companyCheck : user.preferences.personCheck;
    const billingId = reduxStore.getState().investigation.billingId;

    const data = {
        language: user.preferences.language,
        postfilters: categoryPostFilters,
        searchParams,
        fuzzySettings,
        adHocSearch,
        billingId,
    }

    return computePayloadForSanctionsPod(data);

}
export const getSanctionsTableData = (categoryPostFilters) => {
    const user = reduxStore.getState().user;
    const displayRiskScores = user.preferences.generalSettings.displayRiskScores === DISPLAY_RISK_SCORES.SHOW;
    const sanctionsAndWatchListsSnapshot = user.sanctionsAndWatchListsSnapshot;

    if (displayRiskScores && sanctionsAndWatchListsSnapshot) {
        const sanctionsPayload = getSanctionsTableDataPayload(categoryPostFilters)
        return getSanctionsRisk(sanctionsPayload);
    }
}

export function updateCountForCategoryGroup(response, enabledCategories, parentCategory, negativityLevels) {
    let category,
        count = 0;

    response &&
        Object.keys(response).forEach((key) => {
            count += response[key].totalSize;
            category = enabledCategories[key]?.category || {};
            if (response[key].status === 200) {
                category.count = response[key].totalSize;
                category.hasError = false;
            } else if (response[key].status === 400 || response[key].status === 500) {
                //throw invalid query
                category.count = 0;
                category.hasError = true;

                errorUtils.failedCategoriesCollector.add({
                    error_code: response[key].status,
                    categoryName: category.name,
                });
            }

            category.articles = [];
            category.countOnly = true;
            category.loaded = true;
            category.hasMore = category.count < category.articles.length;

            //Search results attributes that are available for ESG Ratings only
            category.overallRating = response[key]?.overallRating;
            category.documentId = response[key]?.lni;
            category.range = response[key]?.range;
            category.articles = response[key]?.entity
                ? [...category.articles, ...response[key].entity]
                : category.articles;

            if (category.postFilters && negativityLevels && negativityLevels.length) {
                category.postFilters.negativityLevels = negativityLevels;
            }

            reduxStore.dispatch(searchResultsActions.updateCategory(category));
        });

    SearchUtils.updateParentCategoryCount(parentCategory, count);
    reduxStore.dispatch(searchResultsActions.updateCategoryProperty(parentCategory.name, LOADED, true));

    return category;
}

function formatPostFilterData(arrayOfData, filterType = null, isSearchRerun = false) {
    let values = [];
    if (!arrayOfData) {
        return values;
    }
    arrayOfData.forEach((item) => {
        let count = item[1] !== undefined && item[1] !== null ? item[1] : 0;
        let checked = item[2] !== undefined ? item[2] : true;
        if (
            count !== 0 ||
            (count === 0 && isSearchRerun) ||
            (count === 0 && filterType === POST_FILTER_COMPANY_MENTIONS) ||
            filterType === POST_FILTER_PERSON_MENTIONS
        ) {
            values.push({ label: item[0], checked: checked, count: count });
        }
    });
    return values;
}

function getSortedConfigValues(configValues, values) {
    // We have in configValues the options that are selected
    if (configValues && configValues.length > 0) {
        let configKeys = configValues.filter((value) => value.checked).map((value) => value.label);
        let checkedValues = values.filter((value) => configKeys.indexOf(value.label) !== -1);
        let uncheckedValues = values.filter((value) => configKeys.indexOf(value.label) === -1);

        // When editing an alert or viewing a history item, we display first the checked options
        checkedValues = utils.sortByProperty(checkedValues, 'count').reverse();
        uncheckedValues = utils.sortByProperty(uncheckedValues, 'count').reverse();

        values = [...checkedValues, ...uncheckedValues];
    } else {
        values = utils.sortByProperty(values, 'count').reverse();
    }

    return values;
}

export function mergeConfigFilterWithSearchFilterValues(
    searchFilterValues,
    configValues,
    configType,
    searchFilterLabels = []
) {
    let values = [];

    if (configValues.length === 0) {
        // overwrite with transformed values
        values = transformSearchFilterValuesToArray(searchFilterValues);
    } else {
        configValues.forEach((item) => {
            let field = utils.isPostFilterCustomType(configType) ? item.labelId : item.label;

            if (searchFilterValues[field] >= 0) {
                item.count = searchFilterValues[field];
            } else {
                if (item.checked) {
                    item.count = 0;
                }
            }
            values.push({ ...item });
        });

        // Should merge the values of the postFilters with path (will also have values from search)
        if (
            SearchUtils.isPostFilterNested(camelCase(configType)) &&
            Object.keys(searchFilterValues).length > configValues.length
        ) {
            values = addNestedItemsToValues({ searchFilterValues, values, configType });
        }

        // Source post-filter already has sort options
        // Subject and industry is nested, we already have them properly sorted
        if (
            Object.keys(POST_FILTERS_DEFAULT_SORT).indexOf(configType) === -1 &&
            !utils.isPostFilterCustomType(configType)
        ) {
            // sort but keep the unchecked item's place
            values = sortValues(values);
        }
    }

    if (utils.isPostFilterCustomType(configType)) {
        values = buildSubjectIndustrySearchFilterValues(values, searchFilterLabels, configValues);
    }

    return values;
}

export function mergeSearchFilterValuesWithConfig(
    searchFilterValues,
    configValues,
    configType,
    searchFilterLabels = []
) {
    let values = [];
    let formattedSearchValues = cloneDeep(transformSearchFilterValuesToArray(searchFilterValues, configValues, true));

    if (configValues.length === 0) {
        //overwrite
        values = formattedSearchValues;
    } else {
        if (utils.isPostFilterCustomType(configType)) {
            formattedSearchValues = buildSubjectIndustrySearchFilterValues(
                formattedSearchValues,
                searchFilterLabels,
                configValues
            );
        }

        formattedSearchValues.forEach((item) => {
            if (SearchUtils.isPostFilterNested(camelCase(configType))) {
                item = buildItemForNestedFilters(item, configValues);
            } else {
                const config = configValues.find((value) => {
                    return value.label === item.label;
                });

                if (!isEmpty(config)) {
                    item.checked = config.checked;
                } else {
                    item.count = 0;
                    item.checked = false;
                }
            }

            values.push({ ...item });
        });
        // sort but keep the unchecked item's place
        values = sortValues(values);
    }

    if (utils.isPostFilterCustomType(configType)) {
        values = buildSubjectIndustrySearchFilterValues(values, searchFilterLabels, configValues);
    }

    return values;
}

export function buildItemForNestedFilters(item, configValues) {
    const filterValue = item.labelId || item.label;

    const itemInConfig = configValues.find((value) => {
        const configValue = value.labelId || value.label;
        const isSameElement = filterValue === configValue;
        const isParentElement = configValue.indexOf(filterValue) === 0;
        const isChildElement = filterValue.indexOf(configValue) === 0;

        return isSameElement || isParentElement || isChildElement;
    });

    if (!isEmpty(itemInConfig)) {
        const configValue = itemInConfig.labelId || itemInConfig.label;
        const isElementOrChild = filterValue.indexOf(configValue) === 0;

        item.checked = isElementOrChild ? itemInConfig.checked : null;
    } else {
        item.count = 0;
        item.checked = false;
    }

    return item;
}

function sortValues(values) {
    let sortedValues = [],
        i;
    for (i = values.length; i--;) {
        if (!values[i].checked) {
            sortedValues.unshift({ index: i, item: values[i] });
            values.splice(i, 1);
        }
    }

    values = utils.sortByProperty(values, 'count').reverse();

    for (i = 0; i < sortedValues.length; i++) {
        values.splice(sortedValues[i].index, 0, sortedValues[i].item);
    }

    return values;
}

export function buildSubjectIndustrySearchFilterValues(values, filterLabels, configValues = []) {
    if (values && values.length > 0) {
        return values.map((value) => {
            let field = value.labelId || value.label;
            let translatedLabel = field;

            let translateIdsBasedOnLabels = (field) =>
                field.split('/').map((id) => {
                    if (!filterLabels) {
                        return id;
                    }

                    if (!Array.isArray(filterLabels)) {
                        filterLabels = Object.values(filterLabels);

                        if (filterLabels.length === 0) {
                            return id;
                        }
                    }

                    let labelObject = filterLabels.find((label) => label.pguid === id);

                    return labelObject ? labelObject.displayName : id;
                });

            if (configValues && configValues.length > 0) {
                let configObject = configValues.find((label) => label.labelId === field);

                translatedLabel = configObject
                    ? configObject.label
                    : translateIdsBasedOnLabels(translatedLabel).join('/');
            } else {
                translatedLabel = translateIdsBasedOnLabels(translatedLabel).join('/');
            }

            return {
                ...value,
                labelId: field,
                label: translatedLabel,
            };
        });
    }

    return values;
}

export function addNestedItemsToValues({ searchFilterValues, values, configType }) {
    if (searchFilterValues && Object.keys(searchFilterValues).length) {
        Object.entries(searchFilterValues).forEach((item) => {
            const label = item[0];
            const field = utils.isPostFilterCustomType(configType) ? 'labelId' : 'label';
            const itemInValues = values && values.length ? values.find((value) => value[field] === label) : undefined;

            // Item is not already in the values array, we should create an object for it and push it
            if (itemInValues === undefined) {
                let isOptionChecked = true;

                // Item is a child
                if (label.indexOf('/') > -1 && values && values.length) {
                    let parentItem = values.find((value) => value[field] === label.split('/')[0]);

                    if (parentItem) {
                        isOptionChecked = parentItem.checked;
                    }
                }

                values.push({
                    [field]: label,
                    checked: isOptionChecked,
                    count: item[1],
                });
            }
        });
    }

    return values;
}

export function transformSearchFilterValuesToArray(searchFilterValues, configValues = [], isSearchRerun = false) {
    let values = Object.keys(searchFilterValues).map((key) => {
        return [key, searchFilterValues[key]];
    });

    values = cloneDeep(formatPostFilterData(values, null, isSearchRerun));
    values = getSortedConfigValues(configValues, values);

    return values;
}

function getSelectedValueFromArray(items) {
    let selected = '';
    items.forEach((item) => {
        if (item.checked) {
            selected = item.count;
        }
    });
    return selected;
}

export function getDocketStatusValues(selectedValues, configValues) {
    let defaultDocketStatusValues = [
        {
            label: DOCKET_STATUS_OPTION.OPEN,
            checked: true,
            originalLabel: DOCKET_STATUS_OPTION.OPEN,
        },
        {
            label: DOCKET_STATUS_OPTION.CLOSED,
            checked: true,
            originalLabel: DOCKET_STATUS_OPTION.CLOSED,
        },
    ];

    let docketStatusValues = [...defaultDocketStatusValues];

    if (configValues && configValues.length > 0) {
        return configValues;
    } else {
        if (Array.isArray(selectedValues)) {
            docketStatusValues.forEach((item) => {
                if (selectedValues.indexOf(item.label) === -1) {
                    item.checked = false;
                }
            });
        }
    }

    return docketStatusValues;
}

export function getProximityTerms(proximities, proximity, configValues) {
    let selectedValueFromPostFilterConfig;
    let values = [];

    if (Array.isArray(configValues)) {
        selectedValueFromPostFilterConfig = getSelectedValueFromArray(configValues);
    }

    let proximityValue = selectedValueFromPostFilterConfig || proximity;

    if (proximities) {
        for (let i = 0; i < proximities.length; i++) {
            let item = proximities[i];
            let label = utils.proximityToText(item);
            let checked = item === proximityValue;
            values.push([label, item, checked]);
            if (proximity === item) {
                //don't allow to add more proximities values than the default selected
                break;
            }
        }
    }

    values = cloneDeep(formatPostFilterData(values, POST_FILTER_PROXIMITY));

    return values;
}

export function getFuzzySearch(fuzzyOn, configValues) {
    let defaultFuzzySearchValues = [
        ['On (shows more results)', true, false],
        ['Off (shows fewer results)', false, false],
    ];

    if (configValues && configValues.length > 0) {
        return configValues;
    } else {
        // false can be a selectedValue
        if (fuzzyOn !== undefined && fuzzyOn !== null) {
            defaultFuzzySearchValues.forEach((item) => {
                if (item[1] === fuzzyOn) {
                    item[2] = true;
                }
            });
        }

        return cloneDeep(formatPostFilterData(defaultFuzzySearchValues, POST_FILTER_FUZZY_SEARCH));
    }
}

function getDefaultMentionsValues(selectedValue) {
    let defaultMentionsValues = [
        ['Any mentions', 0, false],
        ['At least 2 mentions', 2, false],
        ['At least 3 mentions', 3, false],
        ['At least 4 mentions', 4, false],
        ['At least 5 mentions', 5, false],
        ['At least 6 mentions', 6, false],
        ['At least 7 mentions', 7, false],
        ['At least 8 mentions', 8, false],
    ];

    // 0 can be a selectedValue
    if (selectedValue !== undefined && selectedValue !== null) {
        defaultMentionsValues.forEach((item) => {
            if (item[1] === selectedValue) {
                item[2] = true;
            }
        });
    }

    return defaultMentionsValues;
}

export function getCompanyMentioned(configValues, selectedValue) {
    if (configValues && configValues.length > 0) {
        return configValues;
    } else {
        const defaultCompanyMentionsValues = getDefaultMentionsValues(selectedValue);

        return cloneDeep(formatPostFilterData(defaultCompanyMentionsValues, POST_FILTER_COMPANY_MENTIONS));
    }
}

export function getPersonMentions(configValues, selectedValue) {
    if (configValues && configValues.length > 0) {
        return configValues;
    } else {
        const defaultPersonMentionsValues = getDefaultMentionsValues(selectedValue);

        return cloneDeep(formatPostFilterData(defaultPersonMentionsValues, POST_FILTER_PERSON_MENTIONS));
    }
}

export function getDateValues(date, categoryDateRange, postFilterValue) {
    let selected = false;
    let dateRange;
    if (date === categoryDateRange) {
        selected = true;
    } else if (
        date === 'custom' &&
        utils.getDateFromString(categoryDateRange?.split(';')[0], CALENDAR_DATE_FORMAT_BE)
    ) {
        selected =
            !!(postFilterValue && postFilterValue.count && categoryDateRange === postFilterValue.count) ||
            !postFilterValue;
        dateRange = categoryDateRange;
    }

    return {
        selected,
        dateRange,
    };
}

export function updateUserPreferencesContentTypesFromDropdown(props, state) {
    let newUserPreferencesContentTypes = cloneDeep(props.contentTypes);
    newUserPreferencesContentTypes.map((contentType) => {
        if (!isEmpty(props.searchBarContentTypes)) {
            props.searchBarContentTypes.map((source) => {
                if (source.key === contentType.name) {
                    contentType.value = source.checked;
                }
            });
        }
    });

    if (state.isUserPreferencesChecked && utils.calculateCheckedSources(newUserPreferencesContentTypes) !== 0) {
        props.updateUserPreferencesSources(newUserPreferencesContentTypes);

        return {
            contentTypes: newUserPreferencesContentTypes,
        };
    }
    return {};
}

export function getDateRange(categoryName, userPreferences, adHocPreferences, postFilterConfigValues) {
    let { newsSources, companySources, legalSources } = userPreferences.generalSettings;
    let { newsDateRange, companyDateRange, legalDateRange } = adHocPreferences;

    let newsDate = newsDateRange ? newsDateRange.range : newsSources ? newsSources.dateRange : null;
    let companyDate = companyDateRange ? companyDateRange.range : companySources ? companySources.dateRange : null;
    let legalDate = legalDateRange ? legalDateRange.range : legalSources ? legalSources.dateRange : null;
    let otherCategoriesDate = determineDateRange(adHocPreferences);

    let values = userPreferences.generalSettings.dateRanges
        ? userPreferences.generalSettings.dateRanges.map((date, index) => {
              let selected = false;
              let dateRange;
              switch (categoryName) {
                  case CATEGORY_NAMES.NEWS:
                  case CATEGORY_NAMES.NEGATIVE_NEWS:
                  case CATEGORY_NAMES.NEGATIVE_NEWS_EN:
                  case CATEGORY_NAMES.NEGATIVE_NEWS_FR:
                  case CATEGORY_NAMES.NEGATIVE_NEWS_NL:
                  case CATEGORY_NAMES.NEGATIVE_NEWS_DE:
                  case CATEGORY_NAMES.NEGATIVE_NEWS_ES:
                  case CATEGORY_NAMES.NEGATIVE_NEWS_TR:
                  case CATEGORY_NAMES.NEGATIVE_NEWS_SV:
                  case CATEGORY_NAMES.NEGATIVE_NEWS_MS:
                  case CATEGORY_NAMES.NEGATIVE_NEWS_PT:
                  case CATEGORY_NAMES.NEGATIVE_NEWS_JA:
                  case CATEGORY_NAMES.NEGATIVE_NEWS_ZH:
                  case CATEGORY_NAMES.NEGATIVE_NEWS_IT:
                  case CATEGORY_NAMES.NEGATIVE_NEWS_AR:
                  case CATEGORY_NAMES.NEGATIVE_NEWS_RU:
                  case CATEGORY_NAMES.NEGATIVE_NEWS_PL:
                  case CATEGORY_NAMES.CUSTOM_NEWS:
                  case CATEGORY_NAMES.CUSTOM_NEWS1:
                  case CATEGORY_NAMES.CUSTOM_NEWS2:
                  case CATEGORY_NAMES.CUSTOM_NEWS3:
                  case CATEGORY_NAMES.LN_CUSTOM_NEWS1:
                  case CATEGORY_NAMES.ADMIN_CUSTOM_NEWS1:
                  case CATEGORY_NAMES.ADMIN_CUSTOM_NEWS2:
                  case CATEGORY_NAMES.ADMIN_CUSTOM_NEWS3:
                      newsDate = utils.getDateRangeCount(categoryName, postFilterConfigValues, newsDate);
                      selected = getDateValues(date, newsDate, postFilterConfigValues[index]).selected;
                      dateRange = getDateValues(date, newsDate).dateRange;
                      break;
                  case CATEGORY_NAMES.COMPANY_RESOURCES:
                      companyDate = utils.getDateRangeCount(categoryName, postFilterConfigValues, companyDate);
                      selected = getDateValues(date, companyDate, postFilterConfigValues[index]).selected;
                      dateRange = getDateValues(date, companyDate).dateRange;
                      break;
                  case CATEGORY_NAMES.AGENCY_DECISION:
                  case CATEGORY_NAMES.STATE_DOCKETS:
                  case CATEGORY_NAMES.FEDERAL_DOCKETS:
                  case CATEGORY_NAMES.LAW_REVIEWS:
                  case CATEGORY_NAMES.CASES:
                  case CATEGORY_NAMES.VERDICTS:
                      legalDate = utils.getDateRangeCount(categoryName, postFilterConfigValues, legalDate);
                      selected = getDateValues(date, legalDate, postFilterConfigValues[index]).selected;
                      dateRange = getDateValues(date, legalDate).dateRange;
                      break;
                  case CATEGORY_NAMES.BIOGRAPHICAL:
                  case CATEGORY_NAMES.PEPS:
                  case CATEGORY_NAMES.SANCTIONS_WATCHLIST:
                  case CATEGORY_NAMES.FINANCIAL_REPORT:
                      otherCategoriesDate = utils.getDateRangeCount(categoryName, postFilterConfigValues, otherCategoriesDate);
                      selected = getDateValues(date, otherCategoriesDate, postFilterConfigValues[index]).selected;
                      dateRange = getDateValues(date, otherCategoriesDate).dateRange;
                      break;
              }
              // if the post-filter contains a custom value (ex a history/alerts search rerun) keep the value as an option

              if (date && date === RANGE_TYPE_CUSTOM && postFilterConfigValues.length > 0) {
                  let customDatePostFilter = postFilterConfigValues.find(
                      (filter) => filter.label === utils.dateRangeToText(RANGE_TYPE_CUSTOM)
                  );
                  dateRange = customDatePostFilter.count;
              }

              return [utils.dateRangeToText(date), dateRange || date, selected];
          })
        : null;

    values = cloneDeep(formatPostFilterData(values, POST_FILTER_DATE_RANGE));

    values.map((value) => {
        let postfilter = postFilterConfigValues.find((filter) => filter.count === value.count);
        value.show = postfilter && postfilter.show;
        return value;
    });

    return values;
}

export function getNegativityLevels(preferencesLevels, configValues) {
    if (configValues && configValues.length > 0) {
        return configValues;
    } else {
        if (!preferencesLevels || !preferencesLevels.length) {
            return [];
        }

        const values = NEGATIVITY_LEVELS_ORDER.map((level) => {
            const isChecked = preferencesLevels.indexOf(level) > -1;

            return [level, true, isChecked];
        });

        return cloneDeep(formatPostFilterData(values, POST_FILTER_NEGATIVITY_LEVELS));
    }
}

//TODO: update the logic when we will have elastic response
export function formatFuzzyNamesForPostFilters(fuzzyNames, configValues) {
    let values = [];
    let unitedFuzzyNames = union(
        configValues.map((item) => {
            return item.label;
        }),
        fuzzyNames
    );
    unitedFuzzyNames.forEach((item) => {
        let count = undefined;
        let checked = true;
        let index = findIndex(configValues, (configValue) => {
            return configValue.label === item;
        });
        if (index > -1) {
            //index found, get value from config
            checked = configValues[index].checked;
        }

        values.push({ label: item, checked: checked, count: count });
    });
    return values;
}

export function formatFuzzyNamesforSearch(fuzzyNames, query) {
    let list = [];
    if (Array.isArray(fuzzyNames)) {
        fuzzyNames.forEach((name) => list.push({ name, selected: true }));
        return {
            list,
            query,
        };
    } else {
        return fuzzyNames;
    }
}

export function updatePostFilterConfig(payload, filtersFromSearch, ignoreAllChecked) {
    let { category, fuzzyNames, suggestedNames, searchQueryType } = payload;
    let postFilterConfig = cloneDeep(reduxStore.getState().postFilterConfiguration[category]);
    let userPreferences = cloneDeep(reduxStore.getState().user.preferences);
    let adHocPreferences = cloneDeep(reduxStore.getState().adHocSearch);
    let editSearch = cloneDeep(reduxStore.getState().editSearch);

    if (!postFilterConfig) {
        console.error('postFilterConfig not found for ', category, postFilterConfig);
        return;
    }

    let data = {
        userPreferences,
        adHocPreferences,
        editSearch,
        postFilterConfig,
        filtersFromSearch,
        fuzzyNames,
        suggestedNames,
        searchQueryType,
        category,
    };

    if (ignoreAllChecked) {
        recreatePostFilterValues(data);
    } else {
        updatePostFilterValues(data);
    }
}

export function useHistoryDateRange(values, editSearch, category) {
    values.forEach((value) => {
        if (!isEmpty(editSearch) && editSearch[category]) {
            value.checked = value.count === editSearch[category].postFilters.dateRange;
        }
    });
}

// SEARCH
// for searches from history and alerts, the post-filters that were checked during a search triggered by a post-filter change
// are the only filters available
// that is why we are ignoringAllChecked
// when allChecked is not ignored,
// we are sending an empty array/null to the search api, which in return will send us all the documents available for all the post-filters
// and we do not want that in these two scenarios
// because we want to run the search only with the selected post-filters

// DISPLAY
// from the filter-info endpoint we get all the available post-filters for a category
// in order to display the checked post-filters and the unchecked post-filters we need to do the reverse logic of a normal search
// which means recreate the post-filter config based on what we already have from the alerts/history object which at this point are already in config marked as checked
// so we have all the possible values from search and the selected values in config
// based on these to information we insert in config the all the missing values from the filters api,mark them as deselected, and update the counts for the checked ones

export function recreatePostFilterValues(data) {
    let {
        userPreferences,
        adHocPreferences,
        postFilterConfig,
        filtersFromSearch,
        fuzzyNames,
        suggestedNames,
        editSearch,
        category,
        searchQueryType,
    } = data;

    Object.keys(postFilterConfig).forEach((key) => {
        let config = postFilterConfig[key];

        let values = [];
        let enabled = true;

        switch (config.type) {
            case POSTFILTER_TYPE.LANGUAGE:
                if (filtersFromSearch && filtersFromSearch[FILTER_INFO.LANGUAGE] && config.dynamic) {
                    values = mergeSearchFilterValuesWithConfig(
                        filtersFromSearch[FILTER_INFO.LANGUAGE],
                        config.values,
                        config.type
                    );
                }
                break;
            case POSTFILTER_TYPE.SOURCE:
            case POSTFILTER_TYPE.SOURCE_TYPE:
            case POSTFILTER_TYPE.SOURCE_NAME:
                if (filtersFromSearch && filtersFromSearch[config.searchFieldName] && config.dynamic) {
                    values = mergeSearchFilterValuesWithConfig(
                        filtersFromSearch[config.searchFieldName],
                        config.values,
                        config.type
                    );
                }
                break;
            case POSTFILTER_TYPE.PROXIMITY_NEGATIVE_TERMS: {
                let { proximities, proximity } = userPreferences.generalSettings;
                values = getProximityTerms(proximities, proximity, config.values);
                break;
            }
            case POSTFILTER_TYPE.COMPANY_MENTIONS:
                values = getCompanyMentioned(config.values, null);
                break;
            case POSTFILTER_TYPE.PERSON_MENTIONS:
                values = getPersonMentions(config.values, null);
                break;
            case POSTFILTER_TYPE.DATE_RANGE:
                values = getDateRange(category, userPreferences, adHocPreferences, config.values);
                useHistoryDateRange(values, editSearch, category);
                break;
            case POSTFILTER_TYPE.SIMILAR_NAMES:
                // if (searchQueryType === PERSON_SEARCH) {
                if (fuzzyNames) {
                    values = formatFuzzyNamesForPostFilters(fuzzyNames, config.values);
                }
                // }
                break;
            case POSTFILTER_TYPE.SUGGESTED_NAMES:
                if (utils.isPersonSearch(searchQueryType)) {
                    if (suggestedNames) {
                        values = formatFuzzyNamesForPostFilters(suggestedNames, config.values);
                    }
                }
                break;
            case POSTFILTER_TYPE.COMPANY:
                if (filtersFromSearch && filtersFromSearch[FILTER_INFO.COMPANY]) {
                    values = mergeSearchFilterValuesWithConfig(
                        filtersFromSearch[FILTER_INFO.COMPANY],
                        config.values,
                        config.type
                    );
                }
                break;
            case POSTFILTER_TYPE.GEOGRAPHY:
                if (filtersFromSearch && filtersFromSearch[FILTER_INFO.GEOGRAPHY]) {
                    values = mergeSearchFilterValuesWithConfig(
                        filtersFromSearch[FILTER_INFO.GEOGRAPHY],
                        config.values,
                        config.type
                    );
                }
                break;
            case POSTFILTER_TYPE.DOCKET_STATUS:
                values = getDocketStatusValues(null, config.values);
                break;

            case POSTFILTER_TYPE.SUBJECT:
                if (filtersFromSearch && filtersFromSearch[FILTER_INFO.SUBJECT]) {
                    values = mergeSearchFilterValuesWithConfig(
                        filtersFromSearch[FILTER_INFO.SUBJECT],
                        config.values,
                        config.type,
                        filtersFromSearch[POST_FILTER_LABELS.SUBJECT]
                    );
                }
                break;

            case POSTFILTER_TYPE.INDUSTRY:
                if (filtersFromSearch && filtersFromSearch[FILTER_INFO.INDUSTRY]) {
                    values = mergeSearchFilterValuesWithConfig(
                        filtersFromSearch[FILTER_INFO.INDUSTRY],
                        config.values,
                        config.type,
                        filtersFromSearch[POST_FILTER_LABELS.INDUSTRY]
                    );
                }
                break;

            case POSTFILTER_TYPE.FUZZY_SEARCH:
                let { fuzzyOn } = userPreferences.personCheck;
                values = getFuzzySearch(fuzzyOn, config.values);
                break;

            case POSTFILTER_TYPE.NEGATIVITY_LEVELS:
                let { negativityLevel } =
                    searchQueryType === PERSON_SEARCH ? userPreferences.personCheck : userPreferences.companyCheck;

                values = getNegativityLevels(negativityLevel, config.values);
                break;

            case POSTFILTER_TYPE.ESG_FACTORS:
                if (filtersFromSearch && filtersFromSearch[FILTER_INFO.ESG_FACTORS]) {
                    values = mergeSearchFilterValuesWithConfig(
                        filtersFromSearch[FILTER_INFO.ESG_FACTORS],
                        config.values,
                        config.type,
                        filtersFromSearch[POST_FILTER_LABELS.ESG_FACTORS]
                    );
                }
                break;

            default:
                values = config.values;
        }

        reduxStore.dispatch(
            postFilterConfigActions.updatePostFilterConfigValues(category, config.type, values, enabled)
        );
    });
}

export function updatePostFilterValues(data) {
    let {
        userPreferences,
        adHocPreferences,
        postFilterConfig,
        filtersFromSearch,
        fuzzyNames,
        suggestedNames,
        searchQueryType,
        category,
    } = data;
    Object.keys(postFilterConfig).forEach((key) => {
        let config = postFilterConfig[key];

        let values = [];
        let enabled = true;

        switch (config.type) {
            case POSTFILTER_TYPE.LANGUAGE:
                if (filtersFromSearch && filtersFromSearch[FILTER_INFO.LANGUAGE] && config.dynamic) {
                    values = mergeConfigFilterWithSearchFilterValues(
                        filtersFromSearch[FILTER_INFO.LANGUAGE],
                        config.values,
                        config.type
                    );
                }
                break;
            case POSTFILTER_TYPE.SOURCE:
            case POSTFILTER_TYPE.SOURCE_TYPE:
            case POSTFILTER_TYPE.SOURCE_NAME:
                if (filtersFromSearch && filtersFromSearch[config.searchFieldName] && config.dynamic) {
                    values = mergeConfigFilterWithSearchFilterValues(
                        filtersFromSearch[config.searchFieldName],
                        config.values,
                        config.type
                    );
                }
                break;
            case POSTFILTER_TYPE.PROXIMITY_NEGATIVE_TERMS:
                let { proximities, proximity } = userPreferences.generalSettings;
                values = getProximityTerms(proximities, proximity, config.values);
                break;
            case POSTFILTER_TYPE.COMPANY_MENTIONS:
                values = getCompanyMentioned(config.values, null);
                break;
            case POSTFILTER_TYPE.PERSON_MENTIONS:
                values = getPersonMentions(config.values, null);
                break;
            case POSTFILTER_TYPE.DATE_RANGE:
                values = getDateRange(category, userPreferences, adHocPreferences, config.values);
                break;
            case POSTFILTER_TYPE.SIMILAR_NAMES:
                // if (searchQueryType === PERSON_SEARCH) {
                if (fuzzyNames) {
                    values = formatFuzzyNamesForPostFilters(fuzzyNames, config.values);
                }
                // }
                break;
            case POSTFILTER_TYPE.SUGGESTED_NAMES:
                if (utils.isPersonSearch(searchQueryType)) {
                    if (suggestedNames) {
                        values = formatFuzzyNamesForPostFilters(suggestedNames, config.values);
                    }
                }
                break;
            case POSTFILTER_TYPE.COMPANY:
                if (filtersFromSearch && filtersFromSearch[FILTER_INFO.COMPANY]) {
                    values = mergeConfigFilterWithSearchFilterValues(
                        filtersFromSearch[FILTER_INFO.COMPANY],
                        config.values,
                        config.type
                    );
                }
                break;
            case POSTFILTER_TYPE.GEOGRAPHY:
                if (filtersFromSearch && filtersFromSearch[FILTER_INFO.GEOGRAPHY]) {
                    values = mergeConfigFilterWithSearchFilterValues(
                        filtersFromSearch[FILTER_INFO.GEOGRAPHY],
                        config.values,
                        config.type
                    );
                }
                break;
            case POSTFILTER_TYPE.DOCKET_STATUS:
                values = getDocketStatusValues(null, config.values);
                break;

            case POSTFILTER_TYPE.SUBJECT:
                if (filtersFromSearch && filtersFromSearch[FILTER_INFO.SUBJECT]) {
                    values = mergeConfigFilterWithSearchFilterValues(
                        filtersFromSearch[FILTER_INFO.SUBJECT],
                        config.values,
                        config.type,
                        filtersFromSearch[POST_FILTER_LABELS.SUBJECT]
                    );
                }
                break;

            case POSTFILTER_TYPE.INDUSTRY:
                if (filtersFromSearch && filtersFromSearch[FILTER_INFO.INDUSTRY]) {
                    values = mergeConfigFilterWithSearchFilterValues(
                        filtersFromSearch[FILTER_INFO.INDUSTRY],
                        config.values,
                        config.type,
                        filtersFromSearch[POST_FILTER_LABELS.INDUSTRY]
                    );
                }
                break;

            case POSTFILTER_TYPE.FUZZY_SEARCH:
                let { fuzzyOn } = userPreferences.personCheck;
                values = getFuzzySearch(fuzzyOn, config.values);
                break;

            case POSTFILTER_TYPE.NEGATIVITY_LEVELS:
                let { negativityLevel } =
                    searchQueryType === PERSON_SEARCH ? userPreferences.personCheck : userPreferences.companyCheck;

                values = getNegativityLevels(negativityLevel, config.values);
                break;

            case POSTFILTER_TYPE.ESG_FACTORS:
                if (filtersFromSearch && filtersFromSearch[FILTER_INFO.ESG_FACTORS]) {
                    values = mergeConfigFilterWithSearchFilterValues(
                        filtersFromSearch[FILTER_INFO.ESG_FACTORS],
                        config.values,
                        config.type,
                        filtersFromSearch[POST_FILTER_LABELS.ESG_FACTORS]
                    );
                }
                break;

            default:
                values = config.values;
        }

        reduxStore.dispatch(
            postFilterConfigActions.updatePostFilterConfigValues(category, config.type, values, enabled)
        );
    });
}

function getDuplicateDocuments(duplicateGroupIds, categoryName) {
    MainSearchApi.getDuplicateDocuments(duplicateGroupIds).then((response) => {
        reduxStore.dispatch(
            searchResultsActions.updateArticlesDuplicateSize(response.duplicateGroupsCount, categoryName)
        );
    });
}

export function setFailedCategory(category, e) {
    errorUtils.failedCategoriesCollector.add({
        error_code: e.status,
        categoryName: category.name,
    });

    category.hasMore = false;
    category.loaded = true;
    category.articles = [];

    if (e.message === PROMISE_CANCELED) {
        category.loaded = false;
    }
    // category.count = 0; //should we reset to zero? what if previous call had records
    category.errorMessage = e.message;
    category.statusCode = e.response && e.response.statusCode; //return status code only if it exists. If a promise is canceled, there is no response.
    category.hasError = true;
}

function matchResponseWithSubcategory(payload) {
    // here we try to match if this response is from an actual extended subcategory
    if (categoryUtils.hasChildren(payload.category)) {
        categoryUtils.getCategoryKeys(payload.category).forEach((subcategory) => {
            // if config matches, it means it is one of the subcategories instead
            if (categoryUtils.isMatchingConfig(subcategory, payload)) {
                payload.category = subcategory;
            }
        });
    }
}

export function getUboCategoryRequestPayload(category) {
    let { currentPageIndex, pageSize } = category;

    const costCode = costCodeUtils.getCostCode();
    const billingId = reduxStore.getState().investigation.billingId;
    let payload = {
        pageSize,
        postFilters: category.postFilters,
        pageNumber: currentPageIndex,
        billingSearchEvent: {
            billingId,
            costCode,
        },
    };

    if (isUboCompany(category)) {
        payload = {
            ...payload,
            dunsList: [category.duns],
        };
    } else {
        payload = {
            ...payload,
            searchTerm: category.term,
        };
    }
    return payload;
}

export function getCategoryRequestPayload(category, countOnly = false) {
    let { name, currentPageIndex, pageSize, contentSource } = category;

    let payload = utils.formatPostFilters(category.postFilters);
    payload.filterInfoParams = countOnly ? undefined : category.filterInfoParams;
    //TODO: rename currentPageIndex to pageNumber
    payload.pageNumber = currentPageIndex;
    payload.pageSize = countOnly ? 0 : pageSize;
    payload.category = name;
    payload.company = category.postFilters.company;
    payload.getSimilarDocs = false;

    payload = withContentSourceFilter(contentSource).extendConfig(name, payload);

    let categoryPostFilterConfig = reduxStore.getState().postFilterConfiguration[category.name];

    if (categoryPostFilterConfig) {
        const postFilterConfiguration = Object.values(categoryPostFilterConfig);
        Object.keys(category.postFilters).forEach((key) => {
            const postFilterConf = postFilterConfiguration.filter((filter) => filter.searchFieldName === key);
            // special handling of listed/nested/forced filter
            if (postFilterConf && postFilterConf.length && postFilterConf[0].listed) {
                payload[key] = SearchUtils.getPostfilterSelection(postFilterConf[0], false);
            }
        });
    }

    // remove publicationDate from aggregation
    if (payload.filterInfoParams) {
        payload.filterInfoParams = payload.filterInfoParams.filter((postFilter) => {
            return postFilter.name !== FILTER_INFO.PUBLICATION_DATE;
        });
    }

    // Custom news needs to be an array
    if (payload.customNews && typeof payload.customNews === 'string') {
        payload.customNews = payload.customNews.split();
    }

    return payload;
}

export function getEsgBillingEventPayload(esgDocument) {
    const costCode = costCodeUtils.getCostCode();
    const billingId = reduxStore.getState().investigation.billingId;
    const searchParams = reduxStore.getState().searchParams;
    return {
        billingId: billingId,
        category: CATEGORY_NAMES.ESG_RATINGS,
        costCode: costCode,
        includeTerms: [],
        id: esgDocument.id,
        query: searchParams.query,
        type: searchParams.searchType,
        terms: [],
        index: 0,
        version: 'v2',
    };
}

// In order to display the edit alert and edit history item post-filters (both checked and unchecked options),
// using the response of two filterInfo calls, we combine the default post-filter values
// with the correct count of the filterInfo call
export function mergeCountOfPostFilterValues(allPostFilterValues = {}, postFilters = {}) {
    let postFilterTypes = Object.keys(allPostFilterValues),
        mergedPostFilters = { ...allPostFilterValues };

    if (postFilterTypes.length > 0) {
        postFilterTypes.forEach((postFilterType) => {
            let filterValues = Object.keys(allPostFilterValues[postFilterType]);

            if (filterValues.length > 0) {
                mergedPostFilters[postFilterType] = {
                    ...allPostFilterValues[postFilterType],
                };

                if (
                    postFilterType !== POST_FILTER_LABELS.SUBJECT &&
                    postFilterType !== POST_FILTER_LABELS.INDUSTRY &&
                    postFilterType !== POST_FILTER_LABELS.ESG_FACTORS
                ) {
                    filterValues.forEach((value) => {
                        if (
                            postFilters &&
                            postFilters[postFilterType] &&
                            postFilters[postFilterType][value] !== undefined
                        ) {
                            mergedPostFilters[postFilterType][value] = postFilters[postFilterType][value];
                        } else {
                            mergedPostFilters[postFilterType][value] = 0;
                        }
                    });
                }
            }
        });
    }

    return mergedPostFilters;
}

/**
If parentCategoriesPostFilters is present and not empty, return it as is - otherwise builds the postFilters for the parent category 
@param {Object} parentCategory - The parent category object containing the post filters to be spread.
@param {Object} payload - The payload object containing the enabled categories and news queries.
@param {Array} v4PostFilters - parentCategoriesPostFilters (May not always be present).
@returns {Array} - The resulting postFilters array.
*/
export function buildParentCategoryPostFilter(parentCategory, payload, v4PostFilters) {
    if (v4PostFilters?.length) return v4PostFilters;

    const { categories, newsQueries } = payload;
    return categories.map((category) => ({
        ...parentCategory.postFilters,
        category,
        newsQueries,
    }));
}

export function buildFilterInfoPayload({ category, countOnly, ignoreAllChecked, searchFieldName }) {
    let { name, currentPageIndex, pageSize, contentSource } = category;
    let payload = utils.formatPostFilters(category.postFilters);
    const state = reduxStore.getState();
    const dateRange =
        state.adHocSearch && Object.keys(state.adHocSearch).length === 0 && state.adHocSearch.constructor === Object
            ? category.postFilters.dateRange
            : determineDateRange(state.adHocSearch);
    let interfaceLanguage = state.user.preferences.language;

    payload.filterInfoParams = countOnly ? undefined : category.filterInfoParams;

    if (payload.filterInfoParams) {
        // When expanding the tree items, the filterInfo request should contain path "all"
        if (searchFieldName && SearchUtils.isPostFilterNested(searchFieldName)) {
            let searchFieldObject = payload.filterInfoParams.find((field) => field.name === searchFieldName);

            if (searchFieldObject || ignoreAllChecked) {
                searchFieldObject.path = NESTED_POST_FILTER_PATH.FULL;
            }
        }

        // When editing a history item, if payload has nested items, the filterInfo request should contain path "all"
        if (ignoreAllChecked) {
            payload.filterInfoParams.forEach((filter) => {
                let filterName = filter.name;
                if (SearchUtils.isPostFilterNested(filterName)) {
                    if (payload[filterName] && payload[filterName].length) {
                        let hasNestedItems = payload[filterName].some((item) => item.indexOf('/') > -1);

                        if (hasNestedItems) {
                            filter.path = NESTED_POST_FILTER_PATH.FULL;
                            reduxStore.dispatch(postFilterConfigActions.markFullTreeLoaded(category.name, filterName));
                        }
                    }
                }
            });
        }
    }

    //TODO: rename currentPageIndex to pageNumber
    payload.pageNumber = currentPageIndex;
    payload.pageSize = countOnly ? 0 : pageSize;
    payload.category = name;
    payload.company = category.postFilters.company;
    payload.getSimilarDocs = false;
    payload.isCustomFuzzy = category.isCustomFuzzy;
    payload.dateRange = category.postFilters.dateRange ?? dateRange;

    let newsQueriesFromRerunSearch = [];

    if (
        ignoreAllChecked &&
        payload.newsQueries &&
        payload.newsQueries.length &&
        payload.newsQueries[0].negativeNewsQueries
    ) {
        newsQueriesFromRerunSearch = payload.newsQueries;
    }

    payload = withContentSourceFilter(contentSource).extendConfig(name, payload);

    if (categoryUtils.isChildOf(CATEGORY_NAMES.CUSTOM_NEWS, name) && !Array.isArray(payload.customNews)) {
        payload.customNews = [name];
    }

    let categoryPostFilterConfig = state.postFilterConfiguration[category.name];

    if (categoryPostFilterConfig) {
        const postFilterConfiguration = Object.values(categoryPostFilterConfig);
        Object.keys(category.postFilters).forEach((key) => {
            const postFilterConf = postFilterConfiguration.filter((filter) => filter.searchFieldName === key);
            // special handling of listed/nested/forced filter
            if (postFilterConf && postFilterConf.length && postFilterConf[0].listed) {
                if (
                    !SearchUtils.isPostFilterNested(camelCase(postFilterConf[0].type)) ||
                    (SearchUtils.isPostFilterNested(camelCase(postFilterConf[0].type)) && !ignoreAllChecked)
                ) {
                    payload[key] = SearchUtils.getPostfilterSelection(postFilterConf[0], ignoreAllChecked);
                }
            }
        });
    }

    payload.interfaceLanguage = interfaceLanguage;

    // remove publicationDate and fuzzyNames from aggregation
    if (payload.filterInfoParams) {
        payload.filterInfoParams = payload.filterInfoParams.filter((postFilter) => {
            return (
                postFilter.name !== FILTER_INFO.PUBLICATION_DATE &&
                postFilter.name !== FILTER_INFO.FUZZY_NAMES &&
                postFilter.name !== FILTER_INFO.FUZZY_SEARCH &&
                postFilter.name !== FILTER_INFO.SUGGESTED_NAMES &&
                postFilter.name !== FILTER_INFO.PERSON_MENTIONS &&
                postFilter.name !== FILTER_INFO.COMPANY_MENTIONS &&
                postFilter.name !== FILTER_INFO.PROXIMITY &&
                postFilter.name !== FILTER_INFO.DOCKET_STATUS &&
                postFilter.name !== FILTER_INFO.NEGATIVITY_LEVELS
            );
        });
    }

    // remove searchNamePartyEntity for Agency Decision (ignored on BE side as well)
    if (payload.category === CATEGORY_NAMES.AGENCY_DECISION) {
        delete payload.searchNamePartyEntity;
    }

    // Only need the contentType for sanctions and peps categories, we receive the publicationName from BE
    if (category.name === CATEGORY_NAMES.SANCTIONS_WATCHLIST || category.name === CATEGORY_NAMES.PEPS) {
        if (Array.isArray(payload.contentType)) {
            if (payload.publicationName !== undefined) {
                delete payload.publicationName;
            }
        }
    }

    if (newsQueriesFromRerunSearch && newsQueriesFromRerunSearch.length) {
        payload.newsQueries = newsQueriesFromRerunSearch;
    }

    return payload;
}

export function addNegativeNewsBucketsToSearchCategory(responses, category) {
    if (responses && responses[0]) {
        const payload = responses[0];
        category.buckets = {};
        category.topNegativeTerms = payload.topNegativeTerms;

        Object.values(NEGATIVITY_BUCKET_NAMES).forEach((bucketName) => {
            if (payload[bucketName] && payload[bucketName].length) {
                category.buckets[bucketName] = payload[bucketName];
            }
        });
    }

    return category;
}

export function updateNewsQueriesBasedOnNegativityLevels(payload, newsQueriesFromRerunSearch = []) {
    const { negativityLevels, newsQueries } = payload;

    if (negativityLevels && negativityLevels.length) {
        if (newsQueries && newsQueries.length) {
            newsQueries.forEach((query) => {
                if (query && query.negativeNewsQueries) {
                    Object.keys(query.negativeNewsQueries).forEach((negativeNewsQuery) => {
                        if (negativityLevels.indexOf(NEGATIVITY_STRINGS_TO_LEVEL[negativeNewsQuery]) === -1) {
                            delete query.negativeNewsQueries[negativeNewsQuery];
                        }

                        payload.negativityLevels = negativityLevels;
                    });
                }
            });
        }
    }

    if (newsQueriesFromRerunSearch && newsQueriesFromRerunSearch.length) {
        payload.newsQueries = newsQueriesFromRerunSearch;
    }

    return payload;
}

export const filterNegativeQueriesByAvailableLevels = (negativeNewsQueries, negativityLevels) => {
    Object.keys(negativeNewsQueries).forEach((negativeNewsQuery) => {
        if (negativityLevels.indexOf(NEGATIVITY_STRINGS_TO_LEVEL[negativeNewsQuery]) === -1) {
            delete negativeNewsQueries[negativeNewsQuery];
        }
    });

    return negativeNewsQueries;
};

function populateElasticCategory(category, ts, countOnly, ignoreAllChecked, redirectToResultList) {
    let payload = buildFilterInfoPayload({ category, countOnly, ignoreAllChecked });
    const isEsgRatingsCategory = category.name === CATEGORY_NAMES.ESG_RATINGS;
    const isSanctionsCategory = category.name === CATEGORY_NAMES.SANCTIONS_WATCHLIST;

    if (isUboCategory(category)) {
        return populateUboCategory(category, countOnly, category.pageSize, payload.pageNumber).then(() => {
            return {
                category,
                payload,
            };
        });
    }

    if (isEsgRatingsCategory) {
        payload.prefilterQuery = '';
    }

    const state = reduxStore.getState();
    if (!payload.searchQuery || !payload.searchQueryType) {
        const { query, searchType } = state.searchParams;
        payload.searchQuery = query;
        payload.searchQueryType = searchType;
    }

    const { shardPreference, lastCategoryGenerated } = state.searchParams.shardPreference;
    let payloadShardPreference = shardPreference;

    addSearchEngineOptionsToPayload(
        payload,
        lastCategoryGenerated,
        payloadShardPreference,
        category.name,
        state.user.customerId
    );

    let getSanctionsDocumentsTask = (isSanctionsCategory && countOnly !== true) ? getSanctionsTableData(category.postFilters) : undefined;

    let getElasticSanctionsSearchResultsTask = MainSearchApi.getElasticSanctionsSearchResults(payload, ts).catch(
        (e) => {
            setFailedCategory(category, e);
            updatePostFilterConfig(payload);
            console.error('error getting results list from elastic search for ' + category.name);
        }
    );

    let updatePostFilterConfigTask =
        !countOnly && !isEsgRatingsCategory
            ? MainSearchApi.getFilterInfo(payload, ts).catch(() => {
                updatePostFilterConfig(payload);
                console.error('error updating postFilters for ' + category.name);

                utils.showNotificationsMessage({
                    messageText: 'General.postFilters.updateError',
                    messageType: 'system-error',
                });   
            })
            : null;

    // Task to request the counts for the filters without taking into account the selected post-filters
    // Using this for populating the alert edit and the history items
    let updateDefaultPostFilterConfigTask = null;
    let defaultPayload = { ...payload };

    if (!countOnly && ignoreAllChecked) {
        let defaultPostFilters = SearchUtils.getPostFiltersDefaultValues(payload);

        defaultPayload = {
            ...defaultPostFilters,
        };

        updateDefaultPostFilterConfigTask = MainSearchApi.getFilterInfo(defaultPayload, ts).catch(() => {
            updatePostFilterConfig(defaultPayload);
            console.error('error updating default postFilters ' + category.name);
        });
    }

    let chainedPromises = [
        getElasticSanctionsSearchResultsTask,
        updatePostFilterConfigTask,
        updateDefaultPostFilterConfigTask,
        getSanctionsDocumentsTask
    ].filter((definedPromise) => definedPromise); // filter only for valid promises

    return Promise.all(chainedPromises).then((responses) => {
        responses = responses.filter((definedResponse) => definedResponse);
        if (responses && !responses.length) {
            return { category, payload };
        }
        const { lastPreferencesUpdateInfo } = responses[0];

        //where responses[index] is the resolved response from chainedPromises[index];
        category.count = responses[0].totalSize;
        category.loaded = true;
        category.articles = responses[0].entity;
        category.hasError = false;
        category.hasMore = category.articles && category.count < category.articles.length;
        category.countOnly = countOnly;

        if (categoryUtils.isChildOf(CATEGORY_NAMES.NEGATIVE_NEWS, category.name)) {
            category = addNegativeNewsBucketsToSearchCategory(responses, category);
        }

        if (categoryUtils.isCategoryWithDeduplication(category.name)) {
            let duplicateGroupIds = [];
            category.articles.forEach((article) => {
                if (article.duplicateGroupId) {
                    duplicateGroupIds.push(article.duplicateGroupId);
                }
            });

            if (duplicateGroupIds.length > 0 && !countOnly) {
                getDuplicateDocuments(duplicateGroupIds, category.name);
            }
        }

        matchResponseWithSubcategory(payload);

        if (!countOnly) {
            if (ignoreAllChecked && responses[2]) {
                // responses[1] contains the response when taking into account the selected post-filters
                // responses[2] contains the response when having the default post-filters set (to retrieve all filter values)
                // Merging the responses to have all post-filter values
                // (from responses[2]) with the correct counts (from responses[1])
                let mergedPostFilters = mergeCountOfPostFilterValues(responses[2], responses[1]);
                matchResponseWithSubcategory(defaultPayload);
                updatePostFilterConfig(defaultPayload, mergedPostFilters, ignoreAllChecked);
            } else {
                updatePostFilterConfig(payload, responses[1], ignoreAllChecked);
            }
        }
        // If the user has access to new S&W snapshot pod and the history entry is for the Sanctions category, make the call to get the necessary data
        const showSnapshot = state.user.preferences.generalSettings.showSnapshot;
        countOnly && category.name === 'sanctions' && showSnapshot && (redirectToResultList !== true) && getSanctionsTableData(category.postFilters)

        return {
            category,
            payload,
            lastPreferencesUpdateInfo,
        };
    });
}

export function addSearchEngineOptionsToPayload(
    payload,
    lastCategoryGenerated,
    payloadShardPreference,
    currentCategory,
    customerId
) {
    // we should generate a new shardPreference when a different category is accessed at first page
    if (payload.pageNumber === 0 && lastCategoryGenerated !== currentCategory) {
        payloadShardPreference = SearchUtils.generateShardPreference(customerId);
        reduxStore.dispatch(
            searchParamsActions.updateShardPreference({
                shardPreference: payloadShardPreference,
                lastCategoryGenerated: customerId,
            })
        );
    }

    payload.searchEngineOptions = {
        shardPreference: payloadShardPreference,
    };
}

function populateUboCategory(category, countOnly, pageSize, pageNumber) {
    const costCode = costCodeUtils.getCostCode();
    const billingId = reduxStore.getState().investigation.billingId;
    let payload = {
        pageSize: pageSize,
        pageNumber: pageNumber,
        postFilters: category.postFilters,
        billingSearchEvent: {
            billingId: billingId,
            costCode: costCode,
        },
    };

    if (isUboCompany(category)) {
        payload = {
            ...payload,
            dunsList: [category.duns],
        };
    } else {
        payload = {
            ...payload,
            searchTerm: category.term,
        };
    }

    return uboApi
        .fetchUboArticles(payload)
        .then((response) => {
            // temporary solution to cover the case where the DUNS number entered is not valid
            // on the final implementation of the Start Page, we won't be needing to make this check
            // validation to prevent the app entering an infinite loop with the 'resultsList' call
            // TODO: perhaps remove validation depending on the final start page
            category.loaded = true;
            category.articles = response && response.resultsList.map((item) => mapOrganizationToCategory(item));
            category.hasError = false;
            category.count = response.count;
            category.hasMore = category.articles && category.count < category.articles.length;
            category.countOnly = countOnly;

            //matchResponseWithSubcategory(payload);
            return {
                category,
                payload,
            };
        })
        .catch((e) => {
            console.error(e);
            setFailedCategory(category, e);
            updatePostFilterConfig(payload);
            return { category, payload };
        });
}

function loadCurrentReport(query: string, searchType: string) {
    const state = reduxStore.getState();

    if (state.user.useNewResearchSummaryFeature) {
        action(investigationEvents.EVENT_TYPES.startInvestigation);
    } else {
        const praOn = state.user.appSettings.prsOn;
        const isSnapshotVisible = state.user.preferences.generalSettings.showSnapshot;

        resetReport();

        // Mark default category as visited when snapshot is disabled

        if (isSnapshotVisible === false) {
            SearchUtils.updateDefaultCategoryVisitedFlag(searchType);
        }

        let contentTypes = state.user.preferences.generalSettings.contentTypes;
        let prContentType = contentTypes.find((contentType) => contentType.name === CATEGORY_NAMES.PUBLIC_RECORDS);
        let prReportOrder = 0;
        if (prContentType) {
            prReportOrder = prContentType.reportOrder;
        }
        return ReportBuilderApi.getReportBySearchQuery(query, searchType, praOn, prReportOrder)
            .then((report) => {
                let { query, searchType } = reduxStore.getState().searchParams;

                if (isInvestigationFromCurrentSearch(report, query, searchType)) {
                    let currentReport = {};

                    currentReport.reportId = report.id;
                    currentReport.articlesSnippets = report.reportSnippets || [];
                    currentReport.researchSummary = report.researchSummary || [];
                    state.investigation.visitedContentTypes = state.investigation.visitedContentTypes || {};
                    reduxStore.dispatch(currentReportActions.setCurrentReport(currentReport));
                    return currentReport;
                }
            })
            .catch(() => {
                let resetedReport = resetReport();
                return resetedReport;
            });
    }
}

export function resetReport() {
    const resetedReport = {
        reportId: null,
        articlesSnippets: [],
    };
    reduxStore.dispatch(currentReportActions.setCurrentReport(resetedReport));
    reduxStore.dispatch(investigationActions.resetInvestigation());

    return resetedReport;
}

function createDefaultSearchDatesObject() {
    let dates = [];
    let generalSettings = reduxStore.getState().user.preferences.generalSettings;
    let adHocSettings = reduxStore.getState().adHocSearch;
    if (generalSettings.newsSources && generalSettings.companySources && generalSettings.legalSources) {
        let isAdHocEmpty =
            (Object.keys(adHocSettings).length === 0 || Object.keys(adHocSettings).length === 1) &&
            adHocSettings.constructor === Object;
        let newsSourcesDate =
            isAdHocEmpty || !adHocSettings.newsDateRange || !adHocSettings.newsDateRange.range
                ? generalSettings.newsSources.dateRange
                : adHocSettings.newsDateRange.range;
        let companySourcesDate =
            isAdHocEmpty || !adHocSettings.companyDateRange || !adHocSettings.companyDateRange.range
                ? generalSettings.companySources.dateRange
                : adHocSettings.companyDateRange.range;
        let legalSourcesDate =
            isAdHocEmpty || !adHocSettings.legalDateRange || !adHocSettings.legalDateRange.range
                ? generalSettings.legalSources.dateRange
                : adHocSettings.legalDateRange.range;

        SOURCES.newsSources.forEach((source) => {
            dates[source] = newsSourcesDate;
        });

        SOURCES.companySources.forEach((source) => {
            dates[source] = companySourcesDate;
        });

        SOURCES.legalSources.forEach((source) => {
            dates[source] = legalSourcesDate;
        });
    }

    return dates;
}

export function getFuzzyNames(searchQuery, searchQueryType, fuzzyNames, suggestedNames) {
    let isCustomFuzzy = SearchUtils.isCustomFuzzy(searchQuery);
    if (isCustomFuzzy) {
        return SearchUtils.getCustomFuzzy(searchQuery, suggestedNames);
    } else {
        reduxStore.dispatch(searchParamsActions.updateCustomFuzzyFlag(false));

        return new Promise((resolve) => {
            return resolve({ fuzzyNames, suggestedNames });
        });
    }
}

export function mergePostFilter(postFilterType, postFilter, defaultPostFilter, categoryName, searchQuery) {
    const adHocPreferences = reduxStore.getState().adHocSearch;
    switch (postFilterType) {
        case POST_FILTER_DATE_RANGE:
            if (postFilter) {
                return postFilter;
            } else if (defaultPostFilter && defaultPostFilter[categoryName]) {
                return defaultPostFilter[categoryName]
            } else if (
                categoryName === CATEGORY_NAMES.BIOGRAPHICAL ||
                categoryName === CATEGORY_NAMES.PEPS ||
                categoryName === CATEGORY_NAMES.SANCTIONS_WATCHLIST ||
                categoryName === CATEGORY_NAMES.FINANCIAL_REPORT
            ) {
                // no defaults for these categories yet (no date range settings in user preferences)
                return determineDateRange(adHocPreferences);
            }

            return undefined;
        case FUZZY_NAMES:
            return postFilter
                ? formatFuzzyNamesforSearch(postFilter, searchQuery)
                : formatFuzzyNamesforSearch(defaultPostFilter, searchQuery);
        case SUGGESTED_NAMES:
            return postFilter
                ? formatFuzzyNamesforSearch(postFilter, searchQuery)
                : formatFuzzyNamesforSearch(defaultPostFilter, searchQuery);
        default:
            if (utils.isArray(postFilter) || utils.isArray(defaultPostFilter)) {
                return postFilter && postFilter.length > 0 ? postFilter : defaultPostFilter;
            } else if (utils.isString(postFilter) || utils.isString(defaultPostFilter)) {
                return postFilter || defaultPostFilter;
            } else {
                return postFilter || postFilter === 0 ? postFilter : defaultPostFilter;
            }
    }
}

export function mergePostFilters(postFilters, defaultPostFilters, categoryName, listOfPostFilters, searchQuery) {
    let mergedPostFilters = {};
    listOfPostFilters.forEach((key) => {
        if (postFilters) {
            let postFilter = postFilters[key];
            let defaultPostFilter = defaultPostFilters[key];
            mergedPostFilters[key] = mergePostFilter(key, postFilter, defaultPostFilter, categoryName, searchQuery);
        } else {
            if (key === POST_FILTER_DATE_RANGE) {
                mergedPostFilters[key] = defaultPostFilters[key] ? defaultPostFilters[key][categoryName] : null;
            } else {
                mergedPostFilters[key] = defaultPostFilters[key];
            }
        }
    });
    return mergedPostFilters;
}

export function getDefaultSearchSettings({ categoriesResults, defaultPostFilters, postFilters = {}, searchQuery, store }) {
    return getFuzzyNames(
        defaultPostFilters.searchQuery,
        defaultPostFilters.searchQueryType,
        defaultPostFilters[FUZZY_NAMES],
        defaultPostFilters[SUGGESTED_NAMES]
    ).then((fuzzyNames, suggestedNames) => {
        let mergedPostFilters = {};
        Object.keys(categoriesResults).forEach((categoryName) => {
            const categoryPostFilters = postFilters[categoryName] || postFilters || {};
            mergedPostFilters[categoryName] = mergePostFilters(
                categoryPostFilters,
                defaultPostFilters,
                categoryName,
                POST_FILTER_TYPES,
                searchQuery
            );

            if (mergedPostFilters[categoryName].fuzzyNames.list.length === 0) {
                mergedPostFilters[categoryName].fuzzyNames = fuzzyNames ? fuzzyNames : null;
            }

            if (mergedPostFilters[categoryName].suggestedNames.list.length === 0) {
                mergedPostFilters[categoryName].suggestedNames = suggestedNames ? suggestedNames : null;
            }

            mergedPostFilters[categoryName][POST_FILTER_SEARCH_QUERY] = defaultPostFilters.searchQuery;
            mergedPostFilters[categoryName][POST_FILTER_SEARCH_QUERY_TYPE] = defaultPostFilters.searchQueryType;

            // If value is set on the postfilters, use that value,
            // if the default value is set, use the default value,
            // else set it to false
            if (Object.keys(LEGAL_SOURCES_TITLES).indexOf(categoryName) !== -1) {
                mergedPostFilters[categoryName][POST_FILTER_SEARCH_NAME_PARTY_ENTITY] =
                    categoryPostFilters[POST_FILTER_SEARCH_NAME_PARTY_ENTITY] !== undefined
                        ? categoryPostFilters[POST_FILTER_SEARCH_NAME_PARTY_ENTITY]
                        : defaultPostFilters[POST_FILTER_SEARCH_NAME_PARTY_ENTITY] !== undefined
                            ? defaultPostFilters[POST_FILTER_SEARCH_NAME_PARTY_ENTITY]
                            : false;
            }

            if (
                defaultPostFilters.searchSpecifically &&
                [CATEGORY_NAMES.FINANCIAL_REPORT, CATEGORY_NAMES.COMPANY_RESOURCES].indexOf(categoryName) >= 0
            ) {
                mergedPostFilters[categoryName][POST_FILTER_SEARCH_SPECIFICALLY] =
                    defaultPostFilters.searchSpecifically;
            }

            if (defaultPostFilters[POST_FILTER_FUZZY_THRESHOLD]) {
                mergedPostFilters[categoryName][POST_FILTER_FUZZY_THRESHOLD] =
                    defaultPostFilters[POST_FILTER_FUZZY_THRESHOLD];
                mergedPostFilters[categoryName][POST_FILTER_FUZZY_SEARCH] =
                    defaultPostFilters[POST_FILTER_FUZZY_SEARCH] || false;
            } else if (
                postFilters &&
                postFilters[categoryName] &&
                postFilters[categoryName][POST_FILTER_FUZZY_THRESHOLD]
            ) {
                mergedPostFilters[categoryName][POST_FILTER_FUZZY_THRESHOLD] =
                    postFilters[categoryName][POST_FILTER_FUZZY_THRESHOLD];
                mergedPostFilters[categoryName][POST_FILTER_FUZZY_SEARCH] = true;
            }

            // Add exclude news toggles for news categories
            if (store.user.newsExcludeTogglesEnabled && categoryUtils.isNewsSource(categoryName)) {
                mergedPostFilters[categoryName][POST_FILTER_EXCLUDE_NON_BUSINESS_NEWS] =
                    categoryPostFilters[POST_FILTER_EXCLUDE_NON_BUSINESS_NEWS] !== undefined
                        ? categoryPostFilters[POST_FILTER_EXCLUDE_NON_BUSINESS_NEWS]
                        : defaultPostFilters[POST_FILTER_EXCLUDE_NON_BUSINESS_NEWS] !== undefined
                            ? defaultPostFilters[POST_FILTER_EXCLUDE_NON_BUSINESS_NEWS]
                            : DEFAULT_EXCLUDE_NEWS_TOGGLE_VALUES[POST_FILTER_EXCLUDE_NON_BUSINESS_NEWS];

                mergedPostFilters[categoryName][POST_FILTER_EXCLUDE_NEWS_WIRES] =
                    categoryPostFilters[POST_FILTER_EXCLUDE_NEWS_WIRES] !== undefined
                        ? categoryPostFilters[POST_FILTER_EXCLUDE_NEWS_WIRES]
                        : defaultPostFilters[POST_FILTER_EXCLUDE_NEWS_WIRES] !== undefined
                            ? defaultPostFilters[POST_FILTER_EXCLUDE_NEWS_WIRES]
                            : DEFAULT_EXCLUDE_NEWS_TOGGLE_VALUES[POST_FILTER_EXCLUDE_NEWS_WIRES];
            }

            // Set the contentLanguage using the postFilter value, if any (from alert)
            if (
                categoryUtils.isChildOf(CATEGORY_NAMES.CUSTOM_NEWS, categoryName) ||
                categoryName === CATEGORY_NAMES.NEWS
            ) {
                if (postFilters[categoryName] && postFilters[categoryName].contentLanguage) {
                    mergedPostFilters[categoryName].contentLanguage = postFilters[categoryName].contentLanguage;
                }
            }

            // Set the newsQueries using the postFilter value, if any (from alert)
            if (categoryUtils.isChildOf(CATEGORY_NAMES.NEGATIVE_NEWS, categoryName)) {
                if (postFilters[categoryName] && postFilters[categoryName].newsQueries) {
                    mergedPostFilters[categoryName].newsQueries = postFilters[categoryName].newsQueries;
                }
            }
        });

        return mergedPostFilters;
    });
}

function getFilterInfoParams(postFilterConfig) {
    let filterInfoParams = [];
    if (!postFilterConfig) {
        return filterInfoParams;
    }
    Object.keys(postFilterConfig).forEach((key) => {
        if (postFilterConfig[key].searchFieldName) {
            // TODO: DON'T FORGET ABOUT THIS -- for geography, subject, industry we need one extra field
            if (SearchUtils.isPostFilterNested(postFilterConfig[key].searchFieldName)) {
                filterInfoParams.push({
                    name: postFilterConfig[key].searchFieldName,
                    path: NESTED_POST_FILTER_PATH.INITIAL,
                });
            } else {
                filterInfoParams.push({ name: postFilterConfig[key].searchFieldName });
            }
            // TODO: DON'T FORGET ABOUT THIS
        }
    });

    return filterInfoParams;
}

function getSearchInfoFromState() {
    const state = reduxStore.getState();
    const searchType = state.searchParams.searchType;
    const postFilterConfig = cloneDeep(state.postFilterConfiguration);
    const pageSize = state.user.preferences.generalSettings.pageSize || 50;
    const lawSources = state.searchResults.lawSources;
    const negativeNews = state.searchResults.negativeNews;
    const customNews = state.searchResults.customNews;
    const historyCategoryName = state.historyCategory.categoryName;
    const editSearch = state.editSearch;
    const isCustomFuzzy = state.searchParams.isCustomFuzzy;

    return {
        searchType,
        postFilterConfig,
        pageSize,
        lawSources,
        negativeNews,
        customNews,
        historyCategoryName,
        isCustomFuzzy,
        editSearch,
    };
}

function isNewFuzzyEnabled(store) {
    return (
        store.user.newFuzzyEnabled &&
        !store.user.preferences.userLocks.fuzzyThresholdPerson &&
        !store.user.preferences.userLocks.masterLockNewsSearchesPerson
    );
}

function isSuggestedNamesEnabled(store) {
    return store.user.suggestedNamesEnabled && store.user.preferences.personCheck.suggestedNames;
}

const SearchUtils = {
    updateParentCategoryCount(category, count) {
        category.count = count;
        category.loaded = true;
        reduxStore.dispatch(searchResultsActions.updateCategory(category));
    },

    updateNegativeNewsQueries(postfilter, values, specificCustomQuery) {
        const languageQuery = specificCustomQuery.customQuery.find(_ => _.language === postfilter.contentLanguage);
        const results = {};
    
        values && values.forEach(value => {
            switch (value) {
                case NEGATIVITY_LEVELS.LOW:
                    results.lowQueryString = languageQuery.lowQueryString;
                    break;
                case NEGATIVITY_LEVELS.MEDIUM:
                    results.mediumQueryString = languageQuery.mediumQueryString;
                    break;
                case NEGATIVITY_LEVELS.HIGH:
                    results.highQueryString = languageQuery.highQueryString;
                    break;
                default:
                    break;
            }
        });
        return results;
    },

    populateDisabledCategory(category) {
        return new Promise((resolve) => {
            if (category.name === CATEGORY_NAMES.LAW_SOURCES) {
                category.loaded = false;
                category.checked = true;
            } else {
                category.loaded = true;
            }

            category.articles = [];
            category.count = 0;
            category.hasError = false;
            category.hasMore = false;
            reduxStore.dispatch(searchResultsActions.updateCategory(category));

            return resolve(category);
        });
    },

    executeBillingSearchEvent(category: string, billingId: string, costCode: string) {
        if (!categoryUtils.isDnbCategory(category)) {
            MainSearchApi.billingForSearch({ billingId, costCode });
        }
    },

    generateDefaultPostFilter: function (searchQuery, searchQueryType, prefilterQuery) {
        let dateRange = createDefaultSearchDatesObject();
        let { proximity, sort } = reduxStore.getState().user.preferences.generalSettings;

        let defaultPostFilter = {
            searchQuery,
            searchQueryType,
            prefilterQuery,
            includeTerms: [],
            excludeTerms: [],
            sort: sort || SORT_OPTIONS.DEFAULT_SORT_OPTION,
            fuzzyNames: [],
            suggestedNames: [],
            dateRange,
            proximity,
        };
        return defaultPostFilter;
    },

    // The dynamic post-filters should be set to null when trying to do the filterInfo request
    // with the default values; the rest of the filters can have the selected value
    getPostFiltersDefaultValues(postFilters) {
        let postFilterKeys = Object.keys(postFilters);
        let defaultPostFilters = {};

        if (postFilterKeys.length > 0) {
            postFilterKeys.forEach((postFilter) => {
                switch (postFilter) {
                    case POST_FILTER_GEOGRAPHY:
                    case POST_FILTER_LANGUAGE:
                    case POST_FILTER_SOURCE:
                    case POST_FILTER_SOURCE_NAME:
                    case POST_FILTER_SOURCE_TYPE:
                    case POST_FILTER_COMPANY:
                    case POST_FILTER_SEARCH_PUBLICATION_NAME:
                    case POST_FILTER_SEARCH_SOURCE_NAME:
                    case POST_FILTER_SEARCH_CONTENT_TYPE:
                    case POST_FILTER_DOCKET_STATUS:
                    case POST_FILTER_EXCLUDE_TERMS:
                    case POST_FILTER_INCLUDE_TERMS:
                    case POST_FILTER_TERMS:
                    case POST_FILTER_ESG_FACTORS:
                        defaultPostFilters[postFilter] = null;
                        break;
                    default:
                        defaultPostFilters[postFilter] = postFilters[postFilter];
                        break;
                }

                let subjectAndIndustryAvailable = reduxStore.getState().user.subjectAndIndustryAvailable;

                if (subjectAndIndustryAvailable) {
                    if (postFilter === POST_FILTER_SUBJECT || postFilter === POST_FILTER_INDUSTRY) {
                        defaultPostFilters[postFilter] = null;
                    }
                }
            });

            //These fields need to be cleared, so that all the filters are loaded and filtering can happen without
            //any side effects(wrong counts)
            delete defaultPostFilters['personMentions'];
            defaultPostFilters['dateRange'] = 'all';
            defaultPostFilters['fuzzyOn'] = true;
        }

        return defaultPostFilters;
    },

    //search used for alerts
    searchArticlesForAlerts(queryObject) {
        let {
            searchQuery,
            searchQueryType,
            prefilterQuery,
            postFilters,
            preloadCategory,
            countOnly,
            disabledCategories,
            alertCategories,
        } = queryObject;
        const store = reduxStore.getState();
        const subjectAndIndustryAvailable = store.user.subjectAndIndustryAvailable;
        const newFuzzyEnabled = isNewFuzzyEnabled(store);
        const suggestedNamesEnabled = isSuggestedNamesEnabled(store);

        reduxStore.dispatch(searchResultsActions.resetCategoriesResults());
        reduxStore.dispatch(
            postFilterConfigActions.resetAllPostFiltersConfigValues(
                searchQueryType,
                subjectAndIndustryAvailable,
                newFuzzyEnabled,
                suggestedNamesEnabled
            )
        );
        reduxStore.dispatch(searchParamsActions.updateQueryString(searchQuery));
        reduxStore.dispatch(searchParamsActions.updateQueryType(searchQueryType));
        reduxStore.dispatch(searchParamsActions.setBooleanTerms(utils.addFirstBooleanOperator(prefilterQuery)));

        loadCurrentReport(searchQuery, searchQueryType);

        let queue = DilQueue();
        return queue.abortLowPriorityTasks().then(() => {
            let defaultPostFilters = this.generateDefaultPostFilter(searchQuery, searchQueryType, prefilterQuery);

            if (searchQueryType === COMPANY_SEARCH) {
                defaultPostFilters.searchSpecifically =
                    reduxStore.getState().user.preferences.companyCheck.searchSpecifically;
            }

            // Enabled categories for current alert
            let updatedCategories = [];
            alertCategories.forEach((categoryName) => {
                updatedCategories.push({
                    name: categoryName,
                    enabled: true,
                });
            });
            reduxStore.dispatch(searchResultsActions.updateCategoriesList(updatedCategories));
            let postFilterDataToBeUpdated = [];
            alertCategories.forEach((categoryName) => {
                let postFilterConfig = cloneDeep(reduxStore.getState().postFilterConfiguration[categoryName]);
                let categoryPostfilters = postFilters[categoryName];

                if (Object.keys(LEGAL_SOURCES_TITLES).indexOf(categoryName) !== -1) {
                    defaultPostFilters.searchNamePartyEntity =
                        reduxStore.getState().adHocSearch.searchNamePartyEntity ||
                        reduxStore.getState().user.preferences.generalSettings.legalSources.searchNamePartyEntity ||
                        false;
                }

                const postFilterObj = retrievePostFilterData(
                    postFilterConfig,
                    categoryPostfilters,
                    categoryName,
                    searchQueryType
                );
                postFilterDataToBeUpdated.push(...postFilterObj);
            });
            reduxStore.dispatch(postFilterConfigActions.updatePostFilterConfigValuesList(postFilterDataToBeUpdated));
            let disabledCategoriesList = [];
            // Disabled categories for current alert
            disabledCategories.forEach((categoryName) => {
                disabledCategoriesList.push({
                    name: categoryName,
                    enabled: false,
                });
            });
            reduxStore.dispatch(searchResultsActions.updateCategoriesList(disabledCategoriesList));

            let categoriesResults = reduxStore.getState().searchResults;

            // Doing the documents request for each category, because the documents-count (for negative news and legal)
            // doesn't allow us to include the post-filters in the request, that's why we wouldn't have the correct results
            return getDefaultSearchSettings({
                categoriesResults,
                defaultPostFilters,
                postFilters,
                searchQuery,
                store,
            }).then((postFilters) => {
                let data = {
                    categoriesResults,
                    postFilters,
                    countOnly,
                    contentSource: null,
                    preloadCategory,
                    ignoreAllChecked: true,
                    redirectToResultList: true,
                };

                this.triggerNewResearchSummaryAlertEvent({ store, queryObject });

                return this.loadDocumentsForCategories(data).then((res) => {
                    // since countsRetrieved waits for uboCount to be done and UBO source is not available on alerts
                    // we're marking it as done to clear the saga queue
                    reduxStore.dispatch({ type: investigationEvents.ACTION_TYPES.uboCountDone });
                    reduxStore.dispatch({
                        type: investigationEvents.ACTION_TYPES.countsRetrieved,
                    });

                    return res;
                });
            });
        });
    },

    triggerNewResearchSummaryAlertEvent({ store, queryObject }) {
        const payload = [];
        const { searchQuery, searchQueryType, postFilters, prefilterQuery } = queryObject;

        Object.keys(postFilters).forEach((key) => {
            const categoryName = postFilters[key].category;
            const name = categoryUtils.isChild(categoryName) ? categoryUtils.getParent(categoryName) : categoryName;

            const subCategory = categoryUtils.isChild(categoryName) ? key : null;

            const settings = {
                ...utils.formatPostFilters(postFilters[key]),
                subCategory: categoryUtils.isChild(postFilters[key].category) ? key : null,
                category: name,
                ...(!!postFilters[key].contentSource && { contentSource: postFilters[key].contentSource }),
            };

            const newsQuery = utils.getExtendedSearchParameters(store, subCategory || name, settings);

            payload.push({ ...settings, newsQuery });
        });

        return reduxStore.dispatch({
            type: investigationEvents.EVENT_SUBTYPES.alert,

            payload: {
                timestamp: new Date().getTime(),
                searchQuery,
                searchQueryType,
                prefilterQuery,
                data: payload,
            },
        });
    },

    buildDefaultPostFiltersForCounts({ state, suggestedNamesEnabled, searchQuery, searchQueryType, prefilterQuery }) {
        const fuzzyNames = state.fuzzyNames;
        const suggestedNames =
            suggestedNamesEnabled && utils.isPersonSearch(searchQueryType) ? state.suggestedNames : [];
        const dateRange = createDefaultSearchDatesObject();
        const { proximity, sort } = state.user.preferences.generalSettings;

        let defaultPostFilters = {
            searchQuery,
            searchQueryType,
            prefilterQuery,
            includeTerms: [],
            excludeTerms: [],
            sort: sort || SORT_OPTIONS.DEFAULT_SORT_OPTION,
            fuzzyNames,
            suggestedNames,
            dateRange,
            proximity,
        };

        if (searchQueryType === COMPANY_SEARCH) {
            defaultPostFilters.searchSpecifically = state.user.preferences.companyCheck.searchSpecifically;
        }

        defaultPostFilters.searchNamePartyEntity =
            state.adHocSearch.searchNamePartyEntity ||
            state.user.preferences.generalSettings.legalSources.searchNamePartyEntity ||
            false;

        if (state.user.newsExcludeTogglesEnabled) {
            defaultPostFilters = this.addExcludeNewsTogglesToPayload(defaultPostFilters, state);
        }

        if (state.user.newFuzzyEnabled && searchQueryType === PERSON_SEARCH) {
            defaultPostFilters.fuzzyOn = state.user.preferences.personCheck.fuzzyOn || false;

            if (defaultPostFilters[POST_FILTER_FUZZY_SEARCH]) {
                defaultPostFilters[POST_FILTER_FUZZY_THRESHOLD] = state.user.preferences.personCheck.fuzzyThreshold;
            }
        }

        return defaultPostFilters;
    },

    shouldUpdateCounts (lastRefreshedNegativityLevels, currentNegativityLevels, negativityRisk){
        if (!currentNegativityLevels || isEqual(lastRefreshedNegativityLevels, currentNegativityLevels)){
            return false;
        } 
            
        const { high, medium, low } = negativityRisk.documentsCount;
        let update = false;

        // needs array parsing to support segmentName or [currentNegativityLevels]
        const negativityLevelsArray = Array.isArray(currentNegativityLevels) 
            ? currentNegativityLevels 
            : [currentNegativityLevels];


        negativityLevelsArray?.forEach((level) => {
            if (level === 'high' && high == 0) {
                update = true;
            }
            if (level === 'medium' && medium == 0) {
                update = true;
            }
            if (level === 'low' && low == 0) {
                update = true;
            }
        });
        return update;
    },

    updateCategoryCounts(queryObject, onFinishUpdateCounts, updateNegativityLevelContext) {
        const { categoryName, searchQuery, searchQueryType, postFilters, ignoreAllChecked, negativityLevel } =
            queryObject;
        let prefilterQuery = queryObject.prefilterQuery;
        prefilterQuery = utils.removeFirstBooleanOperator(prefilterQuery);

        const queue = DilQueue();

        return queue.abortLowPriorityTasks().then(() => {
            reduxStore.dispatch(toggleStatus(false));
            if (!updateNegativityLevelContext) {
                reduxStore.dispatch(searchResultsActions.resetSingleCategoryResults(categoryName));
            }

            const state = reduxStore.getState();
            const searchResults = cloneDeep(state.searchResults);
            const suggestedNamesEnabled = isSuggestedNamesEnabled(state);

            let categoriesResults = { [categoryName]: searchResults[categoryName] };
            Object.keys(searchResults).forEach((categoryKey) => {
                if (categoryUtils.isChildOf(categoryName, categoryKey) && searchResults[categoryKey]?.enabled) {
                    categoriesResults[categoryKey] = searchResults[categoryKey];
                }
            });

            const defaultPostFilters = this.buildDefaultPostFiltersForCounts({
                state,
                suggestedNamesEnabled,
                searchQuery,
                searchQueryType,
                prefilterQuery,
            });

            return getDefaultSearchSettings({ categoriesResults, defaultPostFilters, postFilters, store: state }).then(
                (defaultSearchSettings) => {
                    const parentCategoriesPostFiltersV4 = mapParentCatPostFiltersForV4Payload(postFilters);
                    const data = {
                        categoriesResults,
                        postFilters: defaultSearchSettings,
                        alertSearch: false,
                        updateOnly: true,
                        ignoreAllChecked: ignoreAllChecked,
                        negativityLevel,
                        parentCategoriesPostFiltersV4,
                    };

                    this.getCountsForCategories(data).then((categoriesData) => {
                        onFinishUpdateCounts();

                        return categoriesData;
                    });

                    return defaultSearchSettings;
                }
            );
        });
    },

    getArticlesCounts(queryObject, userPreferencesRefreshObject, searchRunFromScreening = false) {
        let {
            searchQuery,
            searchQueryType,
            prefilterQuery,
            postFilters,
            parentCategoriesPostFiltersV4 = {},
            negativityLevel,
            filtersResetSearchPerformed,
            entityCategories,
            disabledCategories,
            categoriesWithCounts,
        } = queryObject;

        prefilterQuery = utils.removeFirstBooleanOperator(prefilterQuery);
        let queue = DilQueue();
        return queue.abortLowPriorityTasks().then(() => {
            errorUtils.failedCategoriesCollector.clear();
            reduxStore.dispatch(toggleStatus(false));

            if (!filtersResetSearchPerformed) {
                loadCurrentReport(searchQuery, searchQueryType);
            }
            //this condition should be removed after the regular search is migrated to the saga flows
            if (!searchRunFromScreening) {
                reduxStore.dispatch(searchResultsActions.resetCategoriesResults());
            }
            const state = reduxStore.getState();
            const subjectAndIndustryAvailable = state.user.subjectAndIndustryAvailable;
            const newFuzzyEnabled = isNewFuzzyEnabled(state);
            const suggestedNamesEnabled = isSuggestedNamesEnabled(state);

            reduxStore.dispatch(
                postFilterConfigActions.resetAllPostFiltersConfigValues(
                    searchQueryType,
                    subjectAndIndustryAvailable,
                    newFuzzyEnabled,
                    suggestedNamesEnabled
                )
            );
            let categoriesResults = state.searchResults;
            const defaultPostFilters = this.buildDefaultPostFiltersForCounts({
                state,
                suggestedNamesEnabled,
                searchQuery,
                searchQueryType,
                prefilterQuery,
            });

            if (entityCategories && entityCategories.length > 0) {
                utils.updateFilteredCategoriesAndPostfilterConfig(
                    entityCategories,
                    defaultPostFilters,
                    postFilters,
                    searchQueryType
                );
            }

            if (disabledCategories && disabledCategories.length > 0) {
                const disabledCategoriesList = utils.updateDisabledCategories(disabledCategories);
                reduxStore.dispatch(searchResultsActions.updateCategoriesList(disabledCategoriesList));
            }

            const categories = !categoriesWithCounts
                ? categoriesResults
                : Object.fromEntries(
                    Object.entries(categoriesResults).filter(([key]) => key === CATEGORY_NAMES.ESG_RATINGS || !categoriesWithCounts.includes(key))
                );

            return getDefaultSearchSettings({ categoriesResults, defaultPostFilters, postFilters, store: state }).then(
                (defaultSearchSettings) => {
                    const data = {
                        categoriesResults: categories,
                        postFilters: defaultSearchSettings,
                        parentCategoriesPostFiltersV4,
                        alertSearch: false,
                        updateOnly: false,
                        ignoreAllChecked: true,
                        negativityLevel,
                        filtersResetSearchPerformed,
                    };

                    this.getCountsForCategories(data).then((categoriesData) => {
                        if (userPreferencesRefreshObject.isSnapshotEnabled) {
                            userPreferencesRefreshObject.checkPreferencesAreObsolete();
                        }
                        return categoriesData;
                    });
                }
            );
        });
    },

    getCountsForCategories(data) {
        let {
            categoriesResults,
            postFilters,
            parentCategoriesPostFiltersV4 = {},
            alertSearch,
            ignoreAllChecked,
            negativityLevel,
            updateOnly,
            filtersResetSearchPerformed,
        } = data;
        let { postFilterConfig, lawSources, negativeNews, customNews, isCustomFuzzy, editSearch } =
            getSearchInfoFromState();
        let allCategoriesPromise = [];
        let negativeNewsCountPromise = {};
        let legalCountPromise = {};
        let customNewsCountPromise = {};
        let enabledNegativeNewsCategories = {};
        let enabledLegalCategories = {};
        let enabledCustomNewsCategories = {};
        let enabledEsgCategories = {};
        let esgCategoriesCountPromise = {};
        let individualCategories = [];
        let payload;
        let fullPayload = [];
        let categoryNames;
        let ts = new Date().getTime();
        const isSearchFromScreeningPage =
            reduxStore.getState().searchState.launchedFrom === LAUNCHED_SEARCH_FROM.SCREENING_PAGE;

        Object.keys(categoriesResults).forEach((key) => {
            if (categoryUtils.isDnbCategory(key)) return; // not for UBO

            let category = Object.assign({}, categoriesResults[key]);
            category.postFilters = postFilters[key];

            if (editSearch[key]) {
                category.postFilters.dateRange = editSearch[key].postFilters.dateRange;
            }
            category.filterInfoParams = getFilterInfoParams(postFilterConfig[category.name]);

            //@TODO - we need to refactor the search

            if (
                utils.isCategoryEnabled(category.name) === false ||
                categoryUtils.hasChildren(category.name) ||
                (!categoryUtils.shouldDisplayForState(category.name, reduxStore.getState()) && !alertSearch)
            ) {
                allCategoriesPromise.push(SearchUtils.populateDisabledCategory(category));
            } else {
                if (
                    categoryUtils.isChildOf(CATEGORY_NAMES.NEGATIVE_NEWS, category.name) &&
                    !utils.isCategoryEnabled(CATEGORY_NAMES.NEGATIVE_NEWS) &&
                    !alertSearch
                ) {
                    allCategoriesPromise.push(SearchUtils.populateDisabledCategory(category));
                    return;
                }
                if (
                    categoryUtils.isChildOf(CATEGORY_NAMES.CUSTOM_NEWS, category.name) &&
                    !utils.isCategoryEnabled(CATEGORY_NAMES.CUSTOM_NEWS) &&
                    !alertSearch
                ) {
                    allCategoriesPromise.push(SearchUtils.populateDisabledCategory(category));
                    return;
                }
                payload = utils.formatPostFilters(category.postFilters);
                payload.pageSize = 0;
                payload.currentPageIndex = 0;
                payload.getSimilarDocs = false;
                payload.isCustomFuzzy = isCustomFuzzy;

                if (categoryUtils.isChildOf(CATEGORY_NAMES.NEGATIVE_NEWS, category.name)) {
                    let language = categoryUtils.getCategory(category.name).extendConfig().contentLanguage;
                    enabledNegativeNewsCategories[language] = { payload, category };
                } else if (categoryUtils.isChildOf(CATEGORY_NAMES.LAW_SOURCES, category.name)) {
                    enabledLegalCategories[category.name] = { payload, category };
                } else if (CATEGORY_NAMES.ESG_RATINGS === category.name) {
                    enabledEsgCategories[category.name] = { payload, category };
                } else if (categoryUtils.isChildOf(CATEGORY_NAMES.CUSTOM_NEWS, category.name)) {
                    enabledCustomNewsCategories[category.name] = { payload, category };
                } else if (!categoryUtils.isDnbCategory(category.name)) {
                    individualCategories.push({ category, payload });
                }
            }
        });

        function individualCategoriesProcess(returnCondition) {
            individualCategories.forEach((data) => {
                if (returnCondition(data.category.name)) {
                    return;
                }

                let promise = searchHelpers.populateEnabledCategory({
                    category: data.category,
                    pageSize: 0,
                    countOnly: true,
                    isCustomFuzzy: isCustomFuzzy,
                    ts,
                    ignoreAllChecked,
                });

                fullPayload.push({
                    category: data.category.name,
                    isCustomFuzzy: isCustomFuzzy,
                    ...data.payload,
                });
                allCategoriesPromise.push(promise);
            });
        }

        //  prioritize the news request being before documents-count
        individualCategoriesProcess((category) => category !== CATEGORY_NAMES.NEWS);

        if (Object.keys(enabledNegativeNewsCategories).length > 0) {
            reduxStore.dispatch(
                searchResultsActions.updateCategoryProperty(CATEGORY_NAMES.NEGATIVE_NEWS, LOADED, false)
            );
            payload = enabledNegativeNewsCategories[Object.keys(enabledNegativeNewsCategories)[0]].payload;
            categoryNames = Object.keys(enabledNegativeNewsCategories).map(
                (key) => enabledNegativeNewsCategories[key].category.name
            );
            payload = withOptimizedSearchCount().extendConfig(categoryNames, payload);

            //overwrite the negativityLevels postFilter when the user navigates via NNV donut chart
            payload.negativityLevels = negativityLevel;
            payload = updateNewsQueriesBasedOnNegativityLevels(payload);

            payload.version = API_DOCUMENTS_COUNT_SEARCH_VERSION;
            payload.categories = [CATEGORY_NAMES.NEGATIVE_NEWS];
            payload.contentLanguages = Object.keys(enabledNegativeNewsCategories);

            // NOTE: when the search is launched from screening page, use the v4 search endpoint. In the near future, we will use this endpoint for any kind of search
            if (isSearchFromScreeningPage) {
                payload.pageSize = 0;
                payload.postFilters = buildParentCategoryPostFilter(
                    negativeNews,
                    payload,
                    parentCategoriesPostFiltersV4[CATEGORY_NAMES.NEGATIVE_NEWS]
                );
                payload.version = API_SEARCH_VERSION_4;
            }

            negativeNewsCountPromise = searchHelpers.getCountsForCategory(
                enabledNegativeNewsCategories,
                negativeNews,
                payload,
                ts
            );
            fullPayload.push({ ...payload, subCategories: categoryNames });
            allCategoriesPromise.push(negativeNewsCountPromise);
        }

        if (Object.keys(enabledLegalCategories).length > 0) {
            reduxStore.dispatch(searchResultsActions.updateCategoryProperty(CATEGORY_NAMES.LAW_SOURCES, LOADED, false));
            payload = enabledLegalCategories[Object.keys(enabledLegalCategories)[0]].payload;
            payload = withOptimizedSearchCount().extendConfig(Object.keys(enabledLegalCategories), payload);
            payload.version = API_DOCUMENTS_COUNT_SEARCH_VERSION;
            payload.categories = Object.keys(enabledLegalCategories);

            // NOTE: when the search is launched from screening page, use the v4 search endpoint. In the near future, we will use this endpoint for any kind of search
            if (isSearchFromScreeningPage) {
                payload.pageSize = 0;
                payload.postFilters = buildParentCategoryPostFilter(
                    lawSources,
                    payload,
                    parentCategoriesPostFiltersV4[CATEGORY_NAMES.LAW_SOURCES]
                );
                payload.version = API_SEARCH_VERSION_4;
            }

            fullPayload.push({ ...payload, subCategories: payload.categories, category: CATEGORY_NAMES.LAW_SOURCES });

            legalCountPromise = searchHelpers.getCountsForCategory(enabledLegalCategories, lawSources, payload, ts);
            allCategoriesPromise.push(legalCountPromise);
        }
        if (Object.keys(enabledCustomNewsCategories).length > 0) {
            reduxStore.dispatch(searchResultsActions.updateCategoryProperty(CATEGORY_NAMES.CUSTOM_NEWS, LOADED, false));
            payload = enabledCustomNewsCategories[Object.keys(enabledCustomNewsCategories)[0]].payload;
            categoryNames = Object.keys(enabledCustomNewsCategories).map(
                (key) => enabledCustomNewsCategories[key].category.name
            );
            payload = withOptimizedSearchCount().extendConfig(categoryNames, payload);
            payload.version = API_DOCUMENTS_COUNT_SEARCH_VERSION;
            payload.categories = [CATEGORY_NAMES.CUSTOM_NEWS];
            payload.customNews = Object.keys(enabledCustomNewsCategories);

            // NOTE: when the search is launched from screening page, use the v4 search endpoint. In the near future, we will use this endpoint for any kind of search
            if (isSearchFromScreeningPage) {
                payload.pageSize = 0;
                payload.postFilters = buildParentCategoryPostFilter(
                    customNews,
                    payload,
                    parentCategoriesPostFiltersV4[CATEGORY_NAMES.CUSTOM_NEWS]
                );
                payload.version = API_SEARCH_VERSION_4;
            }

            fullPayload.push({ ...payload, subCategories: categoryNames });
            customNewsCountPromise = searchHelpers.getCountsForCategory(
                enabledCustomNewsCategories,
                customNews,
                payload,
                ts
            );
            allCategoriesPromise.push(customNewsCountPromise);
        }
        if (Object.keys(enabledEsgCategories).length > 0) {
            reduxStore.dispatch(searchResultsActions.updateCategoryProperty(CATEGORY_NAMES.ESG_RATINGS, LOADED, false));
            payload = enabledEsgCategories[Object.keys(enabledEsgCategories)[0]].payload;
            categoryNames = Object.keys(enabledEsgCategories).map((key) => enabledEsgCategories[key].category.name);
            payload = withOptimizedSearchCount().extendConfig(categoryNames, payload);
            payload.version = API_DOCUMENTS_COUNT_SEARCH_VERSION;
            payload.categories = [CATEGORY_NAMES.ESG_RATINGS];
            payload.category = CATEGORY_NAMES.ESG_RATINGS;
            fullPayload.push({ ...payload, subCategories: categoryNames });
            esgCategoriesCountPromise = searchHelpers.getCountsForCategory(
                enabledEsgCategories,
                customNews,
                payload,
                ts,
                true
            );
            allCategoriesPromise.push(esgCategoriesCountPromise);
        }

        individualCategoriesProcess((category) => category === CATEGORY_NAMES.NEWS);

        searchHelpers.checkRequestsOrder(fullPayload);

        if (!updateOnly) {
            reduxStore.dispatch({
                type: filtersResetSearchPerformed
                    ? investigationEvents.EVENT_SUBTYPES.filters
                    : investigationEvents.EVENT_SUBTYPES.normal,
                payload: {
                    timestamp: ts,
                    data: fullPayload,
                },
            });

            if (filtersResetSearchPerformed) {
                action(investigationEvents.EVENT_TYPES.setSameInvestigation);
            }

            // Mark time when search is started in order to gather loading metrics
            reduxStore.dispatch({
                type: ACTIONS.metricSearchStarted,
                payload: {
                    timestamp: new Date().getTime(),
                },
            });
        }

        return Promise.all(allCategoriesPromise)
            .then((allResponses) => {
                const { lastPreferencesUpdateInfo } = allResponses[allResponses.length - 1];
                utils.shouldUpdateLastPreferencesUpdateTimestamp(lastPreferencesUpdateInfo);

                if (!utils.isCategoryEnabled(CATEGORY_NAMES.DNB)) {
                    // we send this action so the investigation counts available event will be triggered even when ubo is not enabled
                    reduxStore.dispatch({ type: investigationEvents.ACTION_TYPES.uboCountDone });
                }

                if (!updateOnly) {
                    reduxStore.dispatch({ type: investigationEvents.ACTION_TYPES.countsRetrieved });

                    // Search is finished, gather metrics
                    reduxStore.dispatch({
                        type: ACTIONS.metricGather,
                    });
                }

                reduxStore.dispatch(toggleStatus(true));
            })
            .catch(() => {
                reduxStore.dispatch(toggleStatus(true));
                reduxStore.dispatch({ type: investigationEvents.ACTION_TYPES.countsRetrieved });

                if (!utils.isCategoryEnabled(CATEGORY_NAMES.DNB)) {
                    // we send this action so the investigation counts available event will be triggered even when ubo is not enabled
                    reduxStore.dispatch({ type: investigationEvents.ACTION_TYPES.uboCountDone });
                }

                // Search is finished, gather metrics
                reduxStore.dispatch({
                    type: ACTIONS.metricGather,
                });
            });
    },

    //main search used to begin a new search
    async searchArticles(queryObject) {
        let {
            searchQuery,
            searchQueryType,
            prefilterQuery,
            postFilters,
            preloadCategory,
            countOnly,
            useNewResearchSummary,
            categoriesWithCounts,
        } = queryObject;

        prefilterQuery = utils.removeFirstBooleanOperator(prefilterQuery);
        let queue = DilQueue();
        return queue.abortLowPriorityTasks().then(() => {
            let store = reduxStore.getState();
            const subjectAndIndustryAvailable = store.user.subjectAndIndustryAvailable;
            const newFuzzyEnabled = isNewFuzzyEnabled(store);
            const suggestedNamesEnabled = isSuggestedNamesEnabled(store);

            loadCurrentReport(searchQuery, searchQueryType);
            reduxStore.dispatch(searchResultsActions.resetCategoriesResults());
            reduxStore.dispatch(
                postFilterConfigActions.resetAllPostFiltersConfigValues(
                    searchQueryType,
                    subjectAndIndustryAvailable,
                    newFuzzyEnabled,
                    suggestedNamesEnabled
                )
            );
            store = reduxStore.getState();
            let categoriesResults = store.searchResults;
            let fuzzyNames = store.fuzzyNames;
            let suggestedNames =
                suggestedNamesEnabled && utils.isPersonSearch(searchQueryType) ? store.suggestedNames : [];
            let dateRange = createDefaultSearchDatesObject();
            let { proximity, sort } = store.user.preferences.generalSettings;

            let defaultPostFilters = {
                searchQuery,
                searchQueryType,
                prefilterQuery,
                includeTerms: [],
                excludeTerms: [],
                sort: sort || SORT_OPTIONS.DEFAULT_SORT_OPTION,
                fuzzyNames,
                suggestedNames,
                dateRange,
                proximity,
            };

            const categories = !categoriesWithCounts
                ? categoriesResults
                : Object.fromEntries(
                    Object.entries(categoriesResults).filter(([key]) => !categoriesWithCounts.includes(key))
                );

            return getDefaultSearchSettings({
                categories,
                defaultPostFilters,
                postFilters,
                searchQuery,
                store,
            }).then((postFilters) => {
                let data = {
                    categoriesResults,
                    postFilters,
                    countOnly,
                    contentSource: null,
                    preloadCategory,
                    ignoreAllChecked: false,
                    useNewResearchSummary,
                };

                return this.loadDocumentsForCategories(data);
            });
        });
    },

    async searchArticlesFromHistory(queryObject: QueryObjectType) {
        let {
            searchQuery,
            searchQueryType,
            dnbSelectedCategories,
            postFilters,
            categoryName,
            prefilterQuery,
            contentSource,
            newsSource,
        } = queryObject;

        if (categoryUtils.isDnbCategory(categoryName)) {
            // for ubo category init ubo with the ubo postfilters
            const uboPostFilters = { [categoryName]: {} };
            getUboPostFilterFields().forEach(
                (field) => (uboPostFilters[categoryName][field] = queryObject.postFilters[field])
            );

            let uboPayload = {
                ...dnbSelectedCategories,
                postFilters: uboPostFilters,
            };
            reduxStore.dispatch(uboActions.submit(uboPayload));
        } else {
            // if not ubo category, init ubo normally
            reduxStore.dispatch(uboActions.submit(dnbSelectedCategories));
        }
        // resets previous app state
        reduxStore.dispatch(searchResultsActions.resetCategoriesResults());
        reduxStore.dispatch(adHocSearchActions.updateAdHocSearchObject({}));
        let store = reduxStore.getState();
        const subjectAndIndustryAvailable = store.user.subjectAndIndustryAvailable;
        const newFuzzyEnabled = isNewFuzzyEnabled(store);
        const suggestedNamesEnabled = isSuggestedNamesEnabled(store);
        const { fuzzyOn, fuzzyThreshold } = store.user.preferences.personCheck.fuzzyOn
            ? store.user.preferences.personCheck
            : { ...store.user.preferences.personCheck, ...{ fuzzyThreshold: '' } };

        reduxStore.dispatch(
            postFilterConfigActions.resetAllPostFiltersConfigValues(
                searchQueryType,
                subjectAndIndustryAvailable,
                newFuzzyEnabled,
                suggestedNamesEnabled
            )
        );

        this.addTermsToRedux(postFilters, categoryName);
        reduxStore.dispatch(userPreferencesActions.updateFuzzyNames(postFilters.fuzzyNames, searchQuery));
        reduxStore.dispatch(
            suggestedNamesActions.updateSuggestedNamesAndQuery(postFilters.suggestedNames, searchQuery)
        );
        store = reduxStore.getState();
        let queue = DilQueue();
        queue.abortLowPriorityTasks();
        loadCurrentReport(searchQuery, searchQueryType);
        let billingId = costCodeUtils.generateBillingId();
        let costCode = costCodeUtils.getCostCode();
        SearchUtils.executeBillingSearchEvent(categoryName, billingId, costCode);
        let categoriesResults = store.searchResults;
        let dateRange = createDefaultSearchDatesObject();
        let { proximity } = store.user.preferences.generalSettings;
        let postFilterConfig = cloneDeep(store.postFilterConfiguration[categoryName]);

        postFilters.fuzzyNames = { list: postFilters.fuzzyNames, query: searchQuery };
        postFilters.suggestedNames = { list: postFilters.suggestedNames, query: searchQuery };

        let defaultPostFilters = {
            searchQuery,
            prefilterQuery,
            fuzzyOn,
            fuzzyThreshold,
            searchQueryType,
            includeTerms: [],
            excludeTerms: [],
            sort: postFilters.sort || SORT_OPTIONS.DEFAULT_SORT_OPTION,
            fuzzyNames: postFilters.fuzzyNames,
            suggestedNames: postFilters.suggestedNames,
            dateRange,
            proximity,
        };

        if (searchQueryType === COMPANY_SEARCH) {
            defaultPostFilters.searchSpecifically = store.user.preferences.companyCheck.searchSpecifically;
        }

        defaultPostFilters.searchNamePartyEntity =
            store.adHocSearch.searchNamePartyEntity ||
            store.user.preferences.generalSettings.legalSources.searchNamePartyEntity ||
            false;

        if (store.user.newsExcludeTogglesEnabled) {
            defaultPostFilters = this.addExcludeNewsTogglesToPayload(defaultPostFilters, store);
        }

        const postFilterObj = retrievePostFilterData(postFilterConfig, postFilters, categoryName, searchQueryType);
        reduxStore.dispatch(postFilterConfigActions.updatePostFilterConfigValuesList(postFilterObj));

        /*if (queryObject[POST_FILTER_DUNS_FILTER]) {
            defaultPostFilters[POST_FILTER_DUNS_FILTER] =  queryObject[POST_FILTER_DUNS_FILTER];
        }*/

        getDefaultSearchSettings({ categoriesResults, defaultPostFilters, searchQuery, store }).then(
            (mergedPostFilters) => {
                //override merged postfilters with the selected value from history
                mergedPostFilters[categoryName] = postFilters;

                Object.keys(mergedPostFilters).forEach((key) => {
                    if (categoryName !== key) {
                        mergedPostFilters[key][POST_FILTER_INCLUDE_TERMS] = [];
                        mergedPostFilters[key][POST_FILTER_EXCLUDE_TERMS] = [];
                        mergedPostFilters[key][POST_FILTER_TERMS] = [];
                    }
                });

                if (categoryUtils.isDnbCategory(categoryName)) {
                    // TODO: ubo postfilters dynamic
                    const categoryUboPostFilters = {};
                    getUboPostFilterFields().forEach(
                        (field) => (categoryUboPostFilters[field] = queryObject.postFilters[field])
                    );
                    const uboPostFilters = {
                        ...categoryUboPostFilters,
                        searchQuery: queryObject.postFilters.term,
                    };
                    mergedPostFilters[categoryName] = formatUboCategoryPostFilters(uboPostFilters, categoryName);
                    categoriesResults[categoryName].postFilters = formatUboCategoryPostFilters(
                        uboPostFilters,
                        categoryName
                    );
                }

                this.triggerNewResearchSummaryHistoryEvent({
                    categoriesResults,
                    mergedPostFilters,
                    store,
                    queryObject,
                });

                let data = {
                    categoriesResults,
                    postFilters: mergedPostFilters,
                    countOnly: true,
                    contentSource,
                    newsSource,
                    preloadCategory: categoryName,
                    ignoreAllChecked: true,
                    redirectToResultList: true
                };
                return this.loadDocumentsForCategories(data).then(() => {
                    if (!utils.isCategoryEnabled(CATEGORY_NAMES.DNB)) {
                        // we send this action so the investigation counts available event will be triggered even when ubo is not enabled
                        reduxStore.dispatch({ type: investigationEvents.ACTION_TYPES.uboCountDone });
                    }
                    reduxStore.dispatch({ type: investigationEvents.ACTION_TYPES.countsRetrieved });
                });
            }
        );
    },

    triggerNewResearchSummaryHistoryEvent({ categoriesResults, mergedPostFilters, store, queryObject }) {
        const payload = [];
        const {
            searchQuery,
            searchQueryType,
            categoryName,
            newsSource,
            postFilters,
            prefilterQuery,
            lastSearchRunDate,
        } = queryObject;
        Object.keys(categoriesResults).forEach((key) => {
            let category = Object.assign({}, categoriesResults[key]);

            if (key === categoryName) {
                const name = categoryUtils.isChild(categoryName) ? categoryUtils.getParent(categoryName) : categoryName;
                const settings = {
                    ...utils.formatPostFilters(postFilters),
                    subCategory: categoryUtils.isChild(categoryName) ? key : null,
                    category: name,
                    contentSource: newsSource,
                };
                const newsQuery = utils.getExtendedSearchParameters(store, categoryName, settings);

                payload.push({ ...settings, newsQuery });
            } else {
                if (
                    utils.isCategoryEnabled(category.name) &&
                    !categoryUtils.hasChildren(category.name) &&
                    categoryUtils.shouldDisplayForState(category.name, store)
                ) {
                    category.postFilters = mergedPostFilters[key];
                    category.isCustomFuzzy = store.searchParams.isCustomFuzzy;
                    const name = categoryUtils.isChild(key) ? categoryUtils.getParent(key) : key;

                    const contentSource = categoryUtils.getContentNewsSource(store, key, 'name');
                    const subCategory = categoryUtils.isChild(key) ? key : null;
                    const settings = buildFilterInfoPayload({ category, countOnly: true, ignoreAllChecked: true });
                    const newsQuery = utils.getExtendedSearchParameters(store, subCategory, settings);

                    payload.push({ ...settings, category: name, newsQuery, subCategory, contentSource });
                }
            }
        });
        reduxStore.dispatch({
            type: investigationEvents.EVENT_SUBTYPES.history,

            payload: {
                timestamp: new Date().getTime(),
                searchQuery,
                searchQueryType,
                prefilterQuery,
                lastSearchRunDate,
                fuzzyThreshold: postFilters.fuzzyThreshold,
                data: payload,
            },
        });
    },

    addTermsToRedux(postFilters: PostFiltersType, categoryName: string) {
        if (postFilters.includeTerms && postFilters.includeTerms.length !== 0) {
            postFilters.includeTerms.map((includeTerm) => {
                reduxStore.dispatch(searchResultsActions.addTerms(includeTerm, INCLUDE, categoryName));
            });
        } else if (postFilters.excludeTerms && postFilters.excludeTerms.length !== 0) {
            postFilters.excludeTerms.map((excludeTerm) => {
                //TODO: this is for include or exclude terms
                reduxStore.dispatch(searchResultsActions.addTerms(excludeTerm, INCLUDE, categoryName));
            });
        }
    },

    isCustomFuzzy(query) {
        const queryParts = query.split(OPERATOR_AND);
        if (queryParts[0]) {
            let nameParts = queryParts[0].split(OPERATOR_OR);
            if (nameParts.length) {
                // filter empty names
                nameParts = nameParts
                    .map((namePart) => namePart.replace(/"/g, '').trim())
                    .filter((namePart) => namePart.length);
                // if we still have something, return the custom fuzzy names
                if (nameParts.length > 1 || (nameParts.length === 1 && queryParts[0].indexOf(OPERATOR_OR) > 0)) {
                    return nameParts;
                }
            }
        }
        return false;
    },

    doFuzzySearch(query, searchType, isFuzzyEnabled) {
        if (query) {
            let queue = DilQueue();
            queue.abortCallByUrl(URL_FUZZY);
            reduxStore.dispatch(userPreferencesActions.updateFuzzyLoadingStatus(false));

            // Custom Fuzzy Names check
            if (this.isCustomFuzzy(query)) {
                return SearchUtils.getCustomFuzzy(query);
            } else {
                reduxStore.dispatch(searchParamsActions.updateCustomFuzzyFlag(false));
            }

            // Strip out w/, pre/ connectors
            query = query.replace(/(w|pre)\/[^\s]*/g, '').replace(/\s\s+/g, ' ');

            if (searchType === PERSON_SEARCH && isFuzzyEnabled) {
                return MainSearchApi.fuzzySearch(query)
                    .then((response) => {
                        let fuzzyList = [];
                        if (response.entity && response.entity.length > 0) {
                            fuzzyList = response.entity.map((item) => {
                                return {
                                    name: item.name,
                                    selected: false,
                                };
                            });
                        }
                        reduxStore.dispatch(userPreferencesActions.updateFuzzyNames(fuzzyList, query));
                        reduxStore.dispatch(userPreferencesActions.updateFuzzyLoadingStatus(true));

                        return { list: fuzzyList, query };
                    })
                    .catch((error) => {
                        if (error.message !== PROMISE_CANCELED) {
                            console.log('error loading fuzzy ', error);
                        }
                        reduxStore.dispatch(userPreferencesActions.updateFuzzyLoadingStatus(true));
                        return [];
                    });
            }
        }

        return new Promise((resolve) => {
            return resolve(null);
        });
    },

    getCustomFuzzy(query, suggestedNames) {
        // Custom Fuzzy Names check
        const customFuzzyNames = this.isCustomFuzzy(query);
        if (customFuzzyNames) {
            return new Promise((resolve) => {
                const list = customFuzzyNames.map((name) => ({ name, selected: true }));
                const suggestedNamesList =
                    suggestedNames && suggestedNames.list ? suggestedNames.list.filter((name) => name.selected) : null;

                if (suggestedNamesList) {
                    reduxStore.dispatch(suggestedNamesActions.updateSuggestedNamesAndQuery(suggestedNamesList, query));
                }
                reduxStore.dispatch(userPreferencesActions.updateFuzzyNames(list, query));
                reduxStore.dispatch(userPreferencesActions.updateFuzzyLoadingStatus(true));
                reduxStore.dispatch(searchParamsActions.updateCustomFuzzyFlag(true));

                return resolve({ list, query }, customFuzzyNames);
            });
        } else {
            reduxStore.dispatch(searchParamsActions.updateCustomFuzzyFlag(false));
            return new Promise((resolve) => {
                return resolve({ list: [], query });
            });
        }
    },

    getFilterLabelsBasedOnIds(ids, completeList) {
        let arrayOfLabels = cloneDeep(ids);

        if (completeList && completeList.length > 0) {
            arrayOfLabels = arrayOfLabels.map((id) => {
                let element = completeList.find((element) => id === element.labelId);

                if (element !== undefined) {
                    return element.label;
                } else {
                    return id;
                }
            });
        }

        return arrayOfLabels;
    },

    updateHistory(categoryName, forcedFiltersQuery = null) {
        let forceFilterSearch = [];
        if (forcedFiltersQuery) {
            forceFilterSearch = JSON.parse(decodeURIComponent(forcedFiltersQuery));
        }
        let historyData = {};
        let searchResults = reduxStore.getState().searchResults;
        let category = searchResults[categoryName];
        if (!category) {
            utils.showNotificationsMessage({
                messageText: 'History.failedToUpdateHistoryData.ErrorMessage',
                messageType: 'system-error',
            });
            return;
        }

        let postFilterConfiguration = values(reduxStore.getState().postFilterConfiguration[categoryName]);

        Object.keys(category.postFilters).forEach((key) => {
            let values = category.postFilters[key];
            const postFilterConf = postFilterConfiguration.filter((filter) => filter.searchFieldName === key);
            // special handling of listed/nested/forced filter
            if (
                values ||
                (postFilterConf &&
                    postFilterConf.length &&
                    ((forceFilterSearch[categoryName] &&
                        forceFilterSearch[categoryName].indexOf(postFilterConf[0].searchFieldName) > -1) ||
                        (postFilterConf[0].selected !== null && postFilterConf[0].selected !== undefined)))
            ) {
                if (
                    postFilterConf[0] &&
                    postFilterConf[0].selected !== undefined &&
                    postFilterConf[0].selected !== null
                ) {
                    values = postFilterConf[0].selected;
                } else {
                    if (
                        (postFilterConf[0] && postFilterConf[0].searchFieldName !== POST_FILTER_PROXIMITY) ||
                        postFilterConf[0] === undefined
                    ) {
                        values = SearchUtils.getFilteredPostfilters(postFilterConf[0], values, true);
                    }
                }
            }

            historyData[key] = values;
        });

        let uboSubcategory = '';
        uboSubcategory = Object.keys(searchResults).find((searchResult) => {
            if (
                categoryUtils.isDnbCategory(searchResults[searchResult].name) &&
                searchResults[searchResult].name !== UBO_MAIN_CATEGORY
            ) {
                return searchResult;
            }
        });

        historyData.numberOfResults = category.count;
        categoryUtils.isDnbCategory(categoryName)
            ? (historyData.category = UBO_MAIN_CATEGORY)
            : (historyData.category = category.name);
        historyData.costCode = costCodeUtils.getCostCode();
        historyData.fuzzyNames = utils.getFuzzyNameList(category.postFilters.fuzzyNames);
        historyData.suggestedNames = utils.isPersonSearch(historyData.searchQueryType)
            ? utils.getFuzzyNameList(category.postFilters.suggestedNames)
            : null;
        historyData.timezone = reduxStore.getState().user.timezone;
        historyData.dnbSelectedCategories = reduxStore.getState().ubo.company.selected;
        historyData.newsSource = categoryUtils.getContentNewsSource(reduxStore.getState(), category.name, 'name');

        if (categoryName === CATEGORY_NAMES.FEDERAL_DOCKETS || categoryName === CATEGORY_NAMES.STATE_DOCKETS) {
            historyData[POST_FILTER_DOCKET_STATUS] = utils.getDocketStatusValue(
                category.postFilters[POST_FILTER_DOCKET_STATUS]
            );
        }

        historyData = withContentSourceFilter().extendConfig(category.name, historyData);

        //hack to switch from string to array for customNews
        if (historyData.category === CATEGORY_NAMES.CUSTOM_NEWS) {
            historyData.customNews = Array.isArray(historyData.customNews)
                ? historyData.customNews
                : [historyData.customNews];
        }

        let isUserMIP = reduxStore.getState().user.isMIP;
        if (isUserMIP) return;

        if (categoryUtils.isDnbCategory(categoryName)) {
            const searchQueryForUboCategories = reduxStore.getState().searchParams.query;
            const searchQueryTypeForUboCategories = reduxStore.getState().searchParams.searchType;
            historyData.uboSubcategory = uboSubcategory;
            historyData.searchQueryForUboHistory = replaceSelectedCompanies(
                searchQueryForUboCategories,
                historyData.dnbSelectedCategories
            );
            historyData.searchQuery = searchQueryForUboCategories;
            historyData.searchQueryType = searchQueryTypeForUboCategories;
            historyData.term = category.term;
        }

        let postFilterConfig = reduxStore.getState().postFilterConfiguration[categoryName];

        // Add subject labels on the payload
        if (historyData[FILTER_INFO.SUBJECT]) {
            if (
                postFilterConfig &&
                postFilterConfig[POSTFILTER_TYPE.SUBJECT] &&
                postFilterConfig[POSTFILTER_TYPE.SUBJECT].values
            ) {
                let fullSubject = postFilterConfig[POSTFILTER_TYPE.SUBJECT].values;
                historyData[POST_FILTER_LABELS.SUBJECT] = SearchUtils.getFilterLabelsBasedOnIds(
                    historyData[FILTER_INFO.SUBJECT],
                    fullSubject
                );
            }
        }

        // Add industry labels on the payload
        if (historyData[FILTER_INFO.INDUSTRY]) {
            if (
                postFilterConfig &&
                postFilterConfig[POSTFILTER_TYPE.INDUSTRY] &&
                postFilterConfig[POSTFILTER_TYPE.INDUSTRY].values
            ) {
                let fullIndustry = postFilterConfig[POSTFILTER_TYPE.INDUSTRY].values;
                historyData[POST_FILTER_LABELS.INDUSTRY] = SearchUtils.getFilterLabelsBasedOnIds(
                    historyData[FILTER_INFO.INDUSTRY],
                    fullIndustry
                );
            }
        }

        // remove searchNamePartyEntity for Agency Decision (ignored on BE side as well)
        if (historyData.category === CATEGORY_NAMES.AGENCY_DECISION) {
            delete historyData.searchNamePartyEntity;
        }

        // Add esg factors labels on the payload
        if (historyData[FILTER_INFO.ESG_FACTORS]) {
            if (
                postFilterConfig &&
                postFilterConfig[POSTFILTER_TYPE.ESG_FACTORS] &&
                postFilterConfig[POSTFILTER_TYPE.ESG_FACTORS].values
            ) {
                let fullEsgFactors = postFilterConfig[POSTFILTER_TYPE.ESG_FACTORS].values;
                historyData[POST_FILTER_LABELS.ESG_FACTORS] = SearchUtils.getFilterLabelsBasedOnIds(
                    historyData[FILTER_INFO.ESG_FACTORS],
                    fullEsgFactors
                );
            }
        }

        // Update news queries based on negativity levels
        historyData = updateNewsQueriesBasedOnNegativityLevels(historyData);

        return HistoryApi.updateHistory(historyData).catch((error) => {
            if (error.message !== PROMISE_CANCELED) {
                utils.showNotificationsMessage({
                    messageText: 'History.failedToUpdateHistoryData.ErrorMessage',
                    messageType: 'system-error',
                });
            }
        });
    },

    loadDocumentsForCategories(data, investigationEventType) {
        errorUtils.failedCategoriesCollector.clear();
        reduxStore.dispatch(toggleStatus(false));

        let {
            categoriesResults,
            postFilters,
            countOnly,
            contentSource,
            preloadCategory,
            ignoreAllChecked,
            useNewResearchSummary,
            redirectToResultList
        } = data;

        let { postFilterConfig, pageSize, lawSources, negativeNews, customNews, historyCategoryName, isCustomFuzzy } =
            getSearchInfoFromState();
        let legalSubcategories = [];
        let negNewsSubcategories = [];
        let customNewsSubCategories = [];
        let allCategories = [];

        let ts = new Date().getTime();
        const sortCategories = (a) => {
            if (countOnly && preloadCategory && a === preloadCategory) {
                return -1;
            } else {
                return 0;
            }
        };

        Object.keys(categoriesResults)
            .sort(sortCategories)
            .forEach((key) => {
                let category = Object.assign({}, categoriesResults[key]);
                category.postFilters = postFilters[key];
                category.filterInfoParams = getFilterInfoParams(postFilterConfig[category.name]);

                // a creative way to determine if user is in edit alert page
                const isEditAlert = window.location.hash.indexOf('edit-alert-search') > -1;

                if (
                    utils.isCategoryEnabled(category.name) === false ||
                    categoryUtils.hasChildren(category.name) ||
                    (!categoryUtils.shouldDisplayForState(category.name, reduxStore.getState()) && !isEditAlert)
                ) {
                    SearchUtils.populateDisabledCategory(category);
                } else {
                    let promise = searchHelpers.populateEnabledCategory({
                        category,
                        pageSize,
                        countOnly,
                        historyCategoryName,
                        contentSource,
                        isCustomFuzzy,
                        ts,
                        ignoreAllChecked,
                        investigationEventType,
                        redirectToResultList
                    });

                    if (utils.isLegalSubcategory(category.name) && utils.isCategoryEnabled(category.name)) {
                        legalSubcategories.push(promise);
                    }
                    if (utils.isNegativeNewsSubcategory(category.name) && utils.isCategoryEnabled(category.name)) {
                        negNewsSubcategories.push(promise);
                    }
                    if (categoryUtils.isChildOf(CATEGORY_NAMES.CUSTOM_NEWS, category.name)) {
                        reduxStore.dispatch(
                            searchResultsActions.updateCategoryProperty(CATEGORY_NAMES.CUSTOM_NEWS, LOADED, false)
                        );
                        customNewsSubCategories.push(promise);
                    }

                    allCategories.push(promise);
                }
            });

        let that = this;

        Promise.all(customNewsSubCategories).then((customNewsResponses) => {
            let count = 0;
            customNewsResponses.forEach(({ category }) => {
                count = category.count + count;
            });
            SearchUtils.updateParentCategoryCount(customNews, count);
        });

        Promise.all(legalSubcategories).then((legalResponses) => {
            let count = 0;
            legalResponses.forEach(({ category }) => {
                count = category.count + count;
            });
            SearchUtils.updateParentCategoryCount(lawSources, count);
        });

        Promise.all(negNewsSubcategories).then((negNewsResponses) => {
            let count = 0;
            negNewsResponses.forEach(({ category }) => {
                count = category.count + count;
            });
            SearchUtils.updateParentCategoryCount(negativeNews, count);
        });

        return Promise.all(allCategories).then((categories) => {
            //update search status = complete
            reduxStore.dispatch(toggleStatus(true));
            let promiseWasCanceled = false;

            categories.forEach((category) => {
                if (category.errorMessage === PROMISE_CANCELED) {
                    promiseWasCanceled = true;
                }
            });

            // useNewResearchSummary is undefined when running search from history
            if (!promiseWasCanceled && !useNewResearchSummary) {
                that.updateResearchSummary();
            }
            return categories;
        });
    },

    loadMoreDocumentsForAvailableCategories(
        categoryName,
        selectedPage,
        ignoreAllChecked = false,
        useNewResearchSummary = false
    ) {
        let categoriesResults = reduxStore.getState().searchResults;

        reduxStore.dispatch(searchResultsActions.updateCategoryProperty(categoryName, LOADED, false));
        let category = Object.assign({}, categoriesResults[categoryName]);
        category.currentPageIndex = selectedPage !== undefined ? selectedPage : category.currentPageIndex + 1;
        category.pageSize = reduxStore.getState().user.preferences.generalSettings.pageSize || 50;
        category.isCustomFuzzy = reduxStore.getState().searchParams.isCustomFuzzy;
        const isSearchFromScreeningPage =
            reduxStore.getState().searchState.launchedFrom === LAUNCHED_SEARCH_FROM.SCREENING_PAGE;

        category.countOnly = false;

        let ts = new Date().getTime();

        return populateElasticCategory(category, ts, false, isSearchFromScreeningPage || ignoreAllChecked).then(
            (data) => {
                const { lastPreferencesUpdateInfo } = data;

                utils.shouldUpdateLastPreferencesUpdateTimestamp(lastPreferencesUpdateInfo);
                reduxStore.dispatch(searchResultsActions.updateCategory(data.category));
                reduxStore.dispatch(searchResultsActions.updateCategoryLastUpdated(data.category.name));

                // Mark category/parent category search end to gather metrics
                if (data.category.name) {
                    let categoryName = data.category.name;
                    const errorMessage = data.category.errorMessage
                        ? { error: data.category.errorMessage, statusCode: data.category.statusCode }
                        : {};

                    if (categoryUtils.isChild(categoryName)) {
                        categoryName = categoryUtils.getParent(categoryName);
                    }

                    markCategorySearchEnd(categoryName, errorMessage);
                }

                if (!useNewResearchSummary) this.updateResearchSummary();
                return data;
            }
        );
    },

    searchForCategory(categoryName, useNewResearchSummary) {
        let categoriesResults = cloneDeep(reduxStore.getState().searchResults);
        reduxStore.dispatch(searchResultsActions.updateCategoryProperty(categoryName, LOADED, false));
        let category = Object.assign({}, categoriesResults[categoryName]);
        category.currentPageIndex = 0;
        category.pageSize = reduxStore.getState().user.preferences.generalSettings.pageSize || 50;
        category.articles = [];
        category.hasError = false;
        category.isCustomFuzzy = reduxStore.getState().searchParams.isCustomFuzzy;

        let ts = new Date().getTime();

        let shouldAddFullPostFiltersList =
            category.postFilters[FILTER_INFO.SUBJECT] ||
            category.postFilters[FILTER_INFO.INDUSTRY] ||
            category.postFilters[FILTER_INFO.ESG_FACTORS];

        if (shouldAddFullPostFiltersList) {
            category.postFilters = ArticlesUtils.addFullPostFiltersList(
                category.postFilters,
                reduxStore.getState(),
                categoryName,
                true
            );
        }

        if (categoryUtils.isChild(categoryName)) {
            const parentCategory = categoryUtils.getParent(categoryName);
            reduxStore.dispatch(searchResultsActions.updateCategoryProperty(parentCategory, LOADED, false));
            return populateElasticCategory(category, ts).then((data) => {
                const { lastPreferencesUpdateInfo } = data;

                utils.shouldUpdateLastPreferencesUpdateTimestamp(lastPreferencesUpdateInfo);
                reduxStore.dispatch(searchResultsActions.updateCategory(data.category));
                let categories = reduxStore.getState().searchResults;
                let count = 0;
                Object.keys(categories).forEach((cat) => {
                    if (
                        categoryUtils.isChildOf(parentCategory, categories[cat].name) &&
                        utils.isCategoryEnabled(categories[cat].name)
                    ) {
                        count = categories[cat].count + count;
                    }
                    return count;
                });
                reduxStore.dispatch(searchResultsActions.updateCategoryProperty(parentCategory, 'count', count));
                reduxStore.dispatch(searchResultsActions.updateCategoryProperty(parentCategory, LOADED, true));
                if (!useNewResearchSummary) this.updateResearchSummary();
                return data;
            });
        } else {
            let ts = new Date().getTime();
            return populateElasticCategory(category, ts).then((data) => {
                const { lastPreferencesUpdateInfo } = data;

                utils.shouldUpdateLastPreferencesUpdateTimestamp(lastPreferencesUpdateInfo);
                reduxStore.dispatch(searchResultsActions.updateCategory(data.category));
                if (!useNewResearchSummary) this.updateResearchSummary();
                return data;
            });
        }
    },

    updateFlagForVisitedCategories(contentType) {
        reduxStore.dispatch(investigationActions.markVisitedCategories(contentType));
    },

    // compared to getResearch summary, this method keeps the existing order of the report categories and adds any new category as last in queue
    updateResearchSummary() {
        const state = reduxStore.getState();
        let { searchResults, currentReport, searchParams, user, investigation } = state;
        if (user.useNewResearchSummary) return;

        let { resultsForMainCategory, resultsForSubcategory } = utils.deconstructResultsByMainAndSubcategories(
            searchResults,
            searchParams
        );
        let visitedContentTypes = reduxStore.getState().investigation.visitedContentTypes;
        let reportContentTypes = [];

        if (currentReport.reportId) {
            user.preferences.generalSettings.contentTypes.forEach((content) => {
                if (content.value) {
                    reportContentTypes.push(content);
                }
            });
            reportContentTypes = reportContentTypes.filter(function (el) {
                return el != null;
            });

            // get the research summary of the current report and next order for categories that are to be inserted
            let newResearchSummary = currentReport.researchSummary.filter((summary) =>
                categoryUtils.shouldShowInSummary(summary.sourceType)
            );
            let order = newResearchSummary.length;

            // iterate over parent categories since only those are present in the research summary
            Object.keys(resultsForMainCategory).forEach((categoryName) => {
                // find index and get the count of the parent category in the research summary
                let index = newResearchSummary.findIndex((item) => item.sourceType === categoryName);
                let results = searchResults[categoryName].count;
                // filter enabled subcategories by current parent category
                let subcategories = utils.filterSubCategoriesByParent(resultsForSubcategory, categoryName);

                // set visited flag on true for every category the user has clicked on prior to creating the report
                let isVisited = visitedContentTypes[categoryName] || false;

                // if the category already exists in research summary
                if (index >= 0) {
                    // get configuration
                    let categoryConfig = newResearchSummary[index];

                    categoryConfig.visited = isVisited;

                    categoryConfig.postFilters = ArticlesUtils.getPostFilterInfoForResearchSummary({
                        categoryName,
                        visited: isVisited,
                        visitedContentTypes: investigation.visitedContentTypes,
                        subcategories,
                        searchResults,
                        resultsForSubcategory,
                        resultsForMainCategory,
                        state,
                    });

                    newResearchSummary[index] = {
                        ...categoryConfig,
                        results,
                    };
                    // if the category does not exists in research summary
                } else {
                    let postFilters = ArticlesUtils.getPostFilterInfoForResearchSummary({
                        categoryName,
                        visitedContentTypes: investigation.visitedContentTypes,
                        subcategories,
                        searchResults,
                        resultsForSubcategory,
                        resultsForMainCategory,
                        isVisited: isVisited,
                        state,
                    });
                    let reportContentType = reportContentTypes.find((contentType) => contentType.name === categoryName);

                    if (reportContentType) {
                        order = reportContentType.reportOrder;
                    }
                    // update research summary with the new category
                    order = newResearchSummary.push({
                        sourceType: categoryName,
                        results: results,
                        order: order,
                        visited: isVisited,
                        postFilters,
                    });
                }
            });

            // for UBO
            if (resultsForMainCategory[UBO_MAIN_CATEGORY]) {
                const uboCategorySummary = newResearchSummary.find(
                    (item) => item && item.sourceType === UBO_MAIN_CATEGORY
                );
                // clear the default postfilters
                if (uboCategorySummary) {
                    uboCategorySummary.postFilters = [];
                }
                // concatenating ubo categories postfilters
                Object.keys(resultsForMainCategory).forEach((categoryName) => {
                    if (categoryUtils.isDnbChildCategory(categoryName) && resultsForMainCategory[UBO_MAIN_CATEGORY]) {
                        const subcategorySummary = newResearchSummary.find(
                            (item) => item && item.sourceType === categoryName
                        );
                        if (subcategorySummary && subcategorySummary.postFilters) {
                            newResearchSummary.map((summary) => {
                                if (summary.sourceType === UBO_MAIN_CATEGORY) {
                                    summary.postFilters.push(...subcategorySummary.postFilters);
                                }
                                return summary;
                            });
                            /**/
                        }
                    }
                });
                // cleaning out the ubo subcategories
                Object.keys(resultsForMainCategory).forEach((categoryName) => {
                    if (categoryUtils.isDnbChildCategory(categoryName)) {
                        newResearchSummary = newResearchSummary.filter(
                            (summary) => summary.sourceType !== categoryName
                        );
                    }
                });
            }

            ReportBuilderApi.updateReportSummary(currentReport.reportId, newResearchSummary)
                .then(() => {
                    let report = {};
                    report.reportId = currentReport.reportId;
                    report.articlesSnippets = currentReport.articlesSnippets || [];
                    report.researchSummary = newResearchSummary;
                    report.visitedContentTypes = reduxStore.getState().investigation.visitedContentTypes || [];

                    reduxStore.dispatch(currentReportActions.setCurrentReport(report));
                })
                .catch(() => {
                    utils.showNotificationsMessage({
                        messageText: 'ReportBuilderPage_Errors.updateReport',
                        messageType: 'system-error',
                    });
                });
        }
    },

    disableSpecifiedCategories: (categories, categoriesToDisable) => {
        if (!Array.isArray(categories) || !Array.isArray(categoriesToDisable)) {
            return categories;
        }

        return categories.map((category) => {
            categoriesToDisable.forEach((categoryToDisable) => {
                if (category.key === categoryToDisable) {
                    category.checked = false;
                }
            });

            return category;
        });
    },

    getSearchBarSelectedCategory(state, searchType) {
        const { sources } = cloneDeep(state.searchBar.sourcesDropdown);

        if (sources.length === 0) {
            return null;
        }

        const searchBarSources =
            searchType === PERSON_SEARCH
                ? SearchUtils.disableSpecifiedCategories(sources, CATEGORIES_EXCLUDED_FROM_PERSON_SEARCH)
                : sources;
        const searchBarSelectedCategory = searchBarSources.find((item) => item.checked);

        if (!searchBarSelectedCategory) {
            return null;
        }

        if (searchBarSelectedCategory.key === CATEGORY_NAMES.DNB) {
            return CATEGORY_NAMES.DNB;
        }

        if (searchBarSelectedCategory.key === CATEGORY_NAMES.LAW_SOURCES) {
            return searchBarSources.find((item) => item.checked && categoryUtils.isLegalSource(item.key)).key;
        }

        if (categoryUtils.hasChildren(searchBarSelectedCategory.key)) {
            return categoryUtils.getCategoryKeys(searchBarSelectedCategory.key).find((subcategory) => {
                if (categoryUtils.shouldDisplayForState(subcategory, state)) {
                    return subcategory;
                }
            });
        }

        return searchBarSelectedCategory.key;
    },

    getSearchDefaultCategory: (contentTypes, searchType) => {
        const state = reduxStore.getState();
        const searchBarSelectedCategory = SearchUtils.getSearchBarSelectedCategory(state, searchType);

        if (searchBarSelectedCategory) {
            return searchBarSelectedCategory;
        }

        return contentTypes
            .filter((type) => type.value)
            .map((type) => {
                let category = type.name;
                if (categoryUtils.hasChildren(category)) {
                    return categoryUtils.getCategoryKeys(category).find((subcategory) => {
                        if (categoryUtils.shouldDisplayForState(subcategory, state)) {
                            return subcategory;
                        }
                    });
                }
                return category;
            })
            .find((category) => {
                if (
                    searchType === COMPANY_SEARCH ||
                    (searchType === PERSON_SEARCH &&
                        CATEGORIES_EXCLUDED_FROM_PERSON_SEARCH.every((value) => category !== value))
                ) {
                    return category;
                }
            });
    },

    getPostfilterSelection(postFilterConf, ignoreAllChecked = false) {
        let selectedValues;
        let { componentType, values, type } = postFilterConf;

        switch (componentType) {
            case POSTFILTER_COMPONENT_TYPE.GEOGRAPHY:
            case POSTFILTER_COMPONENT_TYPE.NESTED_CHECKBOX_LIST:
                if (!ignoreAllChecked && utils.areAllChecked(values)) {
                    selectedValues = null;
                } else {
                    const tree = utils.convertFlatOptions(values);
                    selectedValues = utils.flattenTreeValues(tree, false);
                    selectedValues = selectedValues
                        .filter((value) => value.checked)
                        .map((value) => {
                            if (utils.isPostFilterCustomType(type)) {
                                return value.labelId ? value.labelId : value.label;
                            } else {
                                return value.label;
                            }
                        });
                }
                break;
            case POSTFILTER_COMPONENT_TYPE.CHECKBOX_LIST:
                if (
                    !ignoreAllChecked &&
                    utils.areAllChecked(values) &&
                    type !== POSTFILTER_TYPE.SIMILAR_NAMES &&
                    type !== POSTFILTER_TYPE.SUGGESTED_NAMES
                ) {
                    selectedValues = null;
                } else {
                    selectedValues = values;
                    selectedValues = selectedValues.filter((value) => value.checked).map((value) => value.label);
                }
                break;
            case POSTFILTER_COMPONENT_TYPE.RADIO_LIST:
            case POSTFILTER_COMPONENT_TYPE.DATE_RADIO_LIST:
                const checkedOption = values.find((option) => option.checked);
                // the key was supposed to be "value", but playing along with "count" now, too late to change
                selectedValues = checkedOption && checkedOption[ITEM_VALUE_FIELD];
                break;
            case POSTFILTER_COMPONENT_TYPE.TEXT_FILTER:
                selectedValues = values[0];
                break;
            default:
                selectedValues = values;
        }
        // in the case of proximity, the value is the "count" property
        // TODO: but count is for something else, this value should be kept in a "value" property
        if (type === POSTFILTER_TYPE.PROXIMITY_NEGATIVE_TERMS || type === POSTFILTER_TYPE.FUZZY_SEARCH) {
            const checkedOption = values.find((option) => option.checked);
            selectedValues = checkedOption && checkedOption[ITEM_VALUE_FIELD];
        }

        if (type === POSTFILTER_TYPE.DOCKET_STATUS) {
            // Need to send a string, instead of sending an array
            selectedValues = selectedValues ? selectedValues[0] : selectedValues;
        }

        return selectedValues;
    },

    getFilteredPostfilters(postFilterConf, values, ignoreAllChecked = false) {
        if (postFilterConf) {
            const postFilterConfValues = SearchUtils.getPostfilterSelection(postFilterConf, ignoreAllChecked);
            if (Array.isArray(values)) {
                return intersection(values, postFilterConfValues);
            } else {
                return postFilterConfValues;
            }
        } else {
            return values;
        }
    },

    updateDefaultCategoryVisitedFlag(searchType: string) {
        const contentTypes = reduxStore.getState().user.preferences.generalSettings.contentTypes;
        const contentType = SearchUtils.getSearchDefaultCategory(contentTypes, searchType);
        SearchUtils.updateFlagForVisitedCategories(contentType);
    },

    updateFilterData(category, searchFieldName = null) {
        const payload = buildFilterInfoPayload({ category, searchFieldName });
        const ts = Date.now();

        if (searchFieldName) {
            reduxStore.dispatch(searchResultsActions.updateCategoryProperty(category.name, LOADED, false));
        }

        return MainSearchApi.getFilterInfo(payload, ts)
            .then((response) => {
                matchResponseWithSubcategory(payload);
                updatePostFilterConfig(payload, response);
                reduxStore.dispatch(searchResultsActions.updateCategoryLastUpdated(category.name));

                if (searchFieldName) {
                    reduxStore.dispatch(postFilterConfigActions.markFullTreeLoaded(category.name, searchFieldName));
                    reduxStore.dispatch(searchResultsActions.updateCategoryProperty(category.name, LOADED, true));
                }
            })
            .catch((error) => {
                setFailedCategory(category, error);
                updatePostFilterConfig(payload);
            });
    },

    isPostFilterNested(postFilter) {
        return POST_FILTERS_WITH_PATH.indexOf(postFilter) > -1;
    },

    addExcludeNewsTogglesToPayload(defaultPostFilters, state) {
        if (state.user && state.user.newsExcludeTogglesEnabled) {
            const excludeNonBusinessNewsToggle =
                state.user.preferences.generalSettings[POST_FILTER_EXCLUDE_NON_BUSINESS_NEWS];
            const excludeNewsWiresToggle = state.user.preferences.generalSettings[POST_FILTER_EXCLUDE_NEWS_WIRES];

            defaultPostFilters[POST_FILTER_EXCLUDE_NON_BUSINESS_NEWS] =
                excludeNonBusinessNewsToggle !== undefined
                    ? excludeNonBusinessNewsToggle
                    : DEFAULT_EXCLUDE_NEWS_TOGGLE_VALUES[POST_FILTER_EXCLUDE_NON_BUSINESS_NEWS];

            defaultPostFilters[POST_FILTER_EXCLUDE_NEWS_WIRES] =
                excludeNewsWiresToggle !== undefined
                    ? excludeNewsWiresToggle
                    : DEFAULT_EXCLUDE_NEWS_TOGGLE_VALUES[POST_FILTER_EXCLUDE_NEWS_WIRES];
        }

        return defaultPostFilters;
    },

    saveSearch(entityId, requestPayload, finishCallback) {
        reduxStore.dispatch(searchStatusActions.setIsSavingSearch(true));
        EntityViewApi.updateBatchEntities(entityId, requestPayload)
            .then(() => {
                reduxStore.dispatch(searchStatusActions.setIsSavingSearch(false));
                finishCallback && finishCallback();
            })
            .catch(() => {
                reduxStore.dispatch(searchStatusActions.setIsSavingSearch(false));
                utils.showNotificationsMessage({
                    messageText: 'General_CoreFunctionality_Error_error.500Title',
                    messageType: 'system-error',
                });
            });
    },

    async createEntityFromSearch(payload) {
        reduxStore.dispatch(searchStatusActions.setIsAddingToEntityView(true));

        const [data, error] = await EntityViewApi.createFromSearch(payload);

        if (error) {
            reduxStore.dispatch(searchStatusActions.setIsAddingToEntityView(false));
            utils.showNotificationsMessage({
                messageText: 'General_CoreFunctionality_Error_error.500Title',
                messageType: 'system-error',
            });
        } else {
            //set the initial data for the copy notification/snackbar
            const uploadNotificationData = snackbarUtils.getProcessingStatusInitialData({
                actionType: ACTION_TYPES.UPLOAD,
                totalCount: 1,
            });

            const batchId = data.batchId;
            
            reduxStore.dispatch(mainActions.addNewBatchId({batchId, data: uploadNotificationData, keyInTheStore: NOTIFICATION_STORE_KEYS.UPLOAD}));
            reduxStore.dispatch(searchStatusActions.setIsAddingToEntityView(false));

            hashHistory.push(ROUTES.SCREENING);
        }
    },

    async createEntitiesFromAlerts(payload) {
        reduxStore.dispatch(searchStatusActions.setIsAddingToEntityView(true));

        const [data, error] = await EntityViewApi.createFromAlerts(payload);

        if (error) {
            reduxStore.dispatch(searchStatusActions.setIsAddingToEntityView(false));
            utils.showNotificationsMessage({
                messageText: 'General_CoreFunctionality_Error_error.500Title',
                messageType: 'system-error',
            });
        } else {
            const batchId = data.batchId;
            //set the initial data for the copy notification/snackbar
            const uploadNotificationData = snackbarUtils.getProcessingStatusInitialData({
                actionType: ACTION_TYPES.UPLOAD,
                totalCount: payload.length,
            });
            
            reduxStore.dispatch(mainActions.addNewBatchId({batchId, data: uploadNotificationData, keyInTheStore: NOTIFICATION_STORE_KEYS.UPLOAD}));
            hashHistory.push(ROUTES.SCREENING);
            notificationService.pollUploadEntityView(
                {
                    batchId,
                    route: ROUTES.SCREENING,
                    onFinished: () => {
                        reduxStore.dispatch(searchStatusActions.setIsAddingToEntityView(false));
                        notificationService.pollEntitiesCounts({ unreadyEntityIds: payload.length })
                        notificationService.pollInProgressEntitiesData({});
                    },
                },
                true
            );
        }
    },

    // used for creating the payload for 'Add to Entity View' from Snapshot/Results List
    buildPayloadForCreatingEntity(data) {
        const {
            user,
            investigation,
            useNewResearchSummary,
            contentTypes,
            searchResults,
            postFiltersConfig,
            searchParams,
        } = data;

        const { query, searchType, prefilterQuery, isCustomFuzzy } = searchParams;

        const displayName = !prefilterQuery.length ? query : `${query} ${prefilterQuery}`;

        // compute data for the payload
        const {
            timezone,
            preferences: { language, startEachArticleOnNewPage },
        } = user;
        const categoryOrder = useNewResearchSummary ? getCategoryOrder(contentTypes) : undefined;
        const costCode = costCodeUtils.getCostCode();

        //compute searchEntity object
        const searchTerms = getAllTerms(query)
            .map(sanitizeTerm)
            .filter((term) => term.length);
        const currentDate = utils.getCurrentTime();
        const batchFileName: string =
            DEFAULT_FILENAME +
            utils.formatReportFileName(query + '_') +
            utils.formatReportDateWithtTimezoneOffset(currentDate);

        // compute postfilters list
        const categorySources = Object.keys(searchResults).filter(
            (category) =>
                searchResults[category].enabled &&
                category !== CATEGORY_NAMES.PUBLIC_RECORDS &&
                !categoryUtils.hasChildren(category)
        );

        const postFilters = utils.getPostFiltersFromSearch(
            categorySources,
            searchResults,
            postFiltersConfig,
            searchParams
        );

        postFilters.forEach((postFilter) => {
            if (categoryUtils.isChildOf(CATEGORY_NAMES.DNB, postFilter.category)) {
                postFilter.category = CATEGORY_NAMES.DNB;
            }
        });

        return {
            batchFileName,
            billingId: investigation.billingId,
            costCode,
            timezone,
            language,
            startEachArticleOnNewPage,
            categoryOrder,
            screeningEntityData: {
                searchEntity: {
                    searchQuery: query,
                    searchQueryType: searchType,
                    prefilterQuery: utils.removeFirstBooleanOperator(prefilterQuery),
                    isCustomFuzzy,
                    searchTerms,
                },
                displayName,
            },
            postFilters,
            //dateRange and sort are not used by BE in this case
        };
    },

    // in the case of updating the results for an entity already created, this should be sent as an object to the endpoint
    computePayload(data) {
        const {
            user,
            investigation,
            useNewResearchSummary,
            contentTypes,
            searchResults,
            postFiltersConfig,
            searchParams,
            popupModel,
            viewId
        } = data;

        const { query, searchType, prefilterQuery } = searchParams;

        const displayName = !prefilterQuery.length ? query : `${query} ${prefilterQuery}`;

        // compute data for the payload
        const {
            timezone,
            preferences: { language, startEachArticleOnNewPage },
        } = user;
        const categoryOrder = useNewResearchSummary ? getCategoryOrder(contentTypes) : undefined;
        const costCode = costCodeUtils.getCostCode();

        //compute searchEntity object
        const searchTerms = getAllTerms(query)
            .map(sanitizeTerm)
            .filter((term) => term.length);
        const currentDate = utils.getCurrentTime();
        const fileName: string =
            DEFAULT_FILENAME +
            utils.formatReportFileName(query + '_') +
            utils.formatReportDateWithtTimezoneOffset(currentDate);

        // compute postfilters list
        const categorySources = Object.keys(searchResults).filter(
            (category) =>
                searchResults[category].enabled &&
                category !== CATEGORY_NAMES.PUBLIC_RECORDS &&
                !categoryUtils.hasChildren(category)
        );

        const postFilters = utils.getPostFiltersFromSearch(
            categorySources,
            searchResults,
            postFiltersConfig,
            searchParams
        );

        postFilters.forEach((postFilter) => {
            if (categoryUtils.isChildOf(CATEGORY_NAMES.DNB, postFilter.category)) {
                postFilter.category = CATEGORY_NAMES.DNB;
            }
        });

        return {
            billingId: investigation.billingId,
            costCode,
            timezone,
            language,
            startEachArticleOnNewPage,
            categoryOrder,
            searchEntity: {
                searchQuery: query,
                searchQueryType: searchType,
                prefilterQuery: utils.removeFirstBooleanOperator(prefilterQuery),
                searchTerms,
                downloadInfo: {
                    fileType: popupModel?.docType,
                    fileName,
                },
                alertData: {},
            },
            displayName,
            postFilters,
            viewId
        };
    },
    filterPostfiltersToCategoryFromPayload(category, payload) {
        if (!category) return payload;
        const updatedPayload = cloneDeep(payload);
        updatedPayload.postFilters = [
            ...updatedPayload.postFilters.filter((categoryPostfilter) => categoryPostfilter.category === category),
        ];
        return updatedPayload;
    },
    getPostFiltersForEntity: async (id, viewId) => {
        try {
            let results = await MainSearchApi.getPostFiltersAndCounts(id, viewId);
            results['searchQueryType'] = QUERY_SEARCH_TYPES[results['searchQueryType']];
            return results;
        } catch (e) {
            console.error(e);
            return { searchQueryType: '', postFilters: [], counts: null, negativity: null };
        }
    },
    updateEntityCountsAndPostFilters: (counts, esg, postFilters, searchQuery, searchQueryType, redirectToResultList) => {
        const { searchResults, postFilterConfiguration, user: { preferences: { generalSettings: showSnapshot } } } = reduxStore.getState();
        let esgRatings = {};
        const updatedResults = {};
        const categoriesWithCounts = Object.keys(counts);

        for (const key of categoriesWithCounts) {
            const categoryCount = counts[key] ?? 0;
            const config = postFilterConfiguration[key];

            const categoryPostFilters =
                postFilters.find(
                    ({ category }) => category === key || category === uboCategoryNameFormat({ term: searchQuery })
                ) || {};

            const postFilter = {
                ...categoryPostFilters,
                searchQuery,
                searchQueryType,
            };

            if (esg && key === 'esgRatings') {
                const { lni, overallRating, range, entity: articles } = esg;
                esgRatings = { lni, overallRating, range, articles };
            }

            // when triggering a search from Entity View, we don't make any calls to get the counts, since we already have them from the Entity View page
            // because of this, we need to make the sanctions call here to get the data for the table for the new S&W pod
            key === 'sanctions' && showSnapshot && !redirectToResultList && getSanctionsTableData(categoryPostFilters)

            updatedResults[key] = {
                ...searchResults[key],
                count: categoryCount,
                loaded: true,
                postFilters: postFilter,
                filterInfoParams: getFilterInfoParams(config) || [],
                ...(key === 'esgRatings' && esgRatings),
            };
        }

        const combinedResults = {
            ...searchResults,
            ...updatedResults,
        };
        reduxStore.dispatch(searchResultsActions.setSearchResults(combinedResults));
        return categoriesWithCounts;
    },
    updateEntityNegativity: (negativityRisk) => {
        reduxStore.dispatch(negativeNewsVisualisationActions.updateNegativityPodRisk(negativityRisk));
    },
    resetPostFiltersConfiguration: (searchQueryType) => {
        // TODO: resetAllPostFiltersConfigValues is used quite often elsewhere, it should be refactored to use this function.
        const state = reduxStore.getState();
        const subjectAndIndustryAvailable = state.user.subjectAndIndustryAvailable;
        const newFuzzyEnabled = isNewFuzzyEnabled(state);
        const suggestedNamesEnabled = isSuggestedNamesEnabled(state);
        reduxStore.dispatch(
            postFilterConfigActions.resetAllPostFiltersConfigValues(
                searchQueryType,
                subjectAndIndustryAvailable,
                newFuzzyEnabled,
                suggestedNamesEnabled
            )
        );
    },
    generateShardPreference(userId) {
        return Date.now() + userId;
    },
};

export const retrievePostFilterData = (
    postFilterConfig: Object,
    categoryPostfilters: PostFiltersType,
    categoryName: string,
    searchQueryType: string
) => {
    let postFilterData = [];
    if (postFilterConfig && !isEmpty(postFilterConfig)) {
        Object.keys(postFilterConfig).forEach((key) => {
            let config = postFilterConfig[key];
            let data = [];

            switch (config.type) {
                case POSTFILTER_TYPE.SOURCE:
                    if (categoryPostfilters[FILTER_INFO.CONTENT_TYPE].length !== 0) {
                        categoryPostfilters[FILTER_INFO.CONTENT_TYPE].forEach((postFilter) => {
                            data.push({ label: postFilter, checked: true });
                        });
                    } else if (categoryPostfilters[FILTER_INFO.PUBLICATION_NAME].length !== 0) {
                        categoryPostfilters[FILTER_INFO.PUBLICATION_NAME].forEach((postFilter) => {
                            data.push({ label: postFilter, checked: true });
                        });
                    } else if (categoryPostfilters[FILTER_INFO.SOURCE_NAME].length !== 0) {
                        categoryPostfilters[FILTER_INFO.SOURCE_NAME].forEach((postFilter) => {
                            data.push({ label: postFilter, checked: true });
                        });
                    }
                    break;

                case POSTFILTER_TYPE.SOURCE_NAME:
                case POSTFILTER_TYPE.SOURCE_TYPE:
                    if (categoryPostfilters[FILTER_INFO.SOURCE_NAME]) {
                        categoryPostfilters[FILTER_INFO.SOURCE_NAME].forEach((postFilter) => {
                            data.push({ label: postFilter, checked: true });
                        });
                    }
                    if (categoryPostfilters[FILTER_INFO.PUBLICATION_TYPE]) {
                        categoryPostfilters[FILTER_INFO.PUBLICATION_TYPE].forEach((postFilter) => {
                            data.push({ label: postFilter, checked: true });
                        });
                    }
                    break;

                case POSTFILTER_TYPE.COMPANY:
                    if (categoryPostfilters[FILTER_INFO.COMPANY]) {
                        categoryPostfilters[FILTER_INFO.COMPANY].forEach((postFilter) => {
                            data.push({ label: postFilter, checked: true });
                        });
                    }
                    break;

                case POSTFILTER_TYPE.LANGUAGE:
                    if (categoryPostfilters[FILTER_INFO.LANGUAGE]) {
                        categoryPostfilters[FILTER_INFO.LANGUAGE].forEach((postFilter) => {
                            data.push({ label: postFilter, checked: true });
                        });
                    }
                    break;

                case POSTFILTER_TYPE.PROXIMITY_NEGATIVE_TERMS:
                    let { proximities } = reduxStore.getState().user.preferences.generalSettings;
                    data = getProximityTerms(proximities, categoryPostfilters[FILTER_INFO.PROXIMITY], null);
                    break;

                case POSTFILTER_TYPE.DATE_RANGE:
                    let userPreferences = reduxStore.getState().user.preferences;
                    data = setDateRange(
                        categoryName,
                        userPreferences,
                        config.values,
                        categoryPostfilters[FILTER_INFO.DATE_RANGE]
                    );
                    data.find((value) => {
                        if (value.count === categoryPostfilters[FILTER_INFO.DATE_RANGE]) {
                            value.checked = true;
                        } else if (
                            value.count === RANGE_TYPE_CUSTOM &&
                            categoryPostfilters[FILTER_INFO.DATE_RANGE] &&
                            utils.getDateFromString(
                                categoryPostfilters[FILTER_INFO.DATE_RANGE].split(';')[0],
                                CALENDAR_DATE_FORMAT_BE
                            )
                        ) {
                            value.checked = true;
                            value.count = categoryPostfilters[FILTER_INFO.DATE_RANGE];
                        } else {
                            value.checked = false;
                        }
                    });
                    break;

                case POSTFILTER_TYPE.SIMILAR_NAMES:
                    if (categoryPostfilters.fuzzyNames && categoryPostfilters.fuzzyNames.list) {
                        let fuzzyNames = [];
                        categoryPostfilters.fuzzyNames.list.forEach((fuzzy) => {
                            fuzzyNames.push(fuzzy.name);
                        });
                        data = formatFuzzyNamesForPostFilters(fuzzyNames, config.values);
                    }
                    break;

                case POSTFILTER_TYPE.SUGGESTED_NAMES:
                    if (categoryPostfilters.suggestedNames && categoryPostfilters.suggestedNames.list) {
                        let suggestedNames = [];
                        categoryPostfilters.suggestedNames.list.forEach((suggested) => {
                            suggestedNames.push(suggested.name);
                        });
                        data = formatFuzzyNamesForPostFilters(suggestedNames, config.values);
                    }
                    break;

                case POSTFILTER_TYPE.GEOGRAPHY:
                    if (categoryPostfilters[FILTER_INFO.GEOGRAPHY]) {
                        categoryPostfilters[FILTER_INFO.GEOGRAPHY].forEach((postFilter) => {
                            data.push({ label: postFilter, checked: true });
                        });
                    }
                    break;

                case POSTFILTER_TYPE.COMPANY_MENTIONS:
                    if (
                        searchQueryType === COMPANY_SEARCH &&
                        categoryPostfilters.companyMentions !== null &&
                        categoryPostfilters.companyMentions !== undefined
                    ) {
                        data = getCompanyMentioned(null, categoryPostfilters.companyMentions);
                    }
                    break;

                case POSTFILTER_TYPE.PERSON_MENTIONS:
                    if (
                        searchQueryType === PERSON_SEARCH &&
                        categoryPostfilters.personMentions !== null &&
                        categoryPostfilters.personMentions !== undefined
                    ) {
                        data = getPersonMentions(null, categoryPostfilters.personMentions);
                    }
                    break;

                case POSTFILTER_TYPE.DOCKET_STATUS:
                    if (categoryPostfilters[FILTER_INFO.DOCKET_STATUS]) {
                        let selectedValues = [];

                        if (!Array.isArray(categoryPostfilters[FILTER_INFO.DOCKET_STATUS])) {
                            selectedValues.push(categoryPostfilters[FILTER_INFO.DOCKET_STATUS]);
                        } else {
                            selectedValues = categoryPostfilters[FILTER_INFO.DOCKET_STATUS];
                        }

                        data = getDocketStatusValues(selectedValues, null);
                    }
                    break;
                case POSTFILTER_TYPE.DUNS_FILTER:
                    if (categoryPostfilters[FILTER_INFO.DUNS_FILTER]) {
                        data = [categoryPostfilters[FILTER_INFO.DUNS_FILTER]];
                    }
                    break;

                case POSTFILTER_TYPE.SUBJECT:
                    if (categoryPostfilters[FILTER_INFO.SUBJECT]) {
                        categoryPostfilters[FILTER_INFO.SUBJECT].forEach((postFilter) => {
                            data.push({ label: postFilter, checked: true });
                        });
                    }
                    break;
                case POSTFILTER_TYPE.INDUSTRY:
                    if (categoryPostfilters[FILTER_INFO.INDUSTRY]) {
                        categoryPostfilters[FILTER_INFO.INDUSTRY].forEach((postFilter) => {
                            data.push({ label: postFilter, checked: true });
                        });
                    }
                    break;

                case POSTFILTER_TYPE.FUZZY_SEARCH:
                    let fuzzyOn = !!categoryPostfilters[POST_FILTER_FUZZY_THRESHOLD];
                    data = getFuzzySearch(fuzzyOn, config.values);
                    break;

                case POSTFILTER_TYPE.NEGATIVITY_LEVELS:
                    const levels = categoryPostfilters.negativityLevels;

                    data = getNegativityLevels(levels, config.values);
                    break;

                case POSTFILTER_TYPE.ESG_FACTORS:
                    if (categoryPostfilters[FILTER_INFO.ESG_FACTORS]) {
                        categoryPostfilters[FILTER_INFO.ESG_FACTORS].forEach((postFilter) => {
                            data.push({ label: postFilter, checked: true });
                        });
                    }
                    break;

                default:
                    console.warn(`${config.type} post-filter not handled for history search`);
                    break;
            }
            postFilterData.push({
                categoryName: categoryName,
                postFilterType: config.type,
                values: data,
                enabled: true,
            });
        });
    }
    return postFilterData;
};

const setDateRange = (categoryName, userPreferences, postFilterConfigValues, selectedValue) => {
    let selected = false;
    let dateRange;

    let values = userPreferences.generalSettings.dateRanges.map((date) => {
        selected = getDateValues(date, selectedValue).selected;
        dateRange = getDateValues(date, selectedValue).dateRange;
        return [utils.dateRangeToText(date), dateRange || date, selected];
    });

    values = cloneDeep(formatPostFilterData(values, POST_FILTER_DATE_RANGE));
    return values;
};

export const determineDateRange = (adHocPreferences) => {
    if (adHocPreferences && Object.keys(adHocPreferences).length === 0 && adHocPreferences.constructor === Object) {
        return 'all';
    }

    if (!(adHocPreferences.newsDateRange || adHocPreferences.companyDateRange || adHocPreferences.legalDateRange)) {
        return 'all';
    }

    const { newsDateRange, companyDateRange, legalDateRange } = adHocPreferences;

    // if all dateRanges are the same it means that "all categories" date filter dropdown was used
    // OR all 3 dropdowns (news, company & legal) were used, but they have the same value
    const areAllRangesEqual =
        newsDateRange?.range === companyDateRange?.range &&
        newsDateRange?.range === legalDateRange?.range &&
        legalDateRange?.range === companyDateRange?.range;

    return areAllRangesEqual
        ? newsDateRange.range // pick whichever date range since they are the same
        : 'all';
};

export default SearchUtils;
