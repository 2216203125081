// @flow
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { hashHistory, withRouter } from 'react-router';
import {
    CATEGORY_NAMES,
    COMPANY_SEARCH,
    EDIT_SEARCH_URL,
    USER_PREFERENCES,
    GENERAL_SETTINGS,
    PERSON_SEARCH,
} from '@constants';
import formatRichMessage from '@utils/formatRichMessage';
import searchUtils, { updateUserPreferencesContentTypesFromDropdown } from '@pages/MainSearch/SearchUtils';
import SearchBox from '../SearchBox/SearchBox';
import utils from '@utils/utilities';
import costCodeUtils from '@utils/costCodeUtils';
import { UBO_URL_PARAM } from '@constants';
import { SmallLoader } from '../Table/components/defaults';
import { encodeUboUrlParam } from '@sagas/helpers/uboHelper';
import { bindActionCreators, compose } from 'redux';
import searchBar from './redux/SearchBar.actions';
import { connect } from 'react-redux';
import { withAppContext } from '@utils/contexts';
import adHocSearchActions from '../AdHocSearch/redux/AdHocSearch.actions';
import ModalUpdateCheckbox from '../PopupModal/Components/ModalUpdateCheckbox';
import actions from '@pages/UserPreferances/redux/UserPreferences.actions';
import SourcesDropdownContent from './SourcesDropdownContent';
import type {
    FuzzyNames,
    CustomNewsQuery,
    SearchParams,
    Sources,
    UserPreferencesContentTypes,
    SearchBarDefaultProps,
} from './redux/flow/SearchBar.type.guards';
import DateRangesDataWrapper from './components/DateRange/DateRangesDataWrapper';
import type { DateRanges } from './components/DateRange/flow/Dates.typeGuards';
import type { AdHocSearchType } from '../AdHocSearch/flow/AdHocSearch.typeGuards';
import type { SuggestedNamesType } from '../SearchBarHeader/flow/SearchBarHeader.typeGuards';
import type { UserOfferings } from './flow/SearchBar.typeGuards';
import SuggestedNamesUtils from '../SuggestedNames/SuggestedNamesUtils';
import userPreferencesUtils from '@utils/userPreferencesUtils';

type State = {
    isUserPreferencesChecked: boolean,
    isSourcesDropdownChanged: boolean,
    hasInsufficientCharacters: boolean,
    selectedSources: number,
};

type Props = {
    location: Object,
    resetQuery: () => void,
    searchParams: SearchParams,
    fuzzyNames: FuzzyNames,
    userPreferencesSearchType: string,
    changeQuery: (query: string, extra: Object) => void,
    setBooleanTerms: (prefilterQuery: string) => void,
    isFuzzyEnabled: boolean,
    adHocSearch: AdHocSearchType,
    selectedCostCode: string,
    isCostCodeRequired: boolean,
    isSnapshotVisible: boolean,
    chooseOnlyFromCostCodesList: boolean,
    costCodes: Array<string>,
    contentTypes: Array<UserPreferencesContentTypes>,
    uboSelected: Array<?string>,
    searchBarContentTypes?: Array<Sources>,
    setInvestigationId: (investigationId: string) => void,
    isLoading: boolean,
    onSubmit: Object,
    searchType: string,
    onChangeQuery: Object,
    selectedSources: number,
    isListVisible: boolean,
    updateAdHocSources: (sources?: Array<Sources>) => void,
    lawSources: Array<string>,
    adHocSearchSources: Array<Sources>,
    updateSourcesDropdownContentTypes: (sources?: Array<Sources>) => void,
    userLocks: Object,
    adminPersonCustomNewsQuery: Array<CustomNewsQuery>,
    adminCompanyCustomNewsQuery: Array<CustomNewsQuery>,
    personCustomNewsQuery: Array<CustomNewsQuery>,
    companyCustomNewsQuery: Array<CustomNewsQuery>,
    lnPersonCustomNewsQuery: Array<CustomNewsQuery>,
    lnCompanyCustomNewsQuery: Array<CustomNewsQuery>,
    shouldShowPreferencesCheckbox: boolean,
    dateRanges: DateRanges,
    newsDateRange: string,
    legalDateRange: string,
    companyDateRange: string,
    updateAdHocProperty: (property: string, value: any) => void,
    suggestedNamesEnabled: boolean,
    suggestedNames: SuggestedNamesType,
    resetSuggestedNames: () => void,
    isSuggestedNamesPreferenceEnabled: boolean,
    loadedSuggestionsForTerms: Array<{ isLoaded: boolean }>,
    newFuzzyEnabled: boolean,
    refreshPreferencesIfNeeded: () => void,
    userOfferings: Array<UserOfferings> | [],
    updatePreferencesDateRanges: () => void,
    setBillingId: (billingId: string) => void,
};

type InjectedProps = {|
    +intl: Object,
    handleAutosave: (handler: (params: Object) => void, params: Object) => void,
|};

class SearchBar extends React.Component<Props & InjectedProps, State> {
    static defaultProps: SearchBarDefaultProps = {
        uboSelected: [],
        isLoading: false,
    };

    state: State;

    constructor(props: Props & InjectedProps) {
        super(props);
        this.state = {
            isUserPreferencesChecked: false,
            isSourcesDropdownChanged: false,
            hasInsufficientCharacters: false,
            selectedSources: 0,
        };
    }

    componentDidMount() {
        if (!this.props.location || this.props.location.pathname !== EDIT_SEARCH_URL) {
            this.props.resetQuery();
        }
    }

    handleSearchEvent: (e: SyntheticEvent<HTMLInputElement>, newQuery: string) => void = (e, newQuery) => {
        e.persist();

        let localPrefilterQuery: string = '';
        if (newQuery) {
            //this is a search from click
            localPrefilterQuery = utils.extractQueryFromBooleanTerms(newQuery).prefilterQuery;
            newQuery = utils.extractQueryFromBooleanTerms(newQuery).query;
        } else {
            localPrefilterQuery = this.props.searchParams.prefilterQuery;
            newQuery = this.props.searchParams.query;
        }

        if (newQuery && newQuery.length) {
            const isValidQuery = utils.isValidQuery(newQuery);

            if (!isValidQuery) {
                this.setState({
                    hasInsufficientCharacters: !isValidQuery,
                });

                return;
            }
        }

        // save searchType to preferences if the user changed selection and performed a search
        if (this.props.searchParams.searchType !== this.props.userPreferencesSearchType) {
            userPreferencesUtils.saveSearchTypeToPreferences(this.props.searchParams.searchType);
        }

        this.doSearch(utils.sanitizeSearchStringInput(newQuery), localPrefilterQuery, this.props.searchParams.searchType);
    };

    resetQuery: () => void = () => {
        this.props.resetQuery();

        if (
            this.props.suggestedNamesEnabled &&
            this.props.searchParams.searchType === PERSON_SEARCH &&
            this.props.suggestedNames
        ) {
            this.props.resetSuggestedNames();
        }
    };

    queryChanged: (newQuery: string, cursorPosition: number) => void = (newQuery, cursorPosition) => {
        const extra = {
            query: newQuery,
            cursorPosition,
        };

        if (newQuery.length) {
            this.setState({
                hasInsufficientCharacters: !utils.isValidQuery(newQuery),
            });
        }

        let parsedQuery: { query: string, prefilterQuery: string, message: string } =
            utils.extractQueryFromBooleanTerms(utils.sanitiseQuery(newQuery));

        let { query, prefilterQuery } = parsedQuery;

        if (query !== '') {
            this.props.changeQuery(utils.sanitiseQuery(query), extra);

            if (prefilterQuery !== this.props.searchParams.prefilterQuery) {
                this.props.setBooleanTerms(prefilterQuery);
            }

            if (
                this.props.suggestedNamesEnabled &&
                this.props.isSuggestedNamesPreferenceEnabled &&
                this.props.searchParams.searchType === PERSON_SEARCH &&
                this.props.suggestedNames &&
                this.props.suggestedNames.query !== query
            ) {
                SuggestedNamesUtils.getSuggestedNames(
                    query,
                    this.props.suggestedNames,
                    this.props.loadedSuggestionsForTerms
                );
            }

            if (this.props.fuzzyNames && this.props.fuzzyNames.query !== query) {
                const isFuzzyEnabled = !this.props.newFuzzyEnabled;

                searchUtils.doFuzzySearch(query, this.props.searchParams.searchType, isFuzzyEnabled);
            }
        } else {
            if (this.props.suggestedNamesEnabled && this.props.suggestedNames) {
                this.props.resetSuggestedNames();
            }
        }
    };

    validateSearchParams: (query: string, prefilterQuery: string, searchType: string) => boolean = (
        query,
        prefilterQuery,
        searchType
    ) => {
        let areAllParamsValid: boolean = true;
        let costCode: string = this.props.adHocSearch.costCode || this.props.selectedCostCode;

        if (utils.hasMismatchedQuotesNumber(query) || utils.hasMismatchedQuotesNumber(prefilterQuery)) {
            utils.showNotificationsMessage({
                messageText: 'SearchResults_Notifications.mismatchedQuotationMarks',
                messageType: 'user-error',
            });
            areAllParamsValid = false;
        }

        if (this.props.isCostCodeRequired && !costCode) {
            utils.showNotificationsMessage({
                messageText: 'SearchResults_Notifications.missingCostCode',
                messageType: 'user-error',
            });
            areAllParamsValid = false;
        }

        if (this.props.chooseOnlyFromCostCodesList && this.props.costCodes.indexOf(costCode) < 0) {
            utils.showNotificationsMessage({
                messageText: 'SearchResults_Notifications.missingCostCode',
                messageType: 'user-error',
            });
            areAllParamsValid = false;
        }

        if (query === '' || searchType === '') {
            areAllParamsValid = false;
        }

        return areAllParamsValid;
    };

    doSearch: (query: string, prefilterQuery: string, searchType: string) => void = (
        query,
        prefilterQuery,
        searchType
    ) => {
        if (!this.validateSearchParams(query, prefilterQuery, searchType)) {
            return;
        } else {
            if (this.state.isUserPreferencesChecked) {
                // construct new contentTypes object for updating UserPreferences state
                const adHocContentTypes = updateUserPreferencesContentTypesFromDropdown(this.props, this.state);
                const adHocDateRanges = this.props.updatePreferencesDateRanges();
                const newPrefs = {
                    [USER_PREFERENCES]: {
                        [GENERAL_SETTINGS]: {
                            ...adHocContentTypes,
                            ...adHocDateRanges,
                        },
                    },
                };
                utils.updatePreferencesAfterAdHoc(newPrefs);
            }

            query = utils.sanitiseQuery(query);
            let url = '/main-search?searchType=' + searchType + '&q=' + encodeURIComponent(query);

            if (this.props.isSnapshotVisible === false) {
                url +=
                    '&list=true&category=' + searchUtils.getSearchDefaultCategory(this.props.contentTypes, searchType);
            }

            if (prefilterQuery) {
                url += '&prefilterQuery=' + encodeURIComponent(prefilterQuery);
            }

            if (this.props.uboSelected) {
                const uboSelected = encodeUboUrlParam(this.props.uboSelected);
                url += `&${UBO_URL_PARAM}=${uboSelected}`;
            }

            if (utils.isCategoryChecked(CATEGORY_NAMES.DNB)) {
                this.props.onSubmit({ selected: this.props.uboSelected });
            }

            //generate billing id
            let billingId = costCodeUtils.generateBillingId();
            this.props.setBillingId(billingId);
            hashHistory.push(url);
        }
    };

    changeUpdateCheckbox: (event: SyntheticInputEvent<HTMLInputElement>) => void = (event) => {
        this.setState({
            isUserPreferencesChecked: event.target.checked,
        });
    };

    updateDropdownChangedStatus: (contentTypesChanged: boolean) => void = (contentTypesChanged) => {
        this.setState({
            isSourcesDropdownChanged: contentTypesChanged,
        });
    };

    updateSelectedSourcesCount: (selectedSources: number) => void = (selectedSources) => {
        this.setState({
            selectedSources: selectedSources,
        });
    };

    render(): React.Node {
        const { selectedSources, hasInsufficientCharacters } = this.state;
        let { searchType, query, prefilterQuery } = this.props.searchParams;
        let mergedQuery = utils.mergeQueryAndBoolean(query, prefilterQuery);

        return (
            <React.Fragment>
                <div className={`search-bar ${hasInsufficientCharacters ? 'search-bar-error' : ''}`}>
                    <SearchBox
                        dataTrack="homepage-search-bar-input-field"
                        placeholder={
                            searchType === COMPANY_SEARCH
                                ? formatRichMessage(
                                      { id: 'General_CoreFunctionality_UIText_general.searchBox.company' },
                                      this.props.intl
                                  )
                                : formatRichMessage(
                                      { id: 'General_CoreFunctionality_UIText_general.searchBox.person' },
                                      this.props.intl
                                  )
                        }
                        handleSearchEvent={this.handleSearchEvent}
                        queryChanged={this.queryChanged}
                        onChangeQuery={this.props.onChangeQuery}
                        resetQuery={this.resetQuery}
                        query={mergedQuery}
                        searchType={searchType}
                        uboSelected={this.props.uboSelected}
                        selectedSources={selectedSources}
                        hasInsufficientCharacters={hasInsufficientCharacters}
                    />
                    {this.props.isLoading && (
                        <div className={'search-bar-loader'}>
                            <SmallLoader className={'search-bar-loader'} />
                        </div>
                    )}
                    <div className="search-bar-options">
                        <DateRangesDataWrapper
                            dateRanges={this.props.dateRanges}
                            userLocks={this.props.userLocks}
                            newsDateRange={this.props.newsDateRange}
                            legalDateRange={this.props.legalDateRange}
                            companyDateRange={this.props.companyDateRange}
                            updateAdHocProperty={this.props.updateAdHocProperty}
                            adHocSearch={this.props.adHocSearch}
                            refreshPreferencesIfNeeded={this.props.refreshPreferencesIfNeeded}
                            userOfferings={this.props.userOfferings}
                        />
                        <SourcesDropdownContent
                            {...this.props}
                            sources={this.props.searchBarContentTypes}
                            updateSelectedSourcesCount={this.updateSelectedSourcesCount}
                            updateSourcesDropdownContentTypes={this.props.updateSourcesDropdownContentTypes}
                            updateAdHocSources={this.props.updateAdHocSources}
                            isDropdownChanged={this.state.isSourcesDropdownChanged}
                            lawSources={this.props.lawSources}
                            userPreferencesSources={this.props.contentTypes}
                            adHocSearchSources={this.props.adHocSearchSources}
                            personCustomNewsQuery={this.props.personCustomNewsQuery}
                            companyCustomNewsQuery={this.props.companyCustomNewsQuery}
                            adminPersonCustomNewsQuery={this.props.adminPersonCustomNewsQuery}
                            adminCompanyCustomNewsQuery={this.props.adminCompanyCustomNewsQuery}
                            lnPersonCustomNewsQuery={this.props.lnPersonCustomNewsQuery}
                            lnCompanyCustomNewsQuery={this.props.lnCompanyCustomNewsQuery}
                            locked={this.props.userLocks.sourcesIncluded}
                            updateAdhocProperty={this.props.updateAdHocProperty}
                            updateDropdownChangedStatus={this.updateDropdownChangedStatus}
                            refreshPreferencesIfNeeded={this.props.refreshPreferencesIfNeeded}
                        />
                        <button
                            type="button"
                            className="la-SWResearch"
                            data-track="homepage-search-event-trigger"
                            disabled={selectedSources === 0 || hasInsufficientCharacters}
                            onClick={this.handleSearchEvent}
                        ></button>
                    </div>
                    {hasInsufficientCharacters && (
                        <div className="search-term-error">
                            <FormattedMessage id={'SearchResults_Notifications.insufficientCharacters'} />
                        </div>
                    )}
                </div>
                <ModalUpdateCheckbox
                    isVisible={this.state.isSourcesDropdownChanged || this.props.shouldShowPreferencesCheckbox}
                    isChecked={this.state.isUserPreferencesChecked}
                    changeUpdateCheckbox={this.changeUpdateCheckbox}
                    id="searchBar"
                />
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = function (dispatch) {
    return bindActionCreators(
        {
            updateSourcesDropdownContentTypes: searchBar.updateSourcesDropdownContentTypes,
            updateAdHocSources: adHocSearchActions.updateAdHocSources,
            updateAdHocSearchObject: adHocSearchActions.updateAdHocSearchObject,
            updateUserPreferencesSources: actions.updateContentTypes,
        },
        dispatch
    );
};

export { SearchBar as TestSearchBar };

export default (compose(
    connect(null, mapDispatchToProps),
    withRouter,
    withAppContext,
    injectIntl
)(SearchBar): React.AbstractComponent<Props>);
