import React, { useRef, useState, useEffect, useMemo  } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import withAutosave from '../HOC/WithAutosave';
import { FILTER_DROPDOWN_LIST_ELEMENT_WIDTH, ROUTES, SANCTIONS_COLUMNS, SANCTIONS_COMPONENT_OFFSET, SANCTIONS_WATCHLISTS_SECTION, SANCTIONS_WATCHLISTS_TABLE_COLUMNS, SORT_DIRECTION } from '@constants';
import utils from '@utils/utilities';
import FilterSearchBox from '@scripts/reusable/FilterSearchBox/FilterSearchBox';
import ReactTooltip from 'react-tooltip';
import SimpleTable from '@scripts/reusable/SimpleTable/SimpleTable';
import { filterTableDataBasedOnColumnFilters, filterTableDataBasedOnSearchString, flattenTreeStructure, getFiltersData, getTreeStructureFromFlattenedData } from '../UserPreferencesUtils';
import RadioButtonsCell from './sanctions-components/RadioButtonsCell';
import TextCell from './sanctions-components/TextCell';
import TextHeaderCell from './sanctions-components/TextHeaderCell';
import RadioButtonsHeaderCell from './sanctions-components/RadioButtonsHeaderCell';
import useVirtual from 'react-cool-virtual';
import useStateWithPrev from '@scripts/hooks/useStateWithPrev';
import { isEqual } from 'lodash';
import withConfirmationModal from '@reusable/Modal/hoc/withConfirmationModal';

const SanctionsAndWatchListsSection = (props) => {
    // Redux states
    const authoritiesTreePreferences = props.authoritiesTreePreferences;
    // reusable constants
    const DEFAULT_SORTING = { 
        column: SANCTIONS_COLUMNS.SOURCE, 
        direction: SORT_DIRECTION.ASC, 
        secondaryAndTertiarySort: true 
    };
    // React states
    const [tableData, setTableData] = useState([]);
    const [preferencesData, setPreferencesData] = useState([]);
    const [filtersData, setFiltersData] = useState({
        source: [], country: [], authority: [], list: []
    });
    const [sorting, setSorting] = useState(DEFAULT_SORTING);
    const [itemCount, setItemCount] = useState(0);
    const [selectedRiskAll, setSelectedRiskAll] = useState('');
    const [shouldTooltipBeVisible, setShouldTooltipBeVisible] = useState(false);
    const [searchString, setSearchString, prevSearchString] = useStateWithPrev('');
    const [offsetTop, setOffsetTop] = useState();
    // refs
    const sanctionSectionRef = useRef();
    // Memoize the filtersData array to support deep object change effect triggering
    const uniqueFiltersDependency = useMemo(() => {
        return Object.values(filtersData).map(arr => JSON.stringify(arr));
    }, [filtersData]);
    // virtual scrolling hook
    const { outerRef, innerRef, items } = useVirtual({
        // number of items in the table
        itemCount,
        // scroll duration based on the scroll distance
        scrollDuration: (distance) => distance * 0.05,
        // using 'easeInOutBack' effect
        scrollEasingFunction: (t) => {
            const c1 = 1.70158;
            const c2 = c1 * 1.525;

            return t < 0.5
                ? (Math.pow(2 * t, 2) * ((c2 + 1) * 2 * t - c2)) / 2
                : (Math.pow(2 * t - 2, 2) * ((c2 + 1) * (t * 2 - 2) + c2) + 2) / 2;
        },
        // resets the scroll position when the 'itemCount' is changed (default = false)
        resetScroll: true,
        // preload 40 entries before and after what is visible in the table
        overscanCount: 40
    });
    // constants
    const {
        autoSaveMessage,
        sanctionsAndWatchlistsRiskLock,
        userIsAdmin,
        isPageDirty,
    } = props;
    const sectionParam = utils.getUrlQueryParam('section');
    const shouldBeReadOnly = !!(!userIsAdmin && sanctionsAndWatchlistsRiskLock);

    // initial load of data in the table and the column filters
    useEffect(() => {
        if (authoritiesTreePreferences.sources) {
            const initialArrayOfSourcesForFilters = flattenTreeStructure(authoritiesTreePreferences.sources)
            setFiltersData(getFiltersData(initialArrayOfSourcesForFilters));
            setTableData(initialArrayOfSourcesForFilters);
            setPreferencesData(initialArrayOfSourcesForFilters);
            setItemCount(initialArrayOfSourcesForFilters.length);
        }
    }, [])

    // if the user is an admin and the page is not dirty (after clicking cancel to reset the admin preferences changes), reset the table data to the initial data
    useEffect(() => {
        const initialArrayOfSources = flattenTreeStructure(authoritiesTreePreferences.sources)
        !isPageDirty && userIsAdmin && props.location.pathname === ROUTES.ADMIN_PREFERENCES && setTableData(initialArrayOfSources);
    }, [isPageDirty, userIsAdmin]);

    // handles the autosave by checking if there are differences between the preference object from Redux and the preferencesData object from the component to trigger the save
    useEffect(() => {
        if (preferencesData?.length) {
            const payload = getTreeStructureFromFlattenedData(preferencesData);
            if (payload && !(isEqual(authoritiesTreePreferences, payload))) {
                props.handleAutosave(props.updateSanctionsSourcesRisks, [payload]);
            }
        }
    }, [preferencesData])

    // handles the select all radio buttons for risk if the user changes the risk for specific authorities
    useEffect(() => {
        const isAnyFilterActive = Object.values(filtersData).some((filter) => filter.some((entry) => entry.checked));
        const isSearchStringFilled = searchString.length >= 3;

        // only do the check and update the risk for all authorities if there are no filters applied to the table
        if (!isAnyFilterActive && !isSearchStringFilled) {
            // if any authority has a different risk than the select all radio button, set the select all radio button to empty
            if (tableData.length && tableData.some((el) => el.risk !== selectedRiskAll)) {
                setSelectedRiskAll('')
            }
            // if all authorities have the same risk, set the select all radio button to that risk
            if (tableData.length && tableData.every((el) => el.risk === tableData[0].risk)) {
                setSelectedRiskAll(tableData[0].risk)
            }
        }
    }, [tableData])

    // handles the tooltip behaviour when the table data changes
    useEffect(() => {
        ReactTooltip.rebuild();
    }, [tableData, items])

    // this is for jumping to the S&W section when we have the section name in the URL
    useEffect(() => {
        setOffsetTop(sanctionSectionRef.current.offsetTop);

        if (offsetTop && sectionParam === SANCTIONS_WATCHLISTS_SECTION) {
            props.setSanctionsSectionOffset(offsetTop - SANCTIONS_COMPONENT_OFFSET);
        }
    }, [offsetTop]);

    // this is for updating the risk for all authorities if the user changes the select all radio button
    useEffect(() => {
        if (selectedRiskAll.length) {
            const updatedTableAuthorities = [...tableData];
            const updatedPreferencesData = [...preferencesData];
            for (let authority in updatedTableAuthorities) {
                updatedTableAuthorities[authority].risk = selectedRiskAll;
            }
            for (let preferenceAuthority in updatedPreferencesData) {
                if (updatedTableAuthorities.includes(updatedPreferencesData[preferenceAuthority])) {
                    updatedPreferencesData[preferenceAuthority].risk = selectedRiskAll;
                }
            }
            setTableData(updatedTableAuthorities);
            setPreferencesData(updatedPreferencesData);
        }
    }, [selectedRiskAll]);

    // this is for updating the table data based on the search string and the column filters
    useEffect(() => {
        const isAnyFilterActive = Object.values(filtersData).some((filter) => filter.some((entry) => entry.checked));
        const isSearchStringEmpty = (((prevSearchString && prevSearchString.length <= 3) || !prevSearchString) && searchString.length < 3);
        const wasSearchStringFilled = prevSearchString && prevSearchString.length >= 3 && searchString.length < 3;
        const isSearchStringFilled = searchString.length >= 3;
        const wasDataChanged = tableData.length !== preferencesData.length;
        let customData = [...preferencesData];

        // if the search string is filled and there are no column filters active, filter the table data based on the search string
        if (isSearchStringFilled && !isAnyFilterActive) {
            customData = [...filterTableDataBasedOnSearchString(customData, searchString)]
        }

        // if the search string is filled and there are column filters active, filter the table data based on the search string and the column filters
        if (isSearchStringFilled && isAnyFilterActive) {
            const filteredTableDataByColumns = filterTableDataBasedOnColumnFilters(customData, filtersData);
            const filteredTableDataBySearchString = filterTableDataBasedOnSearchString(filteredTableDataByColumns, searchString);
            customData = [...filteredTableDataBySearchString]
        }

        // if the search string is empty and there are column filters active, filter the table data based on the column filters
        if ((isSearchStringEmpty || wasSearchStringFilled) && isAnyFilterActive) {
            customData = [...filterTableDataBasedOnColumnFilters(customData, filtersData)];
        }

        // if the search string is empty and there are no column filters active and the data was changed, reset the table data to the default data
        if ((isSearchStringEmpty || wasSearchStringFilled) && !isAnyFilterActive && wasDataChanged) {
            customData = [...preferencesData];
        }

        setTableDataToCustomData(customData);

        // reapply sort on customData if searchString || filtersData [including deep] change   
        if(wasDataChanged || (prevSearchString !== searchString && tableData && tableData.length) || (isAnyFilterActive)){
            applyCustomSorting();
        } else {
            resetSorting();
        }
        
    }, [searchString, filtersData, uniqueFiltersDependency]);

    // this is used to sort the table data based on the column and direction (if descending, we just reverse the sorted array)
    useEffect(() => {
        const sortedTableData = [...tableData].sort((a, b) => {
            if (a[sorting.column] < b[sorting.column]) {
                return -1;
            }
            if (a[sorting.column] > b[sorting.column]) {
                return 1;
            }
    
            if (sorting.secondaryAndTertiarySort) {
                // Secondary sorting
                if (a[SANCTIONS_COLUMNS.AUTHORITY] < b[SANCTIONS_COLUMNS.AUTHORITY]) {
                    return -1;
                }
                if (a[SANCTIONS_COLUMNS.AUTHORITY] > b[SANCTIONS_COLUMNS.AUTHORITY]) {
                    return 1;
                }
    
                // Tertiary sorting
                if (a[SANCTIONS_COLUMNS.LIST] < b[SANCTIONS_COLUMNS.LIST]) {
                    return -1;
                }
                if (a[SANCTIONS_COLUMNS.LIST] > b[SANCTIONS_COLUMNS.LIST]) {
                    return 1;
                }
            }
    
            return 0;
        });
    
        if (sorting.direction === SORT_DIRECTION.DESC) {
            sortedTableData.reverse();
        }
        setTableData(sortedTableData);
    }, [sorting])

    const handleAuthorityRiskChangeAll = (value) => {
        setSelectedRiskAll(value)
    };

    const handleAuthorityRiskChange = (value, element) => {
        setTableData(prev => (prev.map(el => (el.list === element.list && el.authority === element.authority && el.country === element.country && el.source === element.source ? { ...el, risk: value } : el))));
        setPreferencesData(prev => (prev.map(el => (el.list === element.list && el.authority === element.authority && el.country === element.country && el.source === element.source ? { ...el, risk: value } : el))));
    }

    const isEllipsisActive = (e) => {
        // check if the text is truncated by comparing the offsetWidth and the scrollWidth of the element
        // if the offsetWidth is smaller than the scrollWidth, the text is truncated
        // if the offsetWidth is equal to the scrollWidth and the offsetWidth is greater than or equal to 248 (the width of the HTML list item element), the text is truncated
        return ((e.target.offsetWidth < e.target.scrollWidth) ||  (e.target.offsetWidth === e.target.scrollWidth && e.target.offsetWidth >= FILTER_DROPDOWN_LIST_ELEMENT_WIDTH));
    }

    const handleTextTruncation = (e) => {
        const isEllipsisVisible = isEllipsisActive(e)
        setShouldTooltipBeVisible(isEllipsisVisible)
    }

    const applyCustomSorting = ( current = {...sorting} ) => {
        setSorting({
            column: current.column || SANCTIONS_COLUMNS.SOURCE,
            direction: current.direction || SORT_DIRECTION.ASC,
            secondaryAndTertiarySort: current.secondaryAndTertiarySort
        });
    }

    const resetSorting = () => setSorting(DEFAULT_SORTING);

    const handleResetToDefaultSettings = () => {
        const description = (
            <div>
                <p><FormattedMessage id='UserPreferences.sanctionsAndWatchlists.reset.message1'/></p>
                <ul className="primary-list">
                    <li><FormattedMessage id='UserPreferences.sanctionsAndWatchlists.reset.message2'/></li>
                    <ul className="secondary-list">
                        <li><FormattedMessage id='UserPreferences.sanctionsAndWatchlists.reset.message3'/></li>
                        <li><FormattedMessage id='UserPreferences.sanctionsAndWatchlists.reset.message4'/></li>
                        <li><FormattedMessage id='UserPreferences.sanctionsAndWatchlists.reset.message5'/></li>
                    </ul>
                    <li><FormattedMessage id='UserPreferences.sanctionsAndWatchlists.reset.message6'/></li>
                    <ul className="secondary-list last">
                        <li><FormattedMessage id='UserPreferences.sanctionsAndWatchlists.reset.message7'/></li>
                    </ul>
                </ul>
            </div>
        );
        const modalProps = {
            title: <FormattedMessage id='UserPreferences.sanctionsAndWatchlists.reset.primaryBtnText'/>,
            description: description,
            primaryBtnText: 'UserPreferences.sanctionsAndWatchlists.reset.primaryBtnText',
        };
        props.onConfirmModal(modalProps, () => {
            props.getSanctionsDefaultPreferences().then((response) => {
                if (response) {
                    const defaultSanctionsPreferences = flattenTreeStructure(response.sources);
                    const defaultColumnsFilters = getFiltersData(defaultSanctionsPreferences)

                    if (defaultSanctionsPreferences) {
                        setTableData(defaultSanctionsPreferences);
                        setPreferencesData(defaultSanctionsPreferences);
                        setFiltersData(defaultColumnsFilters);
                        setItemCount(defaultSanctionsPreferences.length);
                        setSearchString('');
                        resetSorting();
                    }
                }
            })
        });
    };

    const updateFiltersData = (data, columnType) => {
        setFiltersData((prev) => ({ ...prev, [columnType]: data }));
    }

    const handleSorting = (column, direction, secondaryAndTertiarySort = false) => {
        applyCustomSorting({ column, direction, secondaryAndTertiarySort});
    }

    const setTableDataToCustomData = (data) => {
        setTableData(data);
        setItemCount(data.length);
    };

    const handleChangeFilterValue = (str) => {
        setSearchString(str);
    }

    const isColumnFilterActive = (column) => {
        return filtersData[column].some((entry) => entry.checked);
    }

    const computedRows = (rows, data) => {
        return (
            rows.length && data.length ? rows.map((row) => {
                if (data[row.index]) {
                    return SANCTIONS_WATCHLISTS_TABLE_COLUMNS.map((cell) => (cell === 'risk' ? <RadioButtonsCell rKey={utils.generateUUID()} element={data[row.index]} onChangeHandler={handleAuthorityRiskChange} disabled={shouldBeReadOnly} /> : <TextCell rKey={utils.generateUUID()} text={tableData[row.index][cell]} handleTextTruncation={handleTextTruncation} />))
                } else {
                    return [];
                }
            }) : []
        )
    };

    const computedColumns = (columns) => {
        return (
            columns.length && columns.map((column) => (column === 'risk' ? <RadioButtonsHeaderCell rKey={column} risk={selectedRiskAll} onChangeHandler={handleAuthorityRiskChangeAll} disabled={shouldBeReadOnly} /> : <TextHeaderCell rKey={column} title={column} filterData={filtersData[column]} updateFilterDropdownData={(updatedData) => updateFiltersData(updatedData, column)} sorting={sorting} handleSorting={handleSorting} isColumnFilterActive={isColumnFilterActive(column)} handleTextTruncation={handleTextTruncation}/>))
        )
    };

    const renderedRows = computedRows(items, tableData);
    const renderedColumns = computedColumns(SANCTIONS_WATCHLISTS_TABLE_COLUMNS);

    return (
        <div className='user-preferences-sanctions-and-watchlists' ref={sanctionSectionRef}>
            {shouldTooltipBeVisible && <ReactTooltip
                event='mouseenter'
                eventOff='mouseleave'
                type='light'
                border={true}
                effect='solid'
                place='bottom'
                offset={{ bottom: 0, left: 50 }}
                className='tooltips'
                id='authority-tooltip'
            />}
            <div className='user-preferences-category-container__row'>
                <h3 className='user-preferences-category-container__topic'>
                    <FormattedMessage id='UserPreferences_topic_sources_sanctionsWatchLists.title' />
                </h3>
                {autoSaveMessage}
            </div>
            <div className='user-preferences-category-container__note'>
                <FormattedMessage id='UserPreferences_topic_sources_sanctionsWatchLists.description2' />
            </div>
            <div className="user-preferences-category-container__search">
                <FilterSearchBox setControlledInputValue={handleChangeFilterValue} controlledInputValue={searchString} placeholder='UserPreferences_topic_sources_sanctionsWatchLists.searchPlaceholder' dataTestId='sanctions-and-watchlists-section' />
            </div>

            <div ref={outerRef} className='user-preferences-category-container__authorities'>
                <div ref={innerRef}>
                    <SimpleTable id='sanctionsWatchlistAuthoritiesTable' rows={renderedRows} columns={renderedColumns} emptyTableMessage='UserPreferences_topic_sources_sanctionsWatchLists.noAuthoritiesFound' />
                </div>
            </div>
            <div className='user-preferences-category-container__reset'>
                <button data-testid='authorities-reset-button' disabled={shouldBeReadOnly} onClick={handleResetToDefaultSettings}>
                    <FormattedMessage id='UserPreferences_topic_sources_sanctionsWatchLists.reset' />
                </button>
            </div>
        </div>
    );
};

export default withConfirmationModal(withRouter(withAutosave()(SanctionsAndWatchListsSection)));
