// @flow
import * as React from 'react';
import { hashHistory, withRouter } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';
import formatRichMessage from '@utils/formatRichMessage';
import BreadCrumbsModel from '@utils/breadCrumbBuilder';
import {
    ALERT_ACCESS_TYPE_DEFAULT,
    ALERT_ATTACH_TYPE_DEFAULT,
    ALERT_DELIVERY_TYPE_DEFAULT,
    ALERT_FREQUENCY_TYPE_DEFAULT,
    ALERT_FREQUENCY_TYPE_OPTIONS,
    BREADCRUMBS,
    CREATE_ALERT_PAGE_TITLE,
} from '@constants';
import breadcrumbsActions from '@reusable/Breadcrumbs/redux/Breadcrumbs.actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SaveAlertButton from './components/SaveAlertButton';
import AlertForm from './components/AlertForm';
import ReactTooltip from 'react-tooltip';
import { withAlertRouteToggle } from '@utils/contexts';
import categoryUtils from '@utils/categoryUtils';
import { COMPANY_CHECK, PERSON_CHECK } from '../Main/Main.reducers';
import { withAppContext } from '@utils/contexts';
import { compose } from 'redux';
import { isEmpty } from 'lodash';
import utils from '@utils/utilities';
import AlertUtils from '@utils/alertsUtils';
import type {
    ContextType,
    PreferencesType,
    SearchParamsType,
    SearchResultsType,
} from '@MainSearch/components/typeGuards/ResultsList.typeGuards';
import type { AlertType } from './typeGuards/AlertTypeGuards';

type State = {
    alert: null | AlertType,
};

type Props = {
    location: Object,
    searchParams: SearchParamsType,
    isSnapshotVisible: boolean,
    updateBreadcrumbs: (arg1: string) => void,
    searchResults: SearchResultsType,
    preferences: PreferencesType,
    adHocSearch: Object,
    userEmail: string,
    postFilterConfiguration: Object,
    breadcrumbs: Object,
    fuzzyNames: {
        list: Array<string | null>,
        query: string,
    },
    suggestedNames: {
        list: Array<string | null>,
        query: string,
    },
    contentTypes: Array<{
        name: string,
        reportOrder: number,
        value: boolean,
    }>,
    legalSources: Array<string>,
    state: any,
    ...InjectedProps,
};

type InjectedProps = {|
    +intl: Object,
    context: ContextType,
|};

class CreateAlert extends React.Component<Props, State> {
    state: State;
    constructor(props: Props) {
        super(props);

        this.state = {
            alert: null,
        };
    }

    setBreadcrumbs() {
        const category = this.props.location.query.category || this.props.searchParams.category;
        const shouldDisplaySnapshot = this.props.isSnapshotVisible && !this.props.searchParams.category;

        let breadcrumbs = new BreadCrumbsModel.Builder(BREADCRUMBS.CREATE_ALERT)
            .setProperty('category', category)
            .setProperty('searchQuery', utils.sanitizeSearchStringInput(this.props.searchParams.query))
            .setProperty('searchQueryType', this.props.searchParams.searchType)
            .setProperty('isSnapshotVisible', shouldDisplaySnapshot)
            .setProperty('prevPath', this.props.prevPath)
            .build().breadcrumbs;

        this.props.updateBreadcrumbs(breadcrumbs);
    }

    componentDidMount() {
        document.title = CREATE_ALERT_PAGE_TITLE;
        window.scrollTo(0, 0);
        const { searchResults, postFilterConfiguration, searchParams } = this.props;
        const name = this.props.searchParams.query.length > 0 ? utils.sanitizeSearchStringInput(this.props.searchParams.query) : '';
        const sources = Object.keys(this.props.searchResults).filter(
            (category) => this.props.searchResults[category].enabled && AlertUtils.isChildAndIsAllowedForAlert(category)
        );

        const prefType = this.props.searchParams.searchType === 'people' ? PERSON_CHECK : COMPANY_CHECK;
        const preferences = this.props.preferences[prefType];
        let userCustomNews = preferences.customNewsQuery
            .filter((userCustomQuery) => userCustomQuery.checked && !userCustomQuery.isDeleted)
            .map((userCustomQuery) => userCustomQuery.name);
        let lnCustomNews =
            preferences && preferences.lnCustomNewsQueries && preferences.lnCustomNewsQueries.length
                ? preferences.lnCustomNewsQueries
                      .filter((lnCustomQuery) => lnCustomQuery.checked && !lnCustomQuery.isDeleted)
                      .map((lnCustomQuery) => lnCustomQuery.name)
                : [];
        let adminCustomNews = preferences.adminCustomNewsQuery
            .filter((adminCustomQuery) => adminCustomQuery.checked && !adminCustomQuery.isDeleted)
            .map((adminCustomQuery) => adminCustomQuery.name);
        let customNews = [...userCustomNews, ...lnCustomNews, ...adminCustomNews];

        let contentLanguages = [];
        if (!this.props.adHocSearch || isEmpty(this.props.adHocSearch) || !this.props.adHocSearch.customQuery) {
            contentLanguages = this.props.preferences[prefType].customQuery
                .filter((lang) => lang.checked)
                .map((lang) => lang.language);
        } else {
            contentLanguages = this.props.adHocSearch.customQuery
                .filter((lang) => lang.checked)
                .map((lang) => lang.language);
        }

        const postFilters = utils.getPostFiltersFromSearch(
            sources,
            searchResults,
            postFilterConfiguration,
            searchParams
        );

        let { prefilterQuery, isCustomFuzzy, query, searchType } = this.props.searchParams;
        const originalLegalSources = sources.filter((source) => categoryUtils.isLegalSource(source));

        this.setState({
            alert: {
                name,
                sources,
                originalSources: sources,
                contentLanguages,
                originalContentLanguages: contentLanguages,
                originalLegalSources: originalLegalSources,
                customNews,
                postFilters,
                prefilterQuery: utils.removeFirstBooleanOperator(prefilterQuery),
                searchQuery: query,
                searchQueryType: searchType,
                isCustomFuzzy,
                emails:
                    this.props.context && this.props.context.isUserAnonymized
                        ? ''
                        : this.props.userEmail
                        ? this.props.userEmail
                        : '',
                comment: '',
                accessType: ALERT_ACCESS_TYPE_DEFAULT,
                frequencyType: ALERT_FREQUENCY_TYPE_DEFAULT,
                hourOfDay: ALERT_FREQUENCY_TYPE_OPTIONS.HOURS.defaultValue,
                dayOfMonth: ALERT_FREQUENCY_TYPE_OPTIONS.MONTH_DAYS.defaultValue,
                dayOfWeek: ALERT_FREQUENCY_TYPE_OPTIONS.WEEK_DAYS.defaultValue,
                attachType: ALERT_ATTACH_TYPE_DEFAULT,
                deliveryType: ALERT_DELIVERY_TYPE_DEFAULT,
                shouldTriggerForNoResults: false,
            },
        });
    }

    UNSAFE_componentWillMount() {
        if (this.props.searchParams.query.length === 0 || this.props.searchParams.searchType.length === 0) {
            hashHistory.push('/start');
        } else {
            this.setBreadcrumbs();
        }
    }

    goBackToSearchResults = () => {
        hashHistory.push(
            '/main-search?searchType=' +
                this.props.searchParams.searchType +
                '&q=' +
                utils.sanitizeSearchStringInput(this.props.searchParams.query) +
                '&list=true&category=' +
                this.props.location.query.category
        );
    };

    render() {
        return (
            <div className="create-alert-wrapper">
                <div className="create-alert-wrapper_header" data-testid="create-alert-wrapper-header">
                    <ReactTooltip
                        event="mouseenter"
                        eventOff="mouseleave"
                        type="light"
                        border={true}
                        effect="solid"
                        className="tooltips"
                    />
                    <button
                        className="icon-box"
                        data-tip={formatRichMessage({ id: 'ManageAlerts.backToResultsButton' }, this.props.intl)}
                        onClick={this.goBackToSearchResults}
                        data-testid="create-alert-wrapper-header-back-button"
                    >
                        <div className="la-TriangleLeft" />
                    </button>
                    <h2 data-testid="create-alert-wrapper-header-breadcrumbs">
                        <FormattedMessage id="BREADCRUMBS.createAlert" />
                    </h2>
                </div>
                {this.state.alert && (
                    <AlertForm
                        {...this.props}
                        alert={this.state.alert}
                        renderControls={(createAlertObject) => <SaveControls createAlertObject={createAlertObject} />}
                    />
                )}
            </div>
        );
    }
}

const SaveControls = ({ createAlertObject }) => (
    <div className="create-alert-actions">
        <SaveAlertButton createAlertObject={createAlertObject} />
        <button
            className="button-secondary-sm"
            data-testid="create-alert-cancel-button"
            onClick={() => hashHistory.go(-1)}
        >
            <FormattedMessage id={'General_CoreFunctionality_UIText_general.cancel'} />
        </button>
    </div>
);

const mapStateToProps = (state) => {
    return {
        selectedReport: state.reportBuilder.selectedReport,
        postFilterConfiguration: state.postFilterConfiguration,
        searchParams: state.searchParams,
        searchResults: state.searchResults,
        breadcrumbs: state.breadcrumbs,
        isSnapshotVisible: state.user.preferences.generalSettings.showSnapshot,
        contentTypes: state.user.preferences.generalSettings.contentTypes,
        legalSources: state.user.preferences.generalSettings.lawSources,
        legalSource: state.user.preferences.generalSettings.legalSources.defaultDateSource,
        fuzzyNames: state.fuzzyNames,
        suggestedNames: state.suggestedNames,
        suggestedNamesEnabled: state.user.suggestedNamesEnabled,
        userEmail: state.user.email,
        preferences: state.user.preferences,
        adHocSearch: state.adHocSearch,
        fuzzyThreshold: state.user.preferences.personCheck.fuzzyThreshold,
        prevPath: state.breadcrumbs.prevPath,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateBreadcrumbs: breadcrumbsActions.updateBreadcrumbs,
        },
        dispatch
    );
};

export const TestCreateAlert: void = injectIntl(CreateAlert);
export default (compose(
    connect(mapStateToProps, mapDispatchToProps),
    withAppContext,
    injectIntl,
    withAlertRouteToggle,
    withRouter
)(CreateAlert): React.AbstractComponent<Props, State>);
