import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';
export class SanctionsDocumentMetadataTemplate extends Component {
    LINE_LENGTH = 65;

    getCompatibleCell(uncertainCell) {
        const text = uncertainCell.text;
        return { ...uncertainCell, text };
    }

    update(cell, cellToMerge) {
        return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
    }

    tooltipText(text) {
        return text && this.textTruncated(text)
            ? text.replace(/\s+/g, ' ').trim()
            : undefined;
    }

    textTruncated(text) {
        return text && text.length > this.LINE_LENGTH;
    }

    render(cell) {
        const { text } = cell;
        const { content, onClick } = text;
        const { authority, list, source } = content;
        const isContentAvailable = content !== '-' && ((authority && list) || source);
        const onClickHandler = () => isContentAvailable && onClick();
        const shouldDisplayAuthorityAndList = authority && list;

        const shouldDisplayOnlySource = (!authority || !list) && source;
        const showTooltip = this.tooltipText(authority) || this.tooltipText(list) || this.tooltipText(source);

        return (
            <div
                className={`sanctions-table-cell ${isContentAvailable ? '' : 'disabled'}`}
                onClick={onClickHandler}
                onMouseEnter={() => { ReactTooltip.rebuild(); }}
            >
                {showTooltip && (
                    <ReactTooltip
                        event="mouseenter"
                        eventOff="mouseleave"
                        type="light"
                        border={true}
                        effect="solid"
                        place="top"
                        multiline={true}
                        html={true}
                        className="tooltips"
                        id='sanctions-metadata-tooltip'
                    />
                )}

                <div className='sanctions-document-metadata-outer-container'>
                    {shouldDisplayAuthorityAndList && <div className='sanctions-document-metadata-container'
                        data-tip={this.tooltipText(authority)}
                        data-for="sanctions-metadata-tooltip">
                        <p className='sanctions-document-metadata-title'><FormattedMessage id='SanctionsAndWatchlists.snapshot.table.authorityName' /></p>
                        <p className='sanctions-document-metadata-content'>{authority}</p>
                    </div>}
                    {shouldDisplayAuthorityAndList && <div className='sanctions-document-metadata-container' data-tip={this.tooltipText(list)} data-for="sanctions-metadata-tooltip">
                        <p className='sanctions-document-metadata-title'><FormattedMessage id='SanctionsAndWatchlists.snapshot.table.list' /></p>
                        <p className='sanctions-document-metadata-content'>{list}</p>
                    </div>}
                    {shouldDisplayOnlySource && <div className='sanctions-document-metadata-container' data-tip={this.tooltipText(source)} data-for="sanctions-metadata-tooltip">
                        <p className='sanctions-document-metadata-title'><FormattedMessage id='PostFilters.Title.Source' /></p>
                        <p className='sanctions-document-metadata-content'>{source}</p>
                    </div>}
                </div>
            </div>
        );
    }
}
