import categoryUtils from './categoryUtils';
import { CATEGORY_NAMES } from '@constants';
import { getAllTerms, sanitizeTerm } from '@sagas/helpers/uboHelper';

const AlertUtils = {
    isChildAndIsAllowedForAlert(type) {
        return (
            !categoryUtils.hasChildren(type) &&
            type !== CATEGORY_NAMES.PUBLIC_RECORDS &&
            type !== CATEGORY_NAMES.ESG_RATINGS &&
            !categoryUtils.isDnbCategory(type) &&
            !categoryUtils.isDockets(type)
        );
    },

    isParentAndIsAllowedForAlerts(type) {
        return (
            categoryUtils.isParent(type) &&
            type !== CATEGORY_NAMES.PUBLIC_RECORDS &&
            type !== CATEGORY_NAMES.ESG_RATINGS &&
            !categoryUtils.isDnbCategory(type) &&
            !categoryUtils.isDockets(type)
        );
    },

    mapAlertDataForMultipleEntities: (alertList, payloadData) => {
        return alertList.map((alertData) => {
            return {
                billingId: payloadData.billingId,
                costCode: payloadData.costCode,
                timezone: payloadData.timezone,
                language: payloadData.language,
                startEachArticleOnNewPage: payloadData.startEachArticleOnNewPage,
                categoryOrder: alertData.contentTypes.map((contentType, index) => ({
                    sourceType: contentType,
                    order: index,
                })),
                screeningEntityData: {
                    searchEntity: {
                        searchQuery: alertData.searchQuery,
                        searchQueryType: alertData.searchQueryType,
                        prefilterQuery: alertData.prefilterQuery,
                        isCustomFuzzy: alertData.searchQuery.includes(' OR '),
                        searchTerms: getAllTerms(alertData.searchQuery)
                            .map(sanitizeTerm)
                            .filter((term) => term.length),
                    },
                    existingAlertId: alertData.id,
                    displayName: alertData.prefilterQuery
                        ? `${alertData.searchQuery} AND ${alertData.prefilterQuery}`
                        : alertData.searchQuery,
                },
            };
        });
    },
};

export default AlertUtils;
