import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UserPreferences } from '@UserPreferences/UserPreferencesPage.index';

import utils from '@utils/utilities';
import formatRichMessage from '@utils/formatRichMessage';
import breadcrumbsActions from '@reusable/Breadcrumbs/redux/Breadcrumbs.actions';
import actions from '@UserPreferences/redux/UserPreferences.actions';
import mainActions from '@api/Common.actions';
import {
    BREADCRUMBS,
    CATEGORY_NAMES,
    CHANGED_BY_ADMIN,
    MODAL_TYPE,
    HELP_URLS,
    ADMIN_PREFERENCES_PAGE_TITLE,
} from '@constants';
import PopupModal from '@reusable/PopupModal/PopupModal.index';
import LeftSideNavigation from '@UserPreferences/components/LeftSideNavigation';
import ConfirmationModal from '@reusable/ConfirmationModal/ConfirmationModal';
import { COMPANY_CHECK, DELIVERY_SETTINGS, GENERAL_SETTINGS, PERSON_CHECK } from '../Main/Main.reducers';
import GeneralSettingsContainer from '@UserPreferences/components/GeneralSettingContainer';
import { permissions, userHas } from '@permissions';
import { FormattedMessage, injectIntl } from 'react-intl';
import { cloneDeep, debounce, keys, isEqual } from 'lodash';
import userPreferenceApi from '@UserPreferences/UserPreferencesApi.api';
import PopupModel from '@reusable/PopupModal/PopupBuilder';
import reduxStore from '@reduxStore';
import popupModelActions from '@reusable/PopupModal/redux/PopupModel.actions';
import backgroundActions from '@reusable/BackgroundMessage/redux/BackgroundMessages.actions';
import { withRouter } from 'react-router';
import BreadCrumbsModel from '@utils/breadCrumbBuilder';
import PersonCheck from '@UserPreferences/components/PersonCheck';
import CompanyCheck from '@UserPreferences/components/CompanyCheck';
import AdminLockProviderContext from '@reusable/AdminLock/context/AdminLockContextProvider';
import DeliverySettings from '@UserPreferences/components/DeliverySettings';

class AdminPreferences extends UserPreferences {
    constructor(props) {
        super(props);
        this.state = {
            showEditModal: false,
            userIsAdmin: userHas(permissions.admin.edit),
        };
        this.handleLockClick = this.handleLockClick.bind(this);
        this.toggleAdminSaveModal = this.toggleAdminSaveModal.bind(this);
        this.toggleAdminCancelModal = this.toggleAdminCancelModal.bind(this);
        this.adminApplyChanges = this.adminApplyChanges.bind(this);
        this.adminDiscardChanges = this.adminDiscardChanges.bind(this);
        this.initialPreferences = cloneDeep(props.allPreferences);
        this.clonePreferences = this.clonePreferences.bind(this);
    }

    setBreadcrumbs() {
        let items = this.props.breadcrumbs.items;
        let breadcrumbs = new BreadCrumbsModel.Builder(BREADCRUMBS.ADMIN_PREFERENCES).build().breadcrumbs;
        if (items && JSON.stringify(items) !== JSON.stringify(breadcrumbs)) {
            this.props.updateBreadcrumbs(breadcrumbs);
        }
    }

    handleLockClick(lockName) {
        let lockState = this.props.userLocks[lockName];
        this.props.updateLocks({ [lockName]: !lockState });
    }

    routerWillLeave() {
        let isPageDirty = this.isPageDirty();
        if (isPageDirty) {
            this.toggleAdminSaveModal();
        }
        return !isPageDirty;
    }

    componentDidMount() {
        document.title = ADMIN_PREFERENCES_PAGE_TITLE;

        window.scrollTo(0, 0);
        this.setBreadcrumbs();
        this.setLeftNavigationTopOffset();
        this.registerEvents(document, 'scroll', debounce(this.debounceScrolling, 100));
    }

    toggleAdminSaveModal() {
        let popupModel = new PopupModel.Builder(MODAL_TYPE.APPLY_ADMIN_CHANGES)
            .setPopupProperty('isVisible', true)
            .setPopupProperty('usersInPreferenceGroup', this.props.usersInPreferenceGroup)
            .setPopupProperty('action', () => this.adminApplyChanges())
            .build();

        reduxStore.dispatch(popupModelActions.setPopupModel(popupModel));
    }

    toggleAdminCancelModal() {
        let popupModel = new PopupModel.Builder(MODAL_TYPE.DISCARD_ADMIN_CHANGES)
            .setPopupProperty('isVisible', true)
            .setPopupProperty('action', () => this.adminDiscardChanges())
            .build();

        reduxStore.dispatch(popupModelActions.setPopupModel(popupModel));
    }

    adminApplyChanges() {
        // set the flag to know in the rest of the app that the preferences are updating
        this.props.updateAdminIsDirty(true);
        let adminChangedPrefs = {};

        let { prefCopy, initialPrefCopy } = this.clonePreferences();
        // get differences between intial props and current props
        let differences = utils.getDifferences(prefCopy, initialPrefCopy);

        Object.keys(differences).forEach((mainPreferencesSection) => {
            // for each preferences section (generalSettings, personCheck, companyCheck) get the keys of the changed properties
            let changedValues = keys(differences[mainPreferencesSection]);

            // create a new object for the changed preference section
            adminChangedPrefs[mainPreferencesSection] = {};
            if (changedValues.length > 0) {
                // for each chan
                changedValues.forEach((key) => {
                    switch (key) {
                        case 'isFuzzyEnabled':
                            adminChangedPrefs[mainPreferencesSection].enableFuzzy =
                                prefCopy[mainPreferencesSection][key];
                            break;
                        case 'customQuery':
                        case 'newsSearchSources':
                            adminChangedPrefs[mainPreferencesSection]['customQuery'] =
                                prefCopy[mainPreferencesSection]['customQuery'];
                            adminChangedPrefs[mainPreferencesSection]['newsSearchSources'] =
                                prefCopy[mainPreferencesSection]['newsSearchSources'];
                            break;
                        default:
                            adminChangedPrefs[mainPreferencesSection][key] = prefCopy[mainPreferencesSection][key];
                    }
                });
            } else {
                adminChangedPrefs[mainPreferencesSection] = differences[mainPreferencesSection];
            }
        });

        let userLocks = utils.convertUserLocksObjToArr(prefCopy.userLocks);
        prefCopy.prefsUpdatedByAdmin = true;
        adminChangedPrefs.prefsUpdatedByAdmin = true;

        userPreferenceApi
            .updateAdminUserPreference({ permissions: userLocks, adminPreferences: adminChangedPrefs })
            .then(() => {
                return userPreferenceApi.getUserPreferences();
            })
            .then((response) => {
                this.props.getUserPreferencesValues(response.body);
                utils.showAdminNotificationsMessage({
                    messageText: 'UserPreferences_Notifications.adminChanges',
                    messageType: 'info',
                    terms: { term: CHANGED_BY_ADMIN },
                });
            })
            .catch(() => {
                utils.showNotificationsMessage({
                    messageText: 'UserPreferences_autosave_error',
                    messageType: 'system-error',
                });
            })
            .finally(() => {
                this.props.updateAdminIsDirty(false);
            });

        // once the admin saves the new prefs, they can revert to them.
        this.initialPreferences = prefCopy;
    }

    adminDiscardChanges() {
        this.props.adminDiscardPrefsChanges(this.initialPreferences);
    }

    clonePreferences() {
        let prefCopy = cloneDeep(this.props.allPreferences);
        let initialPrefCopy = cloneDeep(this.initialPreferences);

        delete prefCopy.language;
        delete prefCopy.metadataLanguage;
        delete prefCopy.prefsUpdatedByAdmin;
        delete initialPrefCopy.language;
        delete initialPrefCopy.metadataLanguage;
        delete initialPrefCopy.prefsUpdatedByAdmin;

        return {
            prefCopy,
            initialPrefCopy,
        };
    }

    hasUpdatedNegativityLevels = (prefCopy, initialPrefCopy) => {
        return (
            prefCopy.personCheck.negativityLevel.length !== initialPrefCopy.personCheck.negativityLevel.length ||
            prefCopy.companyCheck.negativityLevel.length !== initialPrefCopy.companyCheck.negativityLevel.length
        );
    };

    isPageDirty() {
        let { prefCopy, initialPrefCopy } = this.clonePreferences();

        return !isEqual(prefCopy, initialPrefCopy) || this.hasUpdatedNegativityLevels(prefCopy, initialPrefCopy);
    }

    changeExcludeNonBusinessNews = (excludeNonBusinessNewsOption) => {
        const isExcludeNonBusinessNews = excludeNonBusinessNewsOption.target.checked;
        this.props.updateExcludeNonBusinessNews(isExcludeNonBusinessNews);
    };

    changeExcludeNewsWires = (excludeNewsWiresOption) => {
        const isExcludeNewsWires = excludeNewsWiresOption.target.checked;
        this.props.updateExcludeNewsWires(isExcludeNewsWires);
    };

    render() {
        const buttons = [
            {
                text: formatRichMessage({ id: 'General_CoreFunctionality_UIText_general.ok' }, this.props.intl),
                primary: true,
                click: this.closeModal,
            },
        ];
        const searchSources = this.getSearchSources() || [];
        const lawSources = searchSources.find((source) => source.key === CATEGORY_NAMES.LAW_SOURCES);
        const isLegalChecked = !!lawSources ? lawSources.checked : false;
        const reportBuilderCategories = this.getReportBuilderSources();
        // @TODO add results list

        const deliverySettings = {
            includeResearchSummary:
                this.props.deliveryIncludeResearchSummaryReport && this.props.deliveryIncludeResearchSummaryResults,
            startEachArticleOnNewPage: this.props.startEachArticleOnNewPage,
        };
        const customNewsSearchHelpUrl = utils.getHelpUrl(this.props.user, HELP_URLS.CUSTOM_NEWS_SEARCH_URL.key);

        return (
            <AdminLockProviderContext isEditable>
                {
                    // eslint-disable-next-line
                    <div className="admin-preferences-page user-preferences-page" ref={'user-preference-page'}>
                        {this.props.popupModel.isVisible && <PopupModal closeButtonHandler={this.closePopupModal} />}
                        <h1 className="user-preferences-header notranslate">
                            <span className="user-preferences-header-name">
                                <FormattedMessage id="HeaderFooterNav_CoreFunctionality_Dropdown_nav.user.adminPreferences" />
                            </span>
                        </h1>
                        <LeftSideNavigation
                            handleScrollToPage={this.handleScrollToPage}
                            isActiveTab={this.props.isActiveTab}
                            top={this.state.leftNavigationTop}
                        />
                        {
                            // eslint-disable-next-line
                            <div className="user-preferences" ref={'user-preference'}>
                                {this.state.showEditModal && (
                                    <ConfirmationModal closeButtonHandler={this.closeModal} buttons={buttons}>
                                        <h2 className="modal-header">
                                            <FormattedMessage id={this.state.confirmationModalTitle} />
                                        </h2>
                                        <div className="modal-description font-body">
                                            <FormattedMessage id={this.state.confirmationModalDescription} />
                                        </div>
                                    </ConfirmationModal>
                                )}
                                <div className="user-preferences-block" id={GENERAL_SETTINGS} ref={GENERAL_SETTINGS}>
                                    <GeneralSettingsContainer
                                        containerName={GENERAL_SETTINGS}
                                        generalSettings={this.props.generalSettings}
                                        sources={searchSources}
                                        handleSourceSelection={this.handleSourceSelection}
                                        handleShowSnapshot={this.handleShowSnapshot}
                                        handleUpdateSanctionsAuthoritiesList={this.handleUpdateSanctionsAuthoritiesList}
                                        handleUpdateSanctionsDateRange={this.handleUpdateSanctionsDateRange}
                                        handleUpdateSanctionsKeywords={this.handleUpdateSanctionsKeywords}
                                        changeDateRange={this.changeDateRange}
                                        changeProximity={this.changeProximity}
                                        changeLegalSource={this.changeLegalSource}
                                        onClickLock={this.handleLockClick}
                                        showSnapshot={this.props.showSnapshot}
                                        proximities={this.props.proximities}
                                        proximity={this.props.proximity}
                                        legalSources={this.state.legalSources}
                                        legalSource={this.state.legalSource}
                                        isLegalChecked={isLegalChecked}
                                        userLocks={this.props.userLocks}
                                        userIsAdmin={this.state.userIsAdmin}
                                        toggleAdminSaveModal={this.toggleAdminSaveModal}
                                        toggleAdminCancelModal={this.toggleAdminCancelModal}
                                        isPageDirty={this.isPageDirty()}
                                        adminIsCostCodeRequired={this.props.adminIsCostCodeRequired}
                                        adminChooseOnlyFromCostCodesList={this.props.adminChooseOnlyFromCostCodesList}
                                        adminCostCodesList={this.props.adminCostCodesList}
                                        updateAdminCostCodeIsRequired={this.props.updateAdminCostCodeIsRequired}
                                        updateAdminCostCodeOnlyFromCostCodeList={
                                            this.props.updateAdminCostCodeOnlyFromCostCodeList
                                        }
                                        addCostCodeToList={this.props.addCostCodeToList}
                                        deleteCostCodeFromList={this.props.deleteCostCodeFromList}
                                        updateSearchNamePartyEntity={this.props.updateSearchNamePartyEntity}
                                        sourcesInformation={this.props.sourcesInformation}
                                        excludeNonBusinessNews={this.props.excludeNonBusinessNews}
                                        changeExcludeNonBusinessNews={this.changeExcludeNonBusinessNews}
                                        excludeNewsWires={this.props.excludeNewsWires}
                                        changeExcludeNewsWires={this.changeExcludeNewsWires}
                                        newsExcludeTogglesEnabled={this.props.newsExcludeTogglesEnabled}
                                        userOfferings={this.props.userOfferings}
                                        sanctionRiskRating={this.props.sanctionRiskRating}
                                        updateSanctionsSourcesRisks={this.props.updateSanctionsSourcesRisks}
                                        authoritiesTreePreferences={this.props.authoritiesTreePreferences}
                                        getSanctionsDefaultPreferences={userPreferenceApi.getDefaultAdminSanctionsPreferences}
                                    />
                                </div>
                                <div className="user-preferences-block" id={PERSON_CHECK} ref={PERSON_CHECK}>
                                    <PersonCheck
                                        userLocks={this.props.userLocks}
                                        intl={this.props.intl}
                                        settings={this.props.personCheck}
                                        updateNegativityLevel={this.updatePersonNegativityLevel}
                                        updateNewsSourcesSelection={this.props.updateNewsSourcesSelection}
                                        handleNegativeNewsLanguageSelection={this.handleNegativeNewsLanguageSelection}
                                        languages={this.state.personAvailableLanguages}
                                        updateCheckedLanguagesUponKey={this.props.updateCheckedLanguagesUponKey}
                                        saveChanges={this.changeQueryPerson}
                                        handleFuzzyEnabled={this.handleFuzzyEnabled}
                                        handleSelectFuzzyMatch={this.handleSelectFuzzyMatch}
                                        fuzzyMatch={this.props.fuzzyMatch}
                                        isFuzzyEnabled={this.props.isFuzzyEnabled}
                                        negativityLevels={this.props.negativityLevels}
                                        userIsAdmin={this.state.userIsAdmin}
                                        handleLockClick={this.handleLockClick}
                                        customNewsQuery={this.props.personCustomNewsQuery}
                                        checkCustomNewsQuery={this.props.checkCustomNewsQuery}
                                        checkCustomNewsSourceAvailability={this.checkCustomNewsSourceAvailability}
                                        fuzzyThreshold={this.props.fuzzyThreshold}
                                        fuzzyOn={this.props.fuzzyOn}
                                        newFuzzyEnabled={this.props.newFuzzyEnabled}
                                        suggestedNamesEnabled={this.props.suggestedNamesEnabled}
                                        handleSelectFuzzyThreshold={this.handleSelectFuzzyThreshold}
                                        handleFuzzyToggle={this.handleFuzzyToggle}
                                        handleSuggestedNamesToggle={this.handleSuggestedNamesToggle}
                                        suggestedNames={this.props.suggestedNames}
                                        setSuccessBackgroundMessages={this.props.setSuccessBackgroundMessages}
                                        clearBackgroundMessages={this.props.clearBackgroundMessages}
                                        setPopupModel={this.props.setPopupModel}
                                        customNewsSearchHelpUrl={customNewsSearchHelpUrl}
                                    />
                                </div>
                                <div className="user-preferences-block" id={COMPANY_CHECK} ref={COMPANY_CHECK}>
                                    <CompanyCheck
                                        userLocks={this.props.userLocks}
                                        intl={this.props.intl}
                                        settings={this.props.companyCheck}
                                        updateNegativityLevel={this.updateCompanyNegativityLevel}
                                        updateNewsSourcesSelection={this.props.updateNewsSourcesSelection}
                                        handleNegativeNewsLanguageSelection={this.handleNegativeNewsLanguageSelection}
                                        languages={this.state.companyAvailableLanguages}
                                        updateCheckedLanguagesUponKey={this.props.updateCheckedLanguagesUponKey}
                                        saveChanges={this.changeQueryCompany}
                                        negativityLevels={this.props.negativityLevels}
                                        userIsAdmin={this.state.userIsAdmin}
                                        handleLockClick={this.handleLockClick}
                                        customNewsQuery={this.props.companyCustomNewsQuery}
                                        checkCustomNewsQuery={this.props.checkCustomNewsQuery}
                                        checkCustomNewsSourceAvailability={this.checkCustomNewsSourceAvailability}
                                        updateCompanySourcesSearchOption={this.props.updateCompanySourcesSearchOption}
                                        setSuccessBackgroundMessages={this.props.setSuccessBackgroundMessages}
                                        clearBackgroundMessages={this.props.clearBackgroundMessages}
                                        setPopupModel={this.props.setPopupModel}
                                        customNewsSearchHelpUrl={customNewsSearchHelpUrl}
                                    />
                                </div>
                                <div className="user-preferences-block" id={DELIVERY_SETTINGS} ref={DELIVERY_SETTINGS}>
                                    <DeliverySettings
                                        categories={reportBuilderCategories}
                                        deliverySettings={deliverySettings}
                                        maxDocumentsPerCategoryInReport={this.props.maxDocumentsInReportCategory}
                                        setReportBuilderContentOrder={this.setReportBuilderContentOrder}
                                        handleToggleDeliverySettings={this.onDeliverySettingsChange}
                                        handleMaxDocumentsPerCategoryInReport={this.handleMaxDocumentsPerCategoryInReport}
                                        userLocks={this.props.userLocks}
                                        userIsAdmin={this.state.userIsAdmin}
                                        handleLockClick={this.handleLockClick}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                }
            </AdminLockProviderContext>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        allPreferences: state.user.adminPreferences,
        companyCheck: state.user.adminPreferences.companyCheck,
        personCheck: state.user.adminPreferences.personCheck,
        sources: state.user.adminPreferences.generalSettings.contentTypes,
        lawSources: state.user.adminPreferences.generalSettings.lawSources,
        lawSource: state.user.adminPreferences.generalSettings.legalSources.defaultDateSource,
        generalSettings: state.user.adminPreferences.generalSettings,
        proximity: state.user.adminPreferences.generalSettings.proximity,
        proximities: state.user.adminPreferences.generalSettings.proximities,
        showSnapshot: state.user.adminPreferences.generalSettings.showSnapshot,
        isFuzzyEnabled: state.user.adminPreferences.generalSettings.isFuzzyEnabled,
        fuzzyMatch: state.user.adminPreferences.generalSettings.fuzzyMatch,
        userLocks: state.user.adminPreferences.userLocks,
        searchType: state.searchParams.searchType,
        popupModel: state.popupModel,
        breadcrumbs: state.breadcrumbs,
        userPreferences: state.user.preferences,
        isActiveTab: state.userPreferences.activeTab,
        personLanguages: state.user.adminPreferences.personCheck.customQuery,
        companyLanguages: state.user.adminPreferences.companyCheck.customQuery,
        availableQueryLanguages: state.user.adminPreferences.generalSettings.availableQueryLanguages,
        negativityLevels: state.user.adminPreferences.generalSettings.negativityLevel,
        adminIsCostCodeRequired: state.user.adminPreferences.generalSettings.isCostCodeRequired,
        adminChooseOnlyFromCostCodesList: state.user.adminPreferences.generalSettings.chooseOnlyFromCostCodesList,
        adminCostCodesList: state.user.adminPreferences.generalSettings.costCodesList,
        personCustomNewsQuery: state.user.adminPreferences.personCheck.adminCustomNewsQuery,
        companyCustomNewsQuery: state.user.adminPreferences.companyCheck.adminCustomNewsQuery,
        fuzzyList: state.fuzzyNames.list,
        sourcesInformation: state.user.sourcesInformation,
        deliveryIncludeResearchSummaryReport: state.user.adminPreferences.contentsOptions.shouldContainResearchSummary,
        deliveryIncludeResearchSummaryResults:
            state.user.adminPreferences.resultsContentOptions.shouldContainResearchSummary,
        startEachArticleOnNewPage: state.user.adminPreferences.startEachArticleOnNewPage,
        fuzzyThreshold: state.user.adminPreferences.personCheck.fuzzyThreshold,
        fuzzyOn: state.user.adminPreferences.personCheck.fuzzyOn,
        newFuzzyEnabled: state.user.newFuzzyEnabled,
        suggestedNamesEnabled: state.user.suggestedNamesEnabled,
        suggestedNames: state.user.adminPreferences.personCheck.suggestedNames,
        excludeNonBusinessNews: state.user.adminPreferences.generalSettings.excludeNonBusinessNews,
        excludeNewsWires: state.user.adminPreferences.generalSettings.excludeNewsWires,
        newsExcludeTogglesEnabled: state.user.newsExcludeTogglesEnabled,
        usersInPreferenceGroup: state.user.adminPreferences.usersInPreferenceGroup,
        personNegativityLevels: state.user.adminPreferences.personCheck.negativityLevel,
        companyNegativityLevels: state.user.adminPreferences.companyCheck.negativityLevel,
        userOfferings: state.user.userOfferings,
        sanctionRiskRating: state.user.adminPreferences.sanctionRiskRating,
        maxDocumentsInReportCategory: state.user.adminPreferences.maxDocumentsInReportCategory,
        authoritiesTreePreferences: state.user.adminPreferences.generalSettings.sanctionsSources,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateBreadcrumbs: breadcrumbsActions.updateBreadcrumbs,
            getAdminPreferencesValues: actions.getAdminPreferencesValues,
            getUserPreferencesValues: actions.getUserPrefencesValues,
            adminDiscardPrefsChanges: actions.adminDiscardPrefsChanges,
            addUserPreferencesContentType: actions.addAdminPreferencesContentType,
            deleteUserPreferencesContentType: actions.deleteAdminPreferencesContentType,
            updateDateRange: actions.updateAdminDateRange,
            updateProximity: actions.updateAdminProximity,
            updateQueryPerson: actions.updateAdminQueryPerson,
            updateQueryCompany: actions.updateAdminQueryCompany,
            updateLegalSource: actions.updateAdminLegalSource,
            updateSnapshotVisibility: actions.updateAdminSnapshotVisibility,
            updateFuzzyMatch: actions.updateAdminFuzzyMatch,
            updateFuzzyThreshold: actions.updateAdminFuzzyThreshold,
            updateFuzzyFunctionality: actions.updateAdminFuzzyFunctionality,
            updateFuzzyToggle: actions.updateAdminFuzzyToggle,
            updateSuggestedNamesToggle: actions.updateAdminSuggestedNamesToggle,
            updateLocks: actions.updateLocks,
            setUserPreferences: actions.setUserPreferences,
            setActiveTab: actions.setActiveTab,
            updateContentOrderForReportBuilder: mainActions.updateAdminContentOrderForReportBuilder,
            updateNewsSourcesSelection: actions.updateAdminNewsSourcesSelection,
            updateCheckedLanguagesUponKey: actions.updateAdminCheckedLanguagesUponKey,
            updateCheckedLanguage: actions.updateAdminCheckedLanguage,
            updatePersonNegativityLevel: actions.updateAdminPersonNegativityLevel,
            updateCompanyNegativityLevel: actions.updateAdminCompanyNegativityLevel,
            updateAdminCostCodeIsRequired: actions.updateAdminCostCodeIsRequired,
            updateAdminCostCodeOnlyFromCostCodeList: actions.updateAdminCostCodeOnlyFromCostCodeList,
            checkAdminCustomNewsQuery: actions.updateAdminCreatedCustomNewsQueryCheckedState,
            addCostCodeToList: actions.addCostCodeToList,
            deleteCostCodeFromList: actions.deleteCostCodeFromList,
            checkCustomNewsQuery: actions.updateAdminCustomNewsQueryCheckedState,
            updateCompanySourcesSearchOption: actions.updateCompanySourcesSearchOption,
            updateSearchNamePartyEntity: actions.updateSearchNamePartyEntity,
            updateContentOptions: actions.adminUpdateContentOptions,
            updateShowDocumentOnNewPage: actions.adminUpdateShowDocumentOnNewPage,
            updateExcludeNonBusinessNews: actions.adminUpdateExcludeNonBusinessNews,
            updateExcludeNewsWires: actions.adminUpdateExcludeNewsWires,
            updateSanctionsRiskToggle: actions.adminUpdateSanctionsRiskToggle,
            setSuccessBackgroundMessages: backgroundActions.setSuccessBackgroundMessages,
            clearBackgroundMessages: backgroundActions.clearBackgroundMessages,
            setPopupModel: popupModelActions.setPopupModel,
            updateAdminIsDirty: actions.updateAdminIsDirty,
            updateMaxDocumentsPerCategoryInReport: actions.adminUpdateMaxDocumentsPerCategoryInReport,
            updateSanctionsSourcesRisks: actions.adminUpdateSanctionsSourcesRisks,
        },
        dispatch
    );
};

export { AdminPreferences as TestAdminPreferences };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(AdminPreferences)));
