import {
    SET_SEARCH_RESULTS,
    RESET_CATEGORIES_RESULTS,
    UPDATE_CATEGORY_PROPERTY,
    UPDATE_SEARCH_CATEGORY,
    UPDATE_SEARCH_CATEGORIES_LIST,
    SEARCH_PARAM_ADD_TERM,
    SEARCH_PARAM_REMOVE_TERM,
    CLEAR_TERMS_CATEGORY,
    SET_SORT_OPTION,
    UPDATE_CATEGORY_POSTFILTER,
    UPDATE_CATEGORY_POSTFILTERS,
    UPDATE_POST_FILTERS,
    UPDATE_DUPLICATE_SIZE,
    SEARCH_UBO_ADD_CATEGORY,
    SET_LAST_SORT_OPTION,
    UPDATE_SEARCH_CATEGORY_LAST_UPDATED,
    CLEAR_CATEGORY_NEGATIVE_TERMS,
    RESET_CATEGORIES_GROUP,
    SET_FILTERS_APPLIED_FOR_CATEGORY,
} from './SearchResults.actions';

import update from 'immutability-helper';
import { cloneDeep, isEmpty } from 'lodash';
import { resetCategory } from '@reduxStore';
import errorUtils from '@utils/errors/error-utils';
const APP_MODULE = 'searchResults.reducers';
function searchResults(state = {}, action) {
    let rez = null;
    let category = {};
    let filterType;
    let categoryName;
    let term;
    let postFilters;
    switch (action.type) {
        case UPDATE_DUPLICATE_SIZE: {
            categoryName = action.payload.categoryName;
            let duplicateGroupsCount = action.payload.duplicateGroupsCount;
            let articles = cloneDeep(state[categoryName].articles);
            articles.forEach((article) => {
                article.duplicateSize =
                    duplicateGroupsCount[article.duplicateGroupId] === 0
                        ? 0
                        : duplicateGroupsCount[article.duplicateGroupId] - 1;
            });
            return update(state, { [categoryName]: { ['articles']: { $set: articles } } });
        }

        case UPDATE_CATEGORY_PROPERTY: {
            let { name, propertyName, propertyValue } = action.payload;
            if (!name || !propertyName || propertyValue === undefined || propertyValue === null) {
                //check if propertyValue !== undefined or null because usually it can get values as true/false or integer (count)
                errorUtils.logAppError(
                    `Missing parameter in ${UPDATE_CATEGORY_PROPERTY}`,
                    { propertyName: propertyName, propertyValue: propertyValue },
                    APP_MODULE
                );
                return state;
            }
            return update(state, { [name]: { [propertyName]: { $set: propertyValue } } });
        }

        case UPDATE_CATEGORY_POSTFILTER: {
            let { postFilterName, postFilterValue } = action.payload || {};
            categoryName = action.payload.categoryName;
            if (!postFilterName || !categoryName) {
                errorUtils.logAppError(
                    `Missing parameter in ${UPDATE_CATEGORY_POSTFILTER}`,
                    { postFilterName: postFilterName, postFilterValue: postFilterValue, categoryName: categoryName },
                    APP_MODULE
                );
                return state;
            }
            return update(state, { [categoryName]: { postFilters: { [postFilterName]: { $set: postFilterValue } } } });
        }

        case UPDATE_CATEGORY_POSTFILTERS: {
            if (!action.payload || !action.payload.postFilters || !action.payload.categoryName) {
                errorUtils.logAppError(
                    `Missing parameter in ${UPDATE_CATEGORY_POSTFILTERS}`,
                    {
                        payload: action.payload,
                        postFilters: action.payload.postFilters,
                        categoryName: action.payload.categoryName,
                    },
                    APP_MODULE
                );

                return state;
            }

            postFilters = action.payload.postFilters;
            categoryName = action.payload.categoryName;
            return update(state, { [categoryName]: { postFilters: { $set: postFilters } } });
        }

        case UPDATE_SEARCH_CATEGORY: {
            if (!action.payload || !action.payload.name) {
                errorUtils.logAppError(
                    `Missing parameter in ${UPDATE_SEARCH_CATEGORY}`,
                    { payload: action.payload, name: action.payload.name },
                    APP_MODULE
                );

                return state;
            }

            categoryName = action.payload.name;

            category[categoryName] = Object.assign({}, state[categoryName], action.payload);
            rez = Object.assign({}, state, category);
            return rez;
        }

        case UPDATE_SEARCH_CATEGORIES_LIST: {
            if (!action.payload) {
                errorUtils.logAppError(
                    `Missing parameter in ${UPDATE_SEARCH_CATEGORIES_LIST}`,
                    { payload: action.payload },
                    APP_MODULE
                );

                return state;
            }
            if(action.payload && action.payload.length !== 0){
                action.payload.forEach((category) => {
                    if (!category || !category.name) {
                        errorUtils.logAppError(
                            `Missing parameter in ${UPDATE_SEARCH_CATEGORIES_LIST}`,
                            { category: category },
                            APP_MODULE
                        );   
                    }
                });
                return state;
            }
            rez = Object.assign({}, state, action.payload);;
            return rez;
        }

        case UPDATE_SEARCH_CATEGORY_LAST_UPDATED: {
            if (!action.payload) {
                errorUtils.logAppError(
                    `Missing parameter in ${UPDATE_SEARCH_CATEGORY_LAST_UPDATED}`,
                    { payload: action.payload },
                    APP_MODULE
                );

                return state;
            }

            categoryName = action.payload;

            let timeStamp = Date.now();

            return update(state, { [categoryName]: { categoryLastUpdated: { $set: timeStamp } } });
        }

        case RESET_CATEGORIES_RESULTS: {
            rez = Object.assign({}, action.payload);
            return rez;
        }

        case SET_SEARCH_RESULTS: {
            if (!action.payload) {
                errorUtils.logAppError(
                    `Missing parameter in ${SET_SEARCH_RESULTS}`,
                    { payload: action.payload },
                    APP_MODULE
                );

                return state;
            }
            rez = Object.assign({}, action.payload);
            return rez;
        }

        case RESET_CATEGORIES_GROUP: {
            if (!action.payload || isEmpty(action.payload)) {
                errorUtils.logAppError(
                    `Missing parameter in ${RESET_CATEGORIES_GROUP}`,
                    { payload: action.payload },
                    APP_MODULE
                );

                return state;
            }

            const categories = action.payload;
            let newCategoriesState = cloneDeep(state);

            categories.forEach((category) => {
                newCategoriesState[category.name] = category;
            });

            return newCategoriesState;
        }

        case CLEAR_TERMS_CATEGORY: {
            if (!action.payload) {
                errorUtils.logAppError(
                    `Missing parameter in ${CLEAR_TERMS_CATEGORY}`,
                    { payload: action.payload },
                    APP_MODULE
                );

                return state;
            }

            categoryName = action.payload;
            return update(state, {
                [categoryName]: { postFilters: { includeTerms: { $set: [] }, excludeTerms: { $set: [] } } },
            });
        }

        case SEARCH_PARAM_ADD_TERM: {
            if (!action.payload || !action.payload.type || !action.payload.category || !action.payload.term) {
                errorUtils.logAppError(
                    `Missing parameter in ${SEARCH_PARAM_ADD_TERM}`,
                    {
                        payload: action.payload,
                        type: action.payload.type,
                        category: action.payload.category,
                        term: action.payload.term,
                    },
                    APP_MODULE
                );

                return state;
            }

            filterType = action.payload.type;
            categoryName = action.payload.category;
            term = action.payload.term;

            return update(state, { [categoryName]: { postFilters: { [filterType]: { $push: [term] } } } });
        }

        case SEARCH_PARAM_REMOVE_TERM: {
            if (!action.payload || !action.payload.type || !action.payload.category || !action.payload.term) {
                errorUtils.logAppError(
                    `Missing parameter in ${SEARCH_PARAM_REMOVE_TERM}`,
                    {
                        payload: action.payload,
                        type: action.payload.type,
                        category: action.payload.category,
                        term: action.payload.term,
                    },
                    APP_MODULE
                );

                return state;
            }

            filterType = action.payload.type;
            categoryName = action.payload.category;
            term = action.payload.term;

            let index = state[categoryName].postFilters[filterType].indexOf(term);

            if (index > -1) {
                return update(state, { [categoryName]: { postFilters: { [filterType]: { $splice: [[index, 1]] } } } });
            } else {
                return state;
            }
        }

        case SET_SORT_OPTION: {
            if (!action.payload || !action.payload.categoryName || !action.payload.sort) {
                errorUtils.logAppError(
                    `Missing parameter in ${SET_SORT_OPTION}`,
                    {
                        payload: action.payload,
                        categoryName: action.payload.categoryName,
                        sort: action.payload.sort,
                    },
                    APP_MODULE
                );

                return state;
            }

            categoryName = action.payload.categoryName;
            let sort = action.payload.sort;

            return update(state, { [categoryName]: { postFilters: { sort: { $set: sort } } } });
        }

        case SET_LAST_SORT_OPTION: {
            if (!action.payload || !action.payload.categoryName || !action.payload.lastSort) {
                errorUtils.logAppError(
                    `Missing parameter in ${SET_LAST_SORT_OPTION}`,
                    {
                        payload: action.payload,
                        categoryName: action.payload.categoryName,
                        sort: action.payload.lastSort,
                    },
                    APP_MODULE
                );

                return state;
            }

            categoryName = action.payload.categoryName;
            let lastSort = action.payload.lastSort;

            return update(state, { [categoryName]: { lastSort: { $set: lastSort } } });
        }

        case UPDATE_POST_FILTERS:
            postFilters = action.payload;
            return { ...cloneDeep(state), ...postFilters };
        // case SEARCH_UBO_CLEAR_CATEGORIES:{
        /*const categories = state.filter(item => item.type !== CATEGORY_TYPE_UBO_CHILD)
            .map(item => item.type === CATEGORY_TYPE_UBO ? resetUboCategory(item.));
            return categories;*/
        // }

        case SEARCH_UBO_ADD_CATEGORY: {
            let newState = Object.assign({}, state);
            newState[action.payload.categoryName] = {
                ...resetCategory(action.payload.categoryName, true),
                term: action.payload.term,
                duns: action.payload.duns,
                title: action.payload.title,
            };
            return newState;
        }

        case CLEAR_CATEGORY_NEGATIVE_TERMS:
            if (!action.payload) {
                errorUtils.logAppError(
                    `Missing parameter in ${CLEAR_CATEGORY_NEGATIVE_TERMS}`,
                    { payload: action.payload },
                    APP_MODULE
                );

                return state;
            }

            categoryName = action.payload;

            return update(state, { [categoryName]: { postFilters: { terms: { $set: [] } } } });

        case SET_FILTERS_APPLIED_FOR_CATEGORY:
            if (!action.payload || !action.payload.categoryName) {
                errorUtils.logAppError(
                    `Missing parameter in ${SET_FILTERS_APPLIED_FOR_CATEGORY}`,
                    { payload: action.payload },
                    APP_MODULE
                );

                return state;
            }

            categoryName = action.payload.categoryName;

            return update(state, { [categoryName]: { hasFiltersApplied: { $set: action.payload.filtersApplied } } });

        default:
            return state;
    }
}
export default searchResults;
