import { QUERY_SEARCH_TYPES, LAUNCHED_SEARCH_FROM, NEGATIVITY_LEVELS_ORDER, CATEGORY_NAMES } from '@constants';
import utils from '@utils/utilities';

const cellUtils = {
    buildNegativityPostFilterConfig: (value) => {
        return Object.values(NEGATIVITY_LEVELS_ORDER).map((level) => ({
            label: level,
            checked: level === value,
            count: true,
        }));
    },
    computePayload: (text) => {
        const { category, entityName, searchQueryType, prefilterQuery, id, postFilters } = text;
        const { isSnapshotVisible, ...rest } = text;

        const payload = {
            searchQuery: entityName,
            searchQueryType: QUERY_SEARCH_TYPES[searchQueryType],
            prefilterQuery: utils.setCorrectPrefilterQuery(prefilterQuery),
            launchedFrom: LAUNCHED_SEARCH_FROM.SCREENING_PAGE,
            entityId: id,
            checkPreferencesAreObsolete: () => null,
            postFilters,
            currentEntity: rest,
        };
        
        // add the category name to the payload if it's not sanctions, and if it's the sanctions category, only add it if the snapshot is not enabled
        if(category !== CATEGORY_NAMES.SANCTIONS_WATCHLIST || !isSnapshotVisible) {
            payload.categoryName = category;
        }

        return payload;
    },
};

export default cellUtils;
