import { call, put, select } from 'redux-saga/effects';
import { hashHistory } from 'react-router';

import * as mainSelectors from '../selectors/main';
import * as uboSelectors from '../selectors/uboSelectors';

// constants
import { RESET_LOAD_DOCS_LISTENER } from '@sagas/constants/investigationConstants';
import { COMPANY_SEARCH, LAUNCHED_SEARCH_FROM, CATEGORIES, CATEGORY_NAMES } from '@constants';
import { RESET_CATEGORIES_RESULTS } from '@pages/MainSearch/redux/SearchResults.actions.js';
import { SEARCH_EVENTS } from '../constants/searchConstants';

// actions
import investigationActions from '@pages/MainSearch/redux/Investigation.actions';
import suggestedNamesActions from '@reusable/SuggestedNames/redux/SuggestedNames.actions';
import userPreferencesActions from '@pages/UserPreferances/redux/UserPreferences.actions';
import searchParamsActions from '@pages/MainSearch/redux/SearchParams.actions';
import uboActions from '@pages/StartPage/redux/Ubo.actions';
import adHocSearchActions from '@reusable/AdHocSearch/redux/AdHocSearch.actions';
import sanctionsRiskActions from '@pages/MainSearch/components/sanctionsRisk/redux/SanctionsRisk.actions';
import mainActions from '@pages/Main/Main.actions';

//utilities
import utils from '@utils/utilities';
import searchUtils from '@pages/MainSearch/SearchUtils';
import categoryUtils, { withSearchResultsFilter } from '@utils/categoryUtils';
import costCodeUtils from '@utils/costCodeUtils';
import { buildResetSearchResultsPayload, mapParentCatPostFiltersForV4Payload } from '../helpers/searchHelpers';
import { computeUboPostFiltersForPayload } from '../helpers/uboHelper';

const mainSearchURl = '/main-search';

// actions
const { resetInvestigation, setBillingId } = investigationActions;
const { resetFuzzyNames } = userPreferencesActions;
const { updateQueryType, updateQueryString, setBooleanTerms, setCategory, resetQueryParams } = searchParamsActions;
const { submit: submitUboAction } = uboActions;
const { resetAdHocSearch } = adHocSearchActions;
const { searchLaunchedFrom, setEntityId, setCurrentEntity } = mainActions;
const { resetSanctionsRisk } = sanctionsRiskActions;

export function* resetCategoriesResults() {
    const searchResults = yield select(mainSelectors.searchResults);
    const store = yield select(mainSelectors.reduxState);

    const transformedSearchResults = yield call(buildResetSearchResultsPayload, searchResults, store);

    yield put({ type: RESET_CATEGORIES_RESULTS, payload: transformedSearchResults });
}

export function* runSearch(action) {
    const {
        searchQuery,
        prefilterQuery: receivedPrefilterQuery,
        launchedFrom,
        entityId,
        checkPreferencesAreObsolete,
        categoryName,
        currentEntity,
    } = action.payload;

    yield put(resetSanctionsRisk());

    yield put({ type: RESET_LOAD_DOCS_LISTENER, payload: null });

    const viewId = yield select(uboSelectors.viewId);
    
    const { searchQueryType, postFilters, counts, negativity, esgRatings } = yield call(
        [searchUtils, 'getPostFiltersForEntity'],
        entityId, viewId
    ) || {};

    const fuzzyNames = yield select(mainSelectors.fuzzyNames);
    const suggestedNames = yield select(mainSelectors.suggestedNames);
    const prefilterQuery = yield select(mainSelectors.prefilterQuery);
    const {
        useDocumentsCountEndpoint,
        useNewResearchSummary,
        preferences: {
            generalSettings: { contentTypes, showSnapshot },
        },
    } = yield select(mainSelectors.theUser);

    const isCompanySearch = searchQueryType === COMPANY_SEARCH;
    const isSearchLaunchedFromHeader = launchedFrom === LAUNCHED_SEARCH_FROM.HEADER;
    const isSearchLaunchedFromScreening = launchedFrom === LAUNCHED_SEARCH_FROM.SCREENING_PAGE;
    const sanitisedSearchQuery = yield call(utils.sanitiseQuery, searchQuery);

    const computedPrefilterQuery = receivedPrefilterQuery ?? prefilterQuery;
    const parentCategoriesPostFilters = yield call(mapParentCatPostFiltersForV4Payload, postFilters);

    const { postFiltersForPayload, entityCategories, disabledCategories } = yield call(
        utils.formatPostFiltersForApiPayload,
        postFilters
    );

    const path = {
        pathname: mainSearchURl,
        query: {},
    };

    if (!utils.areContentTypesApplicableWithSearchType(contentTypes, searchQueryType)) {
        utils.showNotificationsMessage({
            messageText: 'SearchResults_Notifications.noAvailableContentTypesForSearch',
            messageType: 'system-error',
        });
        return;
    }

    delete CATEGORIES.ubo.children;

    // reset the adhoc search by passing an empty object
    yield put(resetAdHocSearch());

    // reset the query and prefilter query params
    yield put(resetQueryParams());

    //reset visitedContentTypes and the id of the investigation
    yield put(resetInvestigation());

    //save searchQuery, searchQueryType and prefilterQuery in redux store
    yield put(updateQueryString(sanitisedSearchQuery));
    yield put(updateQueryType(searchQueryType));
    yield put(setBooleanTerms(computedPrefilterQuery));

    yield call([searchUtils, 'resetPostFiltersConfiguration'], searchQueryType);

    const isUboEnabled = yield call([utils, 'isUboEnabled']);

    if (isUboEnabled) {
        const uboPostFilters = postFilters.filter(
            (postFilter) => postFilter.category === CATEGORY_NAMES.DNB && postFilter.dunsFilter
        );

        if (!uboPostFilters.length) {
            // submit empty ubo when searching from header, basically removing the list of duns
            yield put(submitUboAction({ selected: null }));
        } else {
            const uboPostFiltersForPayload = computeUboPostFiltersForPayload(uboPostFilters);
            const uboActionPayload = {
                postFilters: uboPostFiltersForPayload,
                selected: uboPostFiltersForPayload[0],
            };

            yield put(submitUboAction(uboActionPayload));
        }
    }

    //reset search results
    yield put({ type: SEARCH_EVENTS.RESET_SEARCH_RESULTS, payload: null });

    if ((fuzzyNames && fuzzyNames.query !== sanitisedSearchQuery) || isCompanySearch) {
        yield put(resetFuzzyNames());
    }

    if (
        (suggestedNames && suggestedNames.query !== sanitisedSearchQuery) ||
        isCompanySearch ||
        isSearchLaunchedFromHeader
    ) {
        yield put(suggestedNamesActions.resetSuggestedNames());
    }

    // generate billing id || should we use the billing id that we have in redux store or generate another one for every new search
    const billingId = yield call(costCodeUtils.generateBillingId);
    const costCode = yield call(costCodeUtils.getCostCode);

    yield put(setBillingId(billingId));

    path.query.q = sanitisedSearchQuery;
    path.query.searchType = searchQueryType;

    if (computedPrefilterQuery) {
        path.query.prefilterQuery = computedPrefilterQuery;
    }

    let receivedCategory = categoryName;
    const searchResults = yield select(mainSelectors.searchResults);
    if (categoryUtils.hasChildren(categoryName)) {
        receivedCategory = withSearchResultsFilter(searchResults).getFirstChild(categoryName).key;
    }

    const redirectToResultList = !!(!showSnapshot || receivedCategory);

    if (negativity) yield call([searchUtils, 'updateEntityNegativity'], negativity);
    let categoriesWithCounts = [];
    if (counts) {
        categoriesWithCounts = yield call(
            [searchUtils, 'updateEntityCountsAndPostFilters'],
            counts,
            esgRatings,
            postFilters,
            searchQuery,
            searchQueryType,
            redirectToResultList
        );
    }

    if (redirectToResultList) {
        // TODO decomment when the useLocation hook from react-router will be used here
        // if (location.pathname === mainSearchURl) {
        //     yield put({ type: ACTION_TYPES.loadDocuments, payload: { categoryName: defaultSearchCategory, filteredFields: location.query.filteredFields }});
        // }
        path.query.list = true;
        const defaultSearchCategory = receivedCategory
            ? receivedCategory
            : yield call([searchUtils, 'getSearchDefaultCategory'], contentTypes, searchQueryType);

        path.query.category = defaultSearchCategory;

        yield call([searchUtils, 'executeBillingSearchEvent'], defaultSearchCategory, billingId, costCode);
    } else {
        delete path.query.list;
        delete path.query.category;
    }

    if (isSearchLaunchedFromHeader) delete path.query.uboSelected;

    if (isSearchLaunchedFromScreening) {
        yield put(setEntityId(entityId));
        yield put(setCategory(receivedCategory ?? null));
        path.query.searchFrom = LAUNCHED_SEARCH_FROM.SCREENING_PAGE;
    } else {
        yield put(setEntityId(null));
    }

    //push the new url to the browser to render the Results Snapshot/List component
    yield call(hashHistory.push, path);

    if (isSearchLaunchedFromHeader || isSearchLaunchedFromScreening) {
        yield put(searchLaunchedFrom(LAUNCHED_SEARCH_FROM.SCREENING_PAGE));
    }

    //here is an issue in the old flow because list will have a boolean value and it will be always different than string true -> TO BE CHECKED
    const countOnly = path.query.list !== 'true';
    const payload = {
        searchQuery: sanitisedSearchQuery,
        searchQueryType: searchQueryType,
        prefilterQuery: computedPrefilterQuery,
        postFilters: postFiltersForPayload,
        parentCategoriesPostFiltersV4: parentCategoriesPostFilters,
        countOnly: countOnly,
        preloadCategory: path.query.category || null,
        useNewResearchSummary: useNewResearchSummary,
        entityCategories,
        disabledCategories,
        categoriesWithCounts,
    };

    if (useDocumentsCountEndpoint) {
        const userPreferencesRefreshObject = {
            // TODO use the checkPreferencesAreObsolete from withPreferenceRefresh HOC
            checkPreferencesAreObsolete: checkPreferencesAreObsolete,
            isSnapshotEnabled: showSnapshot,
        };

        yield call(
            [searchUtils, 'getArticlesCounts'],
            payload,
            userPreferencesRefreshObject,
            !!isSearchLaunchedFromScreening
        );
    } else {
        yield call([searchUtils, 'searchArticles'], payload);
    }

    if (currentEntity) {
        yield put(setCurrentEntity(currentEntity));
    }
}
