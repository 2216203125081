import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';
import {
    COMPANY_CHECK_NEWS_LANGUAGES_SELECTION,
    LAUNCHED_SEARCH_FROM,
    PERSON_CHECK_NEWS_LANGUAGES_SELECTION,
    POST_FILTER_NEGATIVITY_LEVELS,
    POSTFILTER_TYPE,
    QUERY_SEARCH_TYPES,
} from '@constants';
import searchSagaActions from '@pages/MainSearch/redux/searchSaga.actions';
import searchResultsActions from '@pages/MainSearch/redux/SearchResults.actions';
import categoryUtils, { withSearchResultsFilter } from '@utils/categoryUtils';
import cellUtils from '../../utils/cellUtils';
import overridePosition from '../../utils/overridePosition';
import formatRichMessage from '@utils/formatRichMessage';
import RiskTooltip from 'scripts/reusable/GridTable/components/RiskTooltip';
import NegativeNewsVisualisationsUtils from '@pages/MainSearch/components/negativeNewsVisualisations/resultsList/NegativeNewsVisualisationsUtils';
import utils from '@utils/utilities';

export class NegativeNewsRiskTemplate extends React.Component {
    getCompatibleCell(uncertainCell) {
        const entity = uncertainCell.text;
        return { ...uncertainCell, entity };
    }

    update(cell, cellToMerge) {
        return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
    }

    render(cell) {
        if (cell?.isLoading) {
            return <div className="skeleton-loading" />;
        }

        if (!cell || !cell.entity || !cell.entity.negativity || !cell.entity.negativity.overallRiskLevel) {
            return (
                <div className="risk-score-container non-clickable">
                    <div className="doughnut" />
                    <span className="negative-news-score-value">-</span>
                </div>
            );
        }
        const {
            entity: { category, counts, negativity, entityName, searchQueryType, prefilterQuery, id, extraProps, intl },
            text,
        } = cell;

        const { personCheck, companyCheck, searchResults, dispatch } = extraProps;
        const { formatMessage } = intl;

        const goToSearchResults = () => {
            let categoryName = category;
            const negativityLevel =
                negativity.overallRiskLevel !== '-' ? negativity.overallRiskLevel.toLowerCase() : '';

            const onFinishUpdateCounts = () => {
                updatePostFiltersConfig(negativityLevel, categoryName);
            };

            const updatePostFiltersConfig = (negativityLevel, categoryName) => {
                const negativityPostFilterConfig = cellUtils.buildNegativityPostFilterConfig(negativityLevel);

                if (categoryUtils.hasChildren(categoryName)) {
                    const filteredSearchResult = withSearchResultsFilter(searchResults);
                    const childrenList = filteredSearchResult.getCategory(categoryName).children;
                    const childrenKeys = Object.values(childrenList).map((child) => child.key);

                    childrenKeys.forEach((subCategory) => {
                        dispatch(
                            searchResultsActions.updatePostFilterForCategory(
                                subCategory,
                                POST_FILTER_NEGATIVITY_LEVELS,
                                [negativityLevel]
                            )
                        );
                        dispatch(
                            searchResultsActions.updatePostFilterConfig(
                                subCategory,
                                POSTFILTER_TYPE.NEGATIVITY_LEVELS,
                                negativityPostFilterConfig
                            )
                        );
                    });
                } else {
                    dispatch(
                        searchResultsActions.updatePostFilterForCategory(categoryName, POST_FILTER_NEGATIVITY_LEVELS, [
                            negativityLevel,
                        ])
                    );
                }
            };

            const payload = {
                searchQuery: entityName,
                prefilterQuery: utils.setCorrectPrefilterQuery(prefilterQuery),
                launchedFrom: LAUNCHED_SEARCH_FROM.SCREENING_PAGE,
                entityId: id,
                checkPreferencesAreObsolete: () => null,
                categoryName,
            };
            dispatch(searchSagaActions.runSearch(payload));
            onFinishUpdateCounts();
        };

        const overallRisk = negativity.overallRiskLevel;
        const totalArticlesValue = counts;
        const preferencesRedirect =
            QUERY_SEARCH_TYPES[searchQueryType] === QUERY_SEARCH_TYPES.SEARCH_FOR_PERSON
                ? PERSON_CHECK_NEWS_LANGUAGES_SELECTION
                : COMPANY_CHECK_NEWS_LANGUAGES_SELECTION;
        const negativityLevels =
            QUERY_SEARCH_TYPES[searchQueryType] === QUERY_SEARCH_TYPES.SEARCH_FOR_PERSON
                ? personCheck.negativityLevel
                : companyCheck.negativityLevel;

        const disclaimerLabel = formatRichMessage({ id: 'NegativeNewsRisk.label.disclaimer' }, intl, {
            a: (...chunks) => `<a href=#/user-preferences?section=${preferencesRedirect}>${chunks.join('')}</a>`,
        });
        const totalArticlesLabel = formatMessage({
            id: 'NegativeNewsVisualisations.snapshot.graph.label.totalArticles',
        });
        const percentageListSeries = NegativeNewsVisualisationsUtils.buildDonutChartSeries(
            cell.entity.negativity.documentsCount,
            negativityLevels
        );

        const riskLevelClass = `${
            cell.entity && negativity.overallRiskLevel ? negativity.overallRiskLevel.toLowerCase() : ''
        }`;

        const negativityPercentageLabels = percentageListSeries.map((percentageItem) => {
            const totalSum = percentageListSeries.reduce((accumulator, listItem) => accumulator + listItem.y, 0);

            return {
                itemRiskLevel: percentageItem.name,
                itemRiskLabel: formatMessage({ id: `NegativeNewsVisualisations.label.short.${percentageItem.name}` }),
                itemPercentage: (100 * percentageItem.y) / totalSum,
            };
        });
        const NegativeNewsRiskTooltipAsString = ReactDOMServer.renderToString(
            <RiskTooltip
                overallRisk={riskLevelClass}
                categoryLabel={formatMessage({ id: 'General.categoryName.label.negativeNews' })}
                totalArticlesLabel={totalArticlesLabel}
                totalArticlesValue={totalArticlesValue}
                disclaimerLabel={disclaimerLabel}
                riskVisualisationsLabel={formatMessage({
                    id: `NegativeNewsVisualisations.label.${riskLevelClass}`,
                })}
                riskPercentageLabels={negativityPercentageLabels}
            />
        );

        return (
            <div className={`risk-score-container ${text?.displayName?.toLowerCase()}`} onClick={goToSearchResults}>
                {overallRisk && (
                    <ReactTooltip
                        type="light"
                        offset={{ bottom: 0, left: -135 }}
                        overridePosition={overridePosition}
                        border={true}
                        effect="solid"
                        place="bottom"
                        html={true}
                        className="tooltips negative-news-risk-tooltip"
                        id={`${cell.text.id}-negativeNewstooltip`}
                        delayShow={500}
                    />
                )}
                <div className={`doughnut ${riskLevelClass}`} />
                <span
                    className="negative-news-score-value"
                    data-tip={NegativeNewsRiskTooltipAsString}
                    data-for={`${cell.text.id}-negativeNewstooltip`}
                    data-testid={`negative-news-score-value-${text?.displayName?.toLowerCase()}`}
                >
                    {formatMessage({ id: `NegativeNewsVisualisations.label.${riskLevelClass}` })}
                </span>
            </div>
        );
    }
}
