import React, { Fragment, useCallback, useEffect, useRef, useState, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';

const FilterDropdown = ({ cellName = '', disabled = false, filterDropdownData, updateFilterDropdownData, isColumnFilterActive, handleTextTruncation }) => {
    const [isOpen, setIsOpen] = useState(false);
    const popoverRef = useRef(null);
    const filterRef = useRef(null);
    const inputRef = useRef(null);

    const handleOpen = () => {
        setIsOpen((prevIsOpen) => !prevIsOpen);
    };

    const handleClickOutside = useCallback(
        (event) => {
            if (
                popoverRef.current &&
                !popoverRef.current.contains(event.target) &&
                !filterRef.current.contains(event.target) &&
                !inputRef.current.contains(event.target)
            ) {
                handleOpen();
            }
        },
        [popoverRef, filterRef]
    );

    useEffect(() => {
        ReactTooltip.rebuild();
        isOpen && document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside, isOpen]);

    const handleChange = (e, filterEntry) => {
        e.stopPropagation();
        const entryToBeUpdated = filterDropdownData.find((entry) => entry.name === filterEntry.name);
        entryToBeUpdated.checked = e.target.checked;
        const updatedFilterDropdownData = filterDropdownData.map((entry) => entry.name === filterEntry.name ? entryToBeUpdated : entry);

        updateFilterDropdownData(updatedFilterDropdownData)
    }

    const handleResetToDefaultSettings = () => {
        const updatedFilterDropdownData = filterDropdownData.map((entry) => ({ ...entry, checked: false }));
        updateFilterDropdownData(updatedFilterDropdownData);
    }

    const areEntriesChecked = filterDropdownData && filterDropdownData.some((entry) => entry.checked);

    return (
        <Fragment key={`user-preference-sanctions-table-filter-dropdown-column-${cellName}`}>
            <div ref={filterRef} className={`user-preferences-category-container__authorities__simple-table__filter-dropdown ${disabled ? 'disabled' : ''}`} data-testid={`user-preferences-category-container__authorities__simple-table__filter-dropdown__${cellName}`} onClick={handleOpen}>
                <div className={`user-preferences-category-container__authorities__simple-table__filter-dropdown__button${isColumnFilterActive ? ' active' : ''}`}>
                    <FormattedMessage id='UserPreferences_topic_sources_sanctionsWatchLists.filter' />
                    <div className={`${isOpen ? 'la-TriangleDown' : 'la-TriangleUp'}`} /></div>
            </div>
            {isOpen && <ul ref={popoverRef} className='user-preferences-category-container__authorities__simple-table__filter-dropdown__content' data-testid='user-preferences-category-container__authorities__simple-table__filter-dropdown__content'>
                <li className='user-preference-filter-reset-button' date-testid={`user-preference-filter-reset-button-${cellName}`}>
                    <button data-testid={`filter-reset-button-${cellName}`} disabled={!areEntriesChecked} onClick={handleResetToDefaultSettings}>
                        <FormattedMessage id='UserPreferences_topic_sources_sanctionsWatchLists.reset' />
                    </button>
                </li>
                {filterDropdownData.map((filterEntry) => {
                    return <li key={filterEntry.name} className='user-preference-checkbox'>
                        <input ref={inputRef} type='checkbox' name={filterEntry.name} checked={filterEntry.checked} onChange={(e) => handleChange(e, filterEntry)} data-testid={`filter-dropdown-${filterEntry.name}-checkbox`} />
                        <label 
                            className='preference-checkbox-label'
                            data-tip={filterEntry.name}
                            data-for="authority-tooltip"
                            onMouseOver={(e) => handleTextTruncation(e)}
                            onMouseOut={(e) => handleTextTruncation(e)}
                            >
                            {filterEntry.name}
                        </label>
                    </li>
                })}
            </ul>}
        </Fragment>
    )
}

export default memo(FilterDropdown);