import {
    CATEGORIES,
    CATEGORIES_WITH_INFO_ICON,
    CATEGORY_NAMES,
    CATEGORY_SORT_FUNC,
    CUSTOM_NEWS_QUERY_ESG_TITLE,
    DOCKETS_SOURCES,
    NEGATIVE_NEWS_SELECTED_LANGUAGE,
    NEWS_CONTENT_TYPES,
    PREFERENCES_KEY,
    LEGAL_SOURCES_ALL_OF_THEM,
} from '@constants';
import _, { find, isEmpty, last } from 'lodash';
import reduxStore from '@reduxStore';
import utils from './utilities';

const categoryUtils = {
    _categoryKeys: () => CATEGORIES,

    _array(parent = null) {
        let arr = [];
        let categories = this._categoryKeys();
        if (parent) {
            categories = (this._categoryKeys()[parent] && this._categoryKeys()[parent].children) || [];
        }

        Object.keys(categories).forEach((key) => {
            arr.push({ ...categories[key] });
        });

        return arr.sort(CATEGORY_SORT_FUNC);
    },

    getList(parent = null) {
        return this._array(parent);
    },

    getFlatList(parent = null, showParents = false) {
        let root = this._array(parent);
        let list = [];
        root.forEach((category) => {
            if (category.children) {
                if (showParents) {
                    list.push(category);
                }
                list = [
                    ...list,
                    ...this._array(category.key).map((category) => ({ ...category, parent: category.key })),
                ];
            } else {
                list.push(category);
            }
        });
        return list;
    },
    resolvedToTranslatableCategory(categoryKey){
        return this.isCustomNewsSource(categoryKey)
            ? this.getParent(categoryKey) || categoryKey
            : categoryKey;
    },
    getCategory(categoryKey) {
        return this.getFlatList(null, true).find((category) => category.key === categoryKey);
    },

    getCategoryName(categoryKey) {
        if (this.getCategory(categoryKey)) {
            return this.getCategory(categoryKey).name;
        } else {
            console.warn(`Trying to retrieve nonexistent for category: ${categoryKey}`);
            return '';
        }
    },

    getCategoryKeyForTranslation(categoryKey) {
        if (this.getCategory(categoryKey)) {
            return this.getCategory(categoryKey).key;
        } else {
            console.warn(`Trying to retrieve nonexistent for category: ${categoryKey}`);
            return '';
        }
    },

    getCategoryLabel(articleType, fromParent = false) {
        return fromParent ? categoryUtils.getParent(articleType) ?? articleType : articleType;
    },

    isChild(categoryKey) {
        let childrenCategories = _.flatten(
            Object.values(this._categoryKeys())
                .filter((category) => category.children)
                .map((category) => Object.values(category.children))
        );

        return childrenCategories.filter((category) => categoryKey === category.key).length > 0;
    },

    isParent(categoryKey) {
        return Object.values(this._categoryKeys()).filter((category) => category.key === categoryKey).length > 0;
    },

    hasChildren(categoryKey) {
        return !!(this.getCategory(categoryKey) && this.getCategory(categoryKey).children);
    },

    hasInformationIcon(categoryName) {
        return CATEGORIES_WITH_INFO_ICON.indexOf(categoryName) > -1;
    },

    hasExtendedChildren(categoryKey) {
        return this.hasChildren(categoryKey) && this.isExtended(this.getFirstChild(categoryKey).key);
    },

    getFirstChild(categoryKey) {
        const children = this._array(categoryKey);
        if (children && children[0]) {
            return children[0];
        } else {
            console.warn(`Attempting to get first children when there is none for: ${categoryKey}`);
            return null;
        }
    },

    getCategoryKeys(parent = null) {
        return this.getList(parent) && this.getList(parent).map((category) => category.key);
    },

    getParent(categoryKey) {
        let parentKey = null;
        this._array().forEach((parent) => {
            const children = this._array(parent.key) || null;
            if (children) {
                children.forEach((child) => {
                    if (child.key === categoryKey) {
                        parentKey = parent.key;
                    }
                });
            }
        });
        return parentKey;
    },

    isChildOf(parent, child) {
        return (
            this._categoryKeys()[parent] &&
            this._categoryKeys()[parent].children &&
            !!this._categoryKeys()[parent].children[child]
        );
    },

    extendConfig(categoryKey, config) {
        return this.getCategory(categoryKey).extendConfig ? this.getCategory(categoryKey).extendConfig(config) : config;
    },

    isMatchingConfig(categoryKey, config, strict = true) {
        return this.getCategory(categoryKey).matchConfig ? this.getCategory(categoryKey).matchConfig(config) : !strict;
    },

    isExtended(categoryKey) {
        return this.isChild(categoryKey) && !!this.getCategory(categoryKey).extendConfig;
    },

    shouldDisplayForState(categoryKey, state) {
        return this.getCategory(categoryKey).shouldDisplay ? this.getCategory(categoryKey).shouldDisplay(state) : true;
    },

    getContentSource(categoryKey, state) {
        return this.getCategory(categoryKey).getContentSource
            ? this.getCategory(categoryKey).getContentSource(state)
            : null;
    },
    // gets for each parent key, the keys its children appear in different circumstances (ex: contentLanguages key for alert object)
    getConfigKeys(categoryKey) {
        return this.getCategory(categoryKey).getConfigKeys ? this.getCategory(categoryKey).getConfigKeys() : null;
    },

    // gets for each child name or language the corresponding key (ex: NegativeNewsEn)
    getSubcategory(subcategories, category, nameOrLanguage, matchConfigKey) {
        return find(
            subcategories,
            (child) => child.matchConfig && child.matchConfig({ category, [matchConfigKey]: nameOrLanguage })
        );
    },

    // get for each parent all the children keys depending on the available languages (ex: ['negativeNewsEn',negativeNewsRu'] for ['English','Russian'])
    getAvailableSubcategories(parentKey, properties, matchConfigKey) {
        let subcategories = CATEGORIES[parentKey].children;
        let availableSubcategories = [];

        if (subcategories) {
            properties.forEach((nameOrLanguage) => {
                let subcategory = categoryUtils.getSubcategory(
                    subcategories,
                    parentKey,
                    nameOrLanguage,
                    matchConfigKey
                );

                if (subcategory) {
                    availableSubcategories.push(subcategory.key);
                }
            });
        }
        return availableSubcategories;
    },

    shouldShowInSummary(categoryKey) {
        if (!categoryKey) {
            return false;
        }
        return !(this.isChild(categoryKey) && !!this.getCategory(categoryKey).extendConfig);
    },

    getNewsQuery(categoryKey, state, searchType) {
        if (!categoryKey) {
            return null;
        }
        return this.getCategory(categoryKey).newsQuery
            ? this.getCategory(categoryKey).newsQuery(state, searchType)
            : null;
    },

    postfilterToCategory(postFilter) {
        let category = postFilter.category;
        if (categoryUtils.hasChildren(category)) {
            categoryUtils.getCategoryKeys(category).forEach((subcategory) => {
                // if config matches, it means it is one of the subcategories instead
                if (categoryUtils.isMatchingConfig(subcategory, postFilter)) {
                    category = subcategory;
                }
            });
        }
        return category;
    },

    getContentNewsSource(state, categoryKey, key = 'contentSource') {
        if (this.isNewsSource(categoryKey) && !!state) {
            let searchType = PREFERENCES_KEY[state.searchParams.searchType];

            const historyCategoryName = !!state.historyCategory && state.historyCategory.categoryName;
            if (
                historyCategoryName !== '' &&
                !isEmpty(state.editSearch) &&
                state.editSearch[historyCategoryName] &&
                state.editSearch[historyCategoryName].contentSource &&
                categoryKey === historyCategoryName
            ) {
                return state.editSearch[historyCategoryName].contentSource;
            }

            if (state.adHocSearch && state.adHocSearch.newsSearchSources) {
                let contentSource = _.find(NEGATIVE_NEWS_SELECTED_LANGUAGE, [
                    'name',
                    state.adHocSearch.newsSearchSources,
                ])[key];

                return contentSource || null;
            }

            if (state.user.preferences[searchType] && state.user.preferences[searchType].newsSearchSources) {
                let contentSource = _.find(NEGATIVE_NEWS_SELECTED_LANGUAGE, [
                    'name',
                    state.user.preferences[searchType].newsSearchSources,
                ])[key];

                return contentSource || null;
            }
        }
        return null;
    },

    isCompanySources(category) {
        return category === CATEGORY_NAMES.COMPANY_RESOURCES;
    },

    isNewsSource(categoryKey) {
        return (
            NEWS_CONTENT_TYPES.indexOf(categoryKey) > -1 ||
            (this.isExtended(categoryKey) && NEWS_CONTENT_TYPES.indexOf(this.getParent(categoryKey)) > -1)
        );
    },

    isCustomNewsSource(categoryKey) {
        return this.getParent(categoryKey) === CATEGORY_NAMES.CUSTOM_NEWS;
    },

    getLanguagesForCustomNews(state, categoryKey) {
        if (
            categoryKey === CATEGORY_NAMES.NEWS ||
            categoryKey === CATEGORY_NAMES.CUSTOM_NEWS ||
            this.getParent(categoryKey) === CATEGORY_NAMES.CUSTOM_NEWS
        ) {
            let searchType = PREFERENCES_KEY[state.searchParams.searchType];
            let newsSource = _.find(NEGATIVE_NEWS_SELECTED_LANGUAGE, [
                'name',
                state.adHocSearch.newsSearchSources || state.user.preferences[searchType].newsSearchSources,
            ]);
            let languages = null;
            switch (newsSource.name) {
                case NEGATIVE_NEWS_SELECTED_LANGUAGE.allNonEnglishLanguageNews.name:
                    languages = newsSource.contentLanguage;
                    break;
                case NEGATIVE_NEWS_SELECTED_LANGUAGE.allNewsAllLanguages.name:
                    languages = null;
                    break;
                default:
                    languages = newsSource.values;
            }

            return languages ? languages[0] : null;
        }
    },

    isDnbCategory(categoryName) {
        return categoryName === CATEGORY_NAMES.DNB || categoryUtils.isChildOf(CATEGORY_NAMES.DNB, categoryName);
    },

    isDnbChildCategory(categoryName) {
        return categoryUtils.isChildOf(CATEGORY_NAMES.DNB, categoryName);
    },

    isNewCategory(categoryKey) {
        return !!this.getCategory(categoryKey) && this.getCategory(categoryKey).isNew === true;
    },

    isDockets(categoryName) {
        return DOCKETS_SOURCES.indexOf(categoryName) > -1;
    },

    isLegalSource(categoryName) {
        return categoryUtils.isChildOf(CATEGORY_NAMES.LAW_SOURCES, categoryName);
    },

    isCategoryWithDeduplication(categoryName) {
        return (
            categoryUtils.isChildOf(CATEGORY_NAMES.NEGATIVE_NEWS, categoryName) ||
            categoryUtils.isChildOf(CATEGORY_NAMES.CUSTOM_NEWS, categoryName) ||
            categoryName === CATEGORY_NAMES.NEWS
        );
    },

    isVisitedCategory(category, investigation) {
        return !!investigation.visitedContentTypes[category.name] || category.markedInHistory;
    },

    // this function checks if the default newsSearchSources property from adHocSearch state in Redux is 'All news, All languages'
    // this check is done because the default value in Redux for newsSearchSources is always 'All news, All languages' and it should be reset every time a new search is triggered
    isDefaultNewsSearchSourceForAdhoc(adhocSearch) {
        if (!adhocSearch || isEmpty(adhocSearch) || _.size(adhocSearch) !== 1 || !adhocSearch.newsSearchSources) {
            return false;
        }

        return adhocSearch.newsSearchSources === NEGATIVE_NEWS_SELECTED_LANGUAGE.allNewsAllLanguages.name;
    },

    getDefaultSubcategoryName(categoryName, searchResults) {
        return this.hasChildren(categoryName)
            ? withSearchResultsFilter(searchResults).getFirstChild(categoryName).key
            : categoryName;
    },

    getCategoryActiveStatus(state, categoryKey) {
        const adHocSearch = state.adHocSearch;
        const userOfferings = state.user.userOfferings;

        let userEnabledSources = [];

        if (adHocSearch.sources) {
            let contentSources = adHocSearch.sources || [];
            userEnabledSources = contentSources.filter((source) => source.checked).map((source) => source.key);
        } else {
            let contentSources = state.user.preferences.generalSettings.contentTypes || [];
            userEnabledSources = contentSources.filter((source) => source.value).map((source) => source.name);
        }

        //map by user offerings
        userEnabledSources = userEnabledSources.filter((source) => {
            if (source === CATEGORY_NAMES.DNB) return true;

            return utils.filterContentTypesByOfferings(userOfferings, { name: source });
        });

        const userOfferingsItem = userOfferings.find((item) => item.contentType === categoryKey);
        const isCategoryAvailable = !!userOfferingsItem ? userOfferingsItem.available : false;
        const isCategoryEnabled =
            (userEnabledSources.indexOf(categoryKey) > -1 || categoryUtils.isExtended(categoryKey)) &&
            categoryUtils.shouldDisplayForState(categoryKey, state);

        return {
            isCategoryAvailable,
            isCategoryEnabled,
        };
    },

    getDateRangeForCategories({ adHocNewsDateRange, adHocLegalDateRange, adHocCompanyDateRange, generalSettings }) {
        const newsDateRange = adHocNewsDateRange ? adHocNewsDateRange.range : generalSettings.newsSources.dateRange;
        const legalDateRange = adHocLegalDateRange ? adHocLegalDateRange.range : generalSettings.legalSources.dateRange;
        const companySourcesDateRange = adHocCompanyDateRange
            ? adHocCompanyDateRange.range
            : generalSettings.companySources.dateRange;

        return {
            [CATEGORY_NAMES.NEWS]: newsDateRange,
            [CATEGORY_NAMES.LAW_SOURCES]: legalDateRange,
            [CATEGORY_NAMES.COMPANY_RESOURCES]: companySourcesDateRange,
        };
    },
    getAlertSourceCategories(categories) {
        return categories
            .filter(({ checked }) => checked)
            .flatMap(({ key, children }) =>
                this.hasChildren(key) && !this.hasExtendedChildren(key) && key !== CATEGORIES.NEGATIVE_NEWS
                    ? children.filter((child) => child.checked).map((child) => child.key)
                    : key
            );
    },
    getChildrenForAlertSource(parentName, selectedSearchQueryTypes = [], checked = true) {
        if (parentName !== CATEGORY_NAMES.CUSTOM_NEWS && categoryUtils.hasChildren(parentName)) {
            let preferences = reduxStore.getState().user.preferences;
            let isEnabled = false;

            const childrenList = categoryUtils
                .getList(parentName)
                .map((child) => {
                    let childConfig = {
                        key: child.key,
                        enabled: true,
                        checked,
                    };

                    switch (parentName) {
                        case CATEGORY_NAMES.NEGATIVE_NEWS:
                            childConfig.name = child.extendConfig().contentLanguage;

                            isEnabled = selectedSearchQueryTypes.some((searchQueryType) => {
                                const prefType = PREFERENCES_KEY[searchQueryType] ?? '';
                                return preferences[prefType]?.customQuery
                                    ?.filter((query) => query.checked)
                                    ?.some((query) => query.language === childConfig.name);
                            });
                            childConfig.enabled = isEnabled;
                            break;
                        case CATEGORY_NAMES.LAW_SOURCES:
                            const generalSettings = preferences.generalSettings;
                            const legalSource = generalSettings.legalSources.defaultDateSource;
                            isEnabled = legalSource === LEGAL_SOURCES_ALL_OF_THEM || legalSource === child.key;

                            childConfig.name = child.name;
                            childConfig.enabled = isEnabled || !legalSource;
                            break;
                        default:
                            return null;
                    }

                    return childConfig;
                })
                .filter((child) => child && child.enabled && !categoryUtils.isDockets(child.key));

            return childrenList;
        }

        return null;
    },
};

/**
 * Used to filter out the category options by intersecting with searchResult enabled state
 * @param searchResults
 */
export const withSearchResultsFilter = (searchResults) => ({
    ...categoryUtils,
    _array: (parent) =>
        categoryUtils
            ._array(parent)
            .filter((category) => searchResults[category.key] && searchResults[category.key].enabled),
});

export const withCustomNewsTitle = (categoryName) => {
    if (!categoryUtils.isChildOf(CATEGORY_NAMES.CUSTOM_NEWS, categoryName)) {
        return;
    }
    let state = reduxStore.getState();
    let searchType = PREFERENCES_KEY[state.searchParams.searchType];
    let customNews = find(state.user.preferences[searchType].customNewsQuery, ['name', categoryName]);
    return customNews ? [customNews.title] : [categoryName];
};

export const withLNCustomNewsTitle = (categoryName) => {
    if (!categoryUtils.isChildOf(CATEGORY_NAMES.CUSTOM_NEWS, categoryName)) {
        return;
    }
    let state = reduxStore.getState();
    let searchType = PREFERENCES_KEY[state.searchParams.searchType];
    let customNews = find(state.user.preferences[searchType].lnCustomNewsQueries, ['name', categoryName]);
    return customNews ? (customNews.title ? customNews.title : [CUSTOM_NEWS_QUERY_ESG_TITLE]) : [categoryName];
};

export const withAdminCustomNewsTitle = (categoryName) => {
    if (!categoryUtils.isChildOf(CATEGORY_NAMES.CUSTOM_NEWS, categoryName)) {
        return;
    }
    let state = reduxStore.getState();
    let searchType = PREFERENCES_KEY[state.searchParams.searchType];
    let customNews = find(state.user.preferences[searchType].adminCustomNewsQuery, ['name', categoryName]);
    return customNews ? [customNews.title] : [categoryName];
};

export const withCustomNewsTranslation = (translationKey, searchType) => {
    const partTranslateKey = last(translationKey.split('.'));

    if (!categoryUtils.isChildOf(CATEGORY_NAMES.CUSTOM_NEWS, partTranslateKey)) {
        return partTranslateKey;
    }

    const state = reduxStore.getState();
    searchType = PREFERENCES_KEY[searchType] || PREFERENCES_KEY[state.searchParams.searchType];

    /**
     * map the custom news query to its translation
     * @key {string} - is the identifier from 'partTranslateKey'
     * @value {queries, fallback} - queries: news queries from user preferences by searchType; fallback: default
     * translation in case if queries are not found
     * */
    const newsQueriesMap = {
        custom: {
            queries: state.user.preferences[searchType].customNewsQuery,
            fallback: partTranslateKey,
        },
        admin: {
            queries: state.user.preferences[searchType].adminCustomNewsQuery,
            fallback: partTranslateKey,
        },
        lnCustom: {
            queries: state.user.preferences[searchType].lnCustomNewsQueries,
            fallback: CUSTOM_NEWS_QUERY_ESG_TITLE,
        },
    };

    const newsQueryTitle = Object.keys(newsQueriesMap)
        .map((identifier) => {
            if (partTranslateKey.indexOf(identifier) > -1) {
                const { queries, fallback } = newsQueriesMap[identifier];
                const query = find(queries, ['name', partTranslateKey]);

                return (query && query.title) || fallback;
            }
        })
        .filter(Boolean)[0];

    return newsQueryTitle || partTranslateKey;
};

export const getCategoryOrder = (contentTypes) => {
    if (!utils.isArrayPopulated(contentTypes)) return [];

    const availableContentTypes = contentTypes.filter((category) => category.value);
    availableContentTypes.sort((a, b) => a.reportOrder - b.reportOrder);

    return availableContentTypes.map((contentType, index) => {
        const { name } = contentType;

        return {
            sourceType: name,
            order: index,
        };
    });
};

export default categoryUtils;
