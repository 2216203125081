import React, { Component } from 'react';

export class SanctionsResultsTemplate extends Component {
    getCompatibleCell(uncertainCell) {
        const text = uncertainCell.text;
        return { ...uncertainCell, text };
    }

    update(cell, cellToMerge) {
        return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
    }

    render(cell) {
        const { text } = cell;
        const { content, onClick } = text;
        const isContentAvailable = !!content.length;
        const onClickHandler = () => isContentAvailable && onClick();

        return (
            <div className={`sanctions-table-results ${isContentAvailable ? '' : 'disabled'}`} onClick={onClickHandler}>
                <div className='sanctions-table-results-content'>
                    {isContentAvailable ? <div dangerouslySetInnerHTML={{ __html: content }} /> : <p className='sanctions-table-cell'>-</p>}
                </div>
            </div>
        );
    }
}
